import { CSSObjectWithLabel, GroupBase, OptionProps } from 'react-select';
import { ThemeType } from '../../models';

type OverridesType = {
	control?: CSSObjectWithLabel;
	singleValue?: CSSObjectWithLabel;
	input?: CSSObjectWithLabel;
	dropdownIndicator?: CSSObjectWithLabel;
	option?: CSSObjectWithLabel;
};

const createSelectStyles = <Option = unknown, IsMulti extends boolean = boolean, Group extends GroupBase<Option> = GroupBase<Option>>(
	themeContext: ThemeType,
	overrides?: OverridesType
) => ({
	control: (styles: CSSObjectWithLabel) => ({
		...styles,
		background: 'transparent',
		borderColor: themeContext.colors.accent,
		boxShadow: 'none',
		':hover': {
			...styles[':hover'],
			borderColor: themeContext.colors.accent
		},
		...overrides?.control
	}),
	singleValue: (styles: CSSObjectWithLabel) => ({
		...styles,
		color: themeContext.colors.text,
		...overrides?.singleValue
	}),
	input: (styles: CSSObjectWithLabel) => ({
		...styles,
		color: themeContext.colors.text,
		...overrides?.input
	}),
	dropdownIndicator: (styles: CSSObjectWithLabel) => ({
		...styles,
		color: themeContext.colors.text,
		':hover': {
			color: themeContext.colors.text
		},
		...overrides?.dropdownIndicator
	}),
	option: (styles: CSSObjectWithLabel, state: OptionProps<Option, IsMulti, Group>) => ({
		...styles,
		backgroundColor: state.isSelected || state.isFocused ? themeContext.colors.accent : styles.backgroundColor,
		color: state.isSelected || state.isFocused ? themeContext.colors.textinverted : themeContext.colors.text,
		':active': {
			backgroundColor: themeContext.colors.accent,
			color: themeContext.colors.textinverted
		},
		...overrides?.option
	})
});

export default createSelectStyles;
