import { DateOfBirth } from '../../models/DateOfBirth';

export function createDateForDob(dob: DateOfBirth): Date | undefined {
	if (!dob.year || !dob.month || !dob.day) {
		return undefined;
	}

	const year = parseInt(dob.year, 10);
	const month = parseInt(dob.month, 10);
	const day = parseInt(dob.day, 10);

	if (isNaN(year) || isNaN(month) || isNaN(day)) {
		return undefined;
	}

	return new Date(Date.UTC(year, month - 1, day));
}
