import React, { useState } from 'react';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallPostSendEmailSmsAdvice } from '../../helpers/services';

import { default as SP } from '../Procedure/styled';
import { default as SESA } from './styled';

import Button from '../Button';
import { getLabel } from '../../helpers/constants/getLabels';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';

const ProcedureSendEmailOrSmsAdvice = (props) => {
	const [{ settings, session }] = useStateContext();
	const [isDisabled, setIsDisabled] = useState(false);
	const [isSending, setIsSending] = useState({ email: false, sms: false });
	const [sendStatus, setSendStatus] = useState(false);
	const [isError, setIsError] = useState(false);
	const [validationPhoneStatus, setValidationPhoneStatus] = useState(true);
	const [validationEmailStatus, setValidationEmailStatus] = useState(true);
	const [formData, setFormData] = useState({ email: null, phone: null });

	const validateEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const validatePhone = (phoneNumber) => {
		const phoneRegex = /^(?:\+31|0)(?:\d\s?){9,10}$/;
		return phoneRegex.test(phoneNumber);
	};

	const handleSendEmailData = async () => {
		if (!validateField(formData.email, 'email')) {
			setValidationEmailStatus(false);
		} else {
			setIsSending((prev) => ({ ...prev, email: true }));
			await proceedSendData();
		}
	};

	const handleSendPhoneData = async () => {
		if (!validateField(formData.phone, 'phone')) {
			setValidationPhoneStatus(false);
		} else {
			setIsSending((prev) => ({ ...prev, sms: true }));
			await proceedSendData();
		}
	};

	const proceedSendData = async () => {
		setIsDisabled(true);

		const valid = validateField(formData.email, 'email') || validateField(formData.phone, 'phone');

		if (valid) {
			// call API service for sending Email or SMS
			const result = await CallPostSendEmailSmsAdvice(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				languageCode: settings.selectedLanguage.code,
				...(formData.email && { email: formData.email }),
				...(formData.phone && { phone: formData.phone })
			});

			if (result) {
				if (result.message === 'success') {
					setIsSending((prev) => ({ ...prev, sms: false, email: false }));
					setSendStatus(true);

					props.setBackButtonStatus(true);
				} else {
					//handle result.message != success
					//message is always success
					setSendStatus(false);
					setIsError(true);
					setIsDisabled(true);
					setIsSending((prev) => ({ ...prev, sms: false, email: false }));

					props.setBackButtonStatus(true);
				}
			}
		} else {
			setIsSending(false);
			setIsDisabled(false);
		}
	};

	const validateField = (value, field) => {
		const validators = { email: validateEmail, phone: validatePhone };
		return validators[field](value);
	};

	const handleChangeFormField = (event) => {
		const { name, value } = event.target;
		validateField(value, name);
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const errorMessageLabel = getLabel('callbackRequestErrorMessage', settings.applicationTexts, true);
	const succesLabel = getLabel('sendAdviceEmailSmsSuccessMessage', settings.applicationTexts, true);

	return (
		<>
			{!sendStatus && !isError && (
				<>
					<SESA.FormContainer>
						<SESA.FormGroup>
							<InputText
								onChange={handleChangeFormField}
								name="email"
								label={getLabel('WidgetFormLabelEmail', settings.applicationTexts, true)}
								disabled={isDisabled}
							></InputText>
							<SESA.ButtonContainer>
								<Button onClick={handleSendEmailData} disabled={isDisabled}>
									{isSending.email
										? getLabel('WidgetSendingButton', settings.applicationTexts, true)
										: getLabel('WidgetSendButton', settings.applicationTexts, true)}
								</Button>
							</SESA.ButtonContainer>
							<SP.RowClean>
								{validationEmailStatus === false && (
									<SP.Error>
										<ul>
											<li>{getLabel('callBackRequestErrorEmail', settings.applicationTexts, true)}</li>
										</ul>
									</SP.Error>
								)}
							</SP.RowClean>
						</SESA.FormGroup>
						<SESA.FormGroup>
							<InputText
								onChange={handleChangeFormField}
								name="phone"
								label={getLabel('WidgetFormLabelTel', settings.applicationTexts, true)}
								disabled={isDisabled}
							></InputText>
							<SESA.ButtonContainer>
								<Button onClick={handleSendPhoneData} disabled={isDisabled}>
									{isSending.sms
										? getLabel('WidgetSendingButton', settings.applicationTexts, true)
										: getLabel('WidgetSendButton', settings.applicationTexts, true)}
								</Button>
							</SESA.ButtonContainer>

							<SP.RowClean>
								{validationPhoneStatus === false && (
									<SP.Error>
										<ul>
											<li>{getLabel('callBackRequestErrorTel', settings.applicationTexts, true)}</li>
										</ul>
									</SP.Error>
								)}
							</SP.RowClean>
						</SESA.FormGroup>
					</SESA.FormContainer>
				</>
			)}

			{sendStatus && <SP.Succes>{renderMarkdown(succesLabel)}</SP.Succes>}

			{isError && <SP.Error>{renderMarkdown(errorMessageLabel)}</SP.Error>}
		</>
	);
};

export default ProcedureSendEmailOrSmsAdvice;

const InputText = (props) => {
	return (
		<React.Fragment>
			<SP.Label htmlFor={props.name}>{props.label}</SP.Label>
			<SP.Field type="text" {...props} autoComplete="off" />
		</React.Fragment>
	);
};
