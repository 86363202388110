import React, { useState, useEffect, useRef } from 'react';
import { DateTime } from 'luxon';
import S from './styled';
import { default as SA } from '../Activity/styled';
import { default as SP } from '../Procedure/styled';
import { default as SAA } from '../ActivityAdvice/styled';
import { default as SAT } from '../Activity/Activity.styled';
import { SpinnerButton } from '../Button';
import ActivityEndActions from '../ActivityEndActions';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallPostCallBackEmail, CallPostSessionLogProperties } from '../../helpers/services';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import { getLabel } from '../../helpers/constants/getLabels';
import { createDateForDob } from '../../helpers/support/createDateForDob';
import { GetCallbackTimeSlots } from '../../helpers/services/CallGetCallbackTimeslots';
import { faBell } from '@fortawesome/free-regular-svg-icons';
/* eslint-disable no-unused-vars -- We import the various hard-coded schedules we can use for testing */
import {
	scheduleNotRounded,
	scheduleToday,
	scheduleOneDay,
	scheduleTwoDays,
	scheduleWithGaps,
	scheduleNoSchedule,
	scheduleWithManyTimeSlots,
	scheduleWithManyDays
} from '../CallbackRequestScheduler/schedules';
/* eslint-enable */

import { InputPhone, parseInputPhoneNumber } from '../InputPhone/InputPhone';

const scheduleUseApi = process.env.REACT_APP_PROCEDURECONTACTREQUEST_SCHEDULE_USE_API !== 'false'; // note: used for development to have more control over the schedule
const developmentSchedule = scheduleNotRounded;

function validateEmail(value, mandatory) {
	if (value === null || value.length === 0) {
		if (mandatory) {
			return false;
		} else {
			return true;
		}
	}

	const emailParts = value.split('@', 3);
	if (emailParts.length !== 2) {
		return false;
	}

	const [localPart, domainPart] = emailParts;

	if (localPart.length > 0) {
		if (
			domainPart.length > 0 &&
			domainPart.startsWith('.') === false &&
			domainPart.endsWith('.') === false &&
			domainPart.indexOf('..') === -1 &&
			domainPart.includes('.')
		) {
			return true;
		}
	}

	return false;
}

function checkValidFields(validFields, session) {
	let valid = session !== null;
	valid = valid && validFields.Name;
	valid = valid && validFields.GivenName;
	valid = valid && validFields.Surname;
	valid = valid && validFields.Dob;
	valid = valid && validFields.Gender;
	valid = valid && validFields.Tel;
	valid = valid && validFields.Bsn;
	valid = valid && validFields.Email;
	valid = valid && validFields.EmailOrBsn;
	valid = valid && validFields.Address;
	valid = valid && validFields.HouseNumber;
	valid = valid && validFields.ZipCode;
	valid = valid && validFields.Doctor;
	valid = valid && validFields.HapLocation;
	valid = valid && validFields.Consent;
	valid = valid && validFields.CallbackLocation;
	valid = valid && validFields.Comment;

	return valid;
}

export const TextArea = (props) => {
	const [length, setLength] = useState(0);
	const handleChangeFormField = (e) => {
		setLength(e.target.value.length);
		props.onChange?.(e);
	};
	return (
		<>
			{props.label && (
				<SP.Label htmlFor={props.name} data-optional={true}>
					{props.label}
				</SP.Label>
			)}
			<SP.FieldTextArea
				id={props.name}
				onChange={handleChangeFormField}
				name={props.name}
				autoComplete="off"
				disabled={props.disabled}
				maxLength={props.maxLength ?? 300}
				valid={props.valid}
			/>
			<div className="textarea-character-counter">
				<span>
					{length || 0}/{props.maxLength ?? 300}
				</span>
			</div>
		</>
	);
};

const ProcedureContactRequest = (props) => {
	const [{ profile, settings, session, conversation }, dispatch] = useStateContext();
	const [showGenderField] = useState(!profile.gender || profile.gender.length === 0);
	const [isDisabled, setIsDisabled] = useState(props.disabled);
	const [isSending, setIsSending] = useState(false);
	const [isError, setIsError] = useState(false);
	const [hasSendDataBeenCalled, setHasSendDataBeenCalled] = useState(false);

	let profileDobDateTime = null;
	const profileDobDate = createDateForDob(profile.dob);
	if (profileDobDate) {
		profileDobDateTime = DateTime.fromJSDate(profileDobDate, { zone: 'utc' });
	}

	function initializeFields() {
		let dobFieldValue = null;
		if (settings.contactRequest.clientDateOfBirthRequired && profileDobDateTime) {
			dobFieldValue = profileDobDateTime.toFormat('dd-MM-yyyy');
		}

		let genderFieldValue = null;
		if (settings.contactRequest.clientGenderRequired) {
			genderFieldValue = profile.gender;
		}

		const tel = parseInputPhoneNumber(profile.phoneNumber);

		const defaultCallbackLocation =
			settings.contactRequest.locationsForCallbackConfigs.length === 1 ? settings.contactRequest.locationsForCallbackConfigs[0].title : null;

		return {
			Name: null,
			GivenName: null,
			NameParticle: null,
			Surname: null,
			Dob: dobFieldValue,
			Gender: genderFieldValue,
			Tel: tel,
			Bsn: null,
			Email: null,
			Address: null,
			HouseNumber: null,
			ZipCode: null,
			Doctor: null,
			HapLocation: null,
			Consent: null,
			ImageData: null,
			ImageContentType: null,
			Comment: null,
			CallbackLocation: defaultCallbackLocation
		};
	}

	const [fields, setFields] = useState(initializeFields);
	const [validFields, setValidFields] = useState({
		Name: null,
		GivenName: null,
		Surname: null,
		Dob: null,
		Gender: null,
		Tel: null,
		Bsn: null,
		Email: null,
		EmailOrBsn: null,
		Address: null,
		HouseNumber: null,
		ZipCode: null,
		Doctor: null,
		HapLocation: null,
		Consent: null,
		CallbackLocation: null,
		Comment: null
	});

	const conversationHasConsultationPreparation = conversation.some((c) => c.type === 'consultationPreparationQuestion');

	const handleChangeFormField = (field) => {
		if (field.target.type === 'checkbox') {
			setFields({
				...fields,
				[field.target.name]: field.target.checked ? true : null
			});
		} else if (field.target.type === 'date') {
			setFields({
				...fields,
				[field.target.name]:
					field.target.value === '' || field.target.value.trim() === '' ? null : DateTime.fromISO(field.target.value).toFormat('dd-MM-yyyy')
			});
		} else if (field.target.type === 'file' && field.target.name === 'Image') {
			const files = field.target.files;
			if (files && files.length > 0) {
				const file = field.target.files[0];
				if (file.size <= 10485760) {
					// Max size set to 10MB
					const reader = new FileReader();
					reader.onload = function (evt) {
						const readerResult = evt.target.result;
						const base64Index = readerResult.indexOf(';base64,');
						if (base64Index !== -1) {
							const imageData = readerResult.substring(base64Index + ';base64,'.length);
							setFields({
								...fields,
								ImageData: imageData,
								ImageContentType: file.type
							});
						}
					};
					reader.readAsDataURL(file);
				} else {
					// We reset the input value because the file is to large
					field.target.value = '';
					setFields({
						...fields,
						ImageData: null,
						ImageContentType: null
					});
				}
			} else {
				setFields({
					...fields,
					ImageData: null,
					ImageContentType: null
				});
			}
		} else {
			setFields({
				...fields,
				[field.target.name]: field.target.value === '' || field.target.value.trim() === '' ? null : field.target.value
			});
		}
	};

	const handleChangePhoneField = (value) => {
		setFields({
			...fields,
			Tel: value
		});
	};

	useEffect(() => {
		const newValidFields = {
			Name: !settings.contactRequest.clientNameRequired || fields.Name !== null,
			GivenName: !settings.contactRequest.clientExtensiveNameRequired || fields.GivenName !== null,
			Surname: !settings.contactRequest.clientExtensiveNameRequired || fields.Surname !== null,
			Gender: !settings.contactRequest.clientGenderRequired || fields.Gender !== null,
			Tel: !settings.contactRequest.clientPhonenumberRequired || !!fields.Tel,
			Address: !settings.contactRequest.clientAddressRequired || fields.Address !== null,
			HouseNumber: !settings.contactRequest.clientHouseNumberRequired || fields.HouseNumber !== null,
			ZipCode: !settings.contactRequest.clientZipCodeRequired || (fields.ZipCode !== null && isNumberOfChars(fields.ZipCode) >= 4),
			Doctor: !settings.contactRequest.clientDoctorRequired || fields.Doctor !== null,
			HapLocation:
				settings.contactRequest.locationsForCallbackConfigs.length > 0 ||
				!settings.contactRequest.clientHapLocationRequired ||
				fields.HapLocation !== null ||
				settings.contactRequest.locationsForCallbackConfigs.length > 0,
			Consent: fields.Consent !== null,
			CallbackLocation: settings.contactRequest.locationsForCallbackConfigs.length < 1 || fields.CallbackLocation !== null,
			Comment: !settings.contactRequest.clientCommentRequired || fields.Comment !== null
		};

		if (settings.contactRequest.clientDateOfBirthRequired) {
			if (fields.Dob !== null) {
				const today = new Date();
				const dobDateTime = DateTime.fromFormat(fields.Dob, 'dd-MM-yyyy');
				if (dobDateTime >= today) {
					newValidFields.Dob = false;
				} else {
					newValidFields.Dob = true;
				}
			} else {
				newValidFields.Dob = false;
			}
		} else {
			newValidFields.Dob = true;
		}

		const bsnIsEmpty = fields.Bsn === null || fields.Bsn.length === 0;
		const bsnIsValid = isNumberOfChars(fields.Bsn) === 9;

		const emailIsEmpty = fields.Email === null || fields.Email.length === 0;
		const emailIsValid = validateEmail(fields.Email, settings.contactRequest.clientEmailRequired || settings.contactRequest.clientEmailOrBsnNumberRequired);

		if (settings.contactRequest.clientEmailOrBsnNumberRequired) {
			newValidFields.EmailOrBsn = !bsnIsEmpty || !emailIsEmpty;
		} else {
			newValidFields.EmailOrBsn = true;
		}

		if (settings.contactRequest.clientBsnNumberRequired) {
			newValidFields.Bsn = bsnIsValid;
		} else if (settings.contactRequest.clientBsnNumberOptional || settings.contactRequest.clientEmailOrBsnNumberRequired) {
			newValidFields.Bsn = bsnIsEmpty || bsnIsValid;
		} else {
			newValidFields.Bsn = true;
		}

		if (settings.contactRequest.clientEmailRequired) {
			newValidFields.Email = emailIsValid;
		} else if (settings.contactRequest.clientEmailOptional || settings.contactRequest.clientEmailOrBsnNumberRequired) {
			newValidFields.Email = emailIsEmpty || emailIsValid;
		} else {
			newValidFields.Email = true;
		}

		setValidFields(newValidFields);
	}, [fields, settings.contactRequest]);

	const hasCallbackRequestTypeWithTimeSchedule = settings.contactRequest.active && settings.contactRequest.hasCallbackRequestTypeWithTimeSchedule;

	const handleSendData = async () => {
		setIsDisabled(true);
		setIsSending(true);
		setHasSendDataBeenCalled(true);
		const valid = checkValidFields(validFields, session);

		if (valid) {
			props.setBackButtonStatus(true);

			const formData = {
				sessionId: session.id,
				sessionToken: session.token,
				userAgreedToPrivacyStatement: fields.Consent,
				user: {
					name: fields.Name,
					givenName: fields.GivenName,
					nameParticle: fields.NameParticle,
					surname: fields.Surname,
					dateOfBirth: fields.Dob,
					gender: fields.Gender,
					phoneNumber: fields.Tel,
					address: fields.Address,
					houseNumber: fields.HouseNumber,
					zipCode: fields.ZipCode,
					doctor: fields.Doctor,
					hapLocation: fields.HapLocation,
					bsn: fields.Bsn,
					email: fields.Email,
					imageData: fields.ImageData,
					imageContentType: fields.ImageContentType,
					comment: fields.Comment,
					callbackLocation: fields.CallbackLocation
				},
				languageCode: settings.selectedLanguage.code,
				chosenTimeslot: null
			};

			let schedule = null;
			if (hasCallbackRequestTypeWithTimeSchedule) {
				if (scheduleUseApi) {
					schedule = await GetCallbackTimeSlots(settings.ApiKey, {
						sessionId: session.id,
						sessionToken: session.token,
						callbackLocation: formData.user.callbackLocation
					});
				} else {
					schedule = developmentSchedule;
				}
			}

			if (schedule && (schedule.firstAvailable || (schedule.timeSlots && schedule.timeSlots.length > 0))) {
				if (schedule.firstAvailable) {
					const result = await CallPostCallBackEmail(settings.ApiKey, formData);
					if (result.message === 'success') {
						setIsSending(false);
						dispatch({
							type: 'conversation/addStep',
							step: {
								id: 'requestCallbackEnd',
								type: 'requestCallbackEnd',
								callbackTime: result.callbackTime,
								urgence: props.urgence,
								disableBackButton: true,
								useFirstAvailableTimeSlot: true,
								phoneNumber: fields.Tel
							}
						});
						props.handleActivityResponse({ message: result.message, callbackTime: result.callbackTime });
					} else {
						setIsError(true);
						setIsDisabled(true);
						setIsSending(false);
						props.handleActivityResponse({ message: 'error' });
					}
				} else if (schedule.timeSlots && schedule.timeSlots.length > 0) {
					setIsSending(false);
					dispatch({
						type: 'conversation/addStep',
						step: {
							id: 'callbackRequestScheduler',
							type: 'callbackRequestScheduler',
							schedule: schedule,
							fields: fields,
							urgence: props.urgence
						}
					});
				}
				props.handleActivityResponse({ message: 'scheduler', schedule: schedule });
			} else {
				// no schedule, go directy to the confirmation activity
				const result = await CallPostCallBackEmail(settings.ApiKey, formData);
				if (result.message === 'success') {
					setIsSending(false);
					dispatch({
						type: 'conversation/addStep',
						step: {
							id: 'requestCallbackEnd',
							type: 'requestCallbackEnd',
							callbackTime: result.callbackTime,
							urgence: props.urgence,
							disableBackButton: true
							//phoneNumber: fields.Tel // note: possibly add phone in this case as well to confirmation
						}
					});
					props.handleActivityResponse({ message: result.message, callbackTime: result.callbackTime });
				} else {
					setIsError(true);
					setIsDisabled(true);
					setIsSending(false);
					props.handleActivityResponse({ message: 'error' });
				}
			}
		} else {
			// form is not valid
			setIsSending(false);
			setIsDisabled(false);
		}
	};

	const isNumberOfChars = (input) => {
		if (!input) return 0;

		return input.replace(/( )/g, '').replace(/(\.)/g, '').length;
	};

	// Post session property that the contact form was shown to the user
	useEffect(() => {
		CallPostSessionLogProperties(settings.ApiKey, {
			sessionId: session.id,
			sessionToken: session.token,
			sessionLogProperties: [
				{
					sessionLogPropertyName: 'ContactFormShown',
					sessionLogPropertyValue: true
				}
			]
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let buttonLabel = '';
	if (isSending) {
		buttonLabel = getLabel('WidgetSendingButton', settings.applicationTexts, true);
	} else {
		buttonLabel = settings.contactRequest.labels.sendButton
			? settings.contactRequest.labels.sendButton
			: getLabel('WidgetSendButton', settings.applicationTexts, true);
	}

	const errorMessageLabel = getLabel('callbackRequestErrorMessage', settings.applicationTexts, true);

	// Only show validation status after send data has been called once
	const validationStatus = hasSendDataBeenCalled ? checkValidFields(validFields, session) : true;

	const hasOnlyZorgMailContactRequest = conversation.some(
		(item) => item.type === 'advice' && item.advice.startZorgMailRequestProcedure === true && !item.advice.startContactRequestProcedure
	);

	return (
		<React.Fragment>
			<SA.ActivityBubble>
				<SAT.ActivityHeader>
					<SAA.IconLabel>
						<SAA.IconBackground />
						<SAT.HeaderIcon icon={faBell} />
					</SAA.IconLabel>
					<SA.BubbleTitle>
						{/* Uw contactgegevens */}
						{getLabel('callbackRequestFormTitle', settings.applicationTexts)}
					</SA.BubbleTitle>
				</SAT.ActivityHeader>

				<SAA.FullWidthContainer>
					<SAT.LineDivider hasAdvice={true} />
				</SAA.FullWidthContainer>

				{!isError && (
					<SA.BubbleSubTitle>
						{hasOnlyZorgMailContactRequest
							? renderMarkdown(settings.contactRequest.labels.introForZorgMail)
							: renderMarkdown(
									conversationHasConsultationPreparation
										? settings.contactRequest.labels.intro
										: settings.contactRequest.labels.introWithoutConsultationPreparation
							  )}
					</SA.BubbleSubTitle>
				)}
				{!isError && (
					<SP.Form>
						{settings.contactRequest.clientNameRequired && (
							<SP.Col>
								<InputText
									onChange={handleChangeFormField}
									name="Name"
									label={getLabel('WidgetFormLabelName', settings.applicationTexts, true)}
									valid={validationStatus || validFields.Name}
									disabled={isDisabled}
								></InputText>
							</SP.Col>
						)}

						{settings.contactRequest.clientExtensiveNameRequired && (
							<>
								<SP.Col>
									<InputText
										onChange={handleChangeFormField}
										name="GivenName"
										label={getLabel('WidgetFormLabelGivenName', settings.applicationTexts, true)}
										valid={validationStatus || validFields.GivenName}
										disabled={isDisabled}
									></InputText>
								</SP.Col>
								<SP.Col>
									<InputText
										onChange={handleChangeFormField}
										name="NameParticle"
										label={getLabel('WidgetFormLabelNameParticle', settings.applicationTexts, true)}
									></InputText>
								</SP.Col>
								<SP.Col>
									<InputText
										onChange={handleChangeFormField}
										name="Surname"
										label={getLabel('WidgetFormLabelSurname', settings.applicationTexts, true)}
										valid={validationStatus || validFields.Surname}
										disabled={isDisabled}
									></InputText>
								</SP.Col>
							</>
						)}

						{settings.contactRequest.clientDateOfBirthRequired && (
							<SP.Col>
								<InputDate
									onChange={handleChangeFormField}
									name="Dob"
									label={getLabel('WidgetFormLabelDob', settings.applicationTexts, true)}
									defaultValue={profileDobDateTime?.toFormat('yyyy-MM-dd')} // Don't use fields.Dob for defaultValue because that has format 'dd-MM-yyyy'
									max={DateTime.now().toISODate()}
									valid={validationStatus || validFields.Dob}
									disabled={isDisabled}
								></InputDate>
							</SP.Col>
						)}

						{showGenderField && settings.contactRequest.clientGenderRequired && (
							<SP.Col>
								<InputGender
									onChange={handleChangeFormField}
									name="Gender"
									label={getLabel('WidgetFormLabelGender', settings.applicationTexts, true)}
									defaultValue={fields.Gender}
									valid={validationStatus || validFields.Gender}
									disabled={isDisabled}
								/>
							</SP.Col>
						)}

						{settings.contactRequest.clientPhonenumberRequired && (
							<SP.Col50>
								<InputPhone
									name="Tel"
									label={getLabel('WidgetFormLabelTel', settings.applicationTexts, true)}
									onChange={handleChangePhoneField}
									autoComplete="tel-national"
									disabled={isDisabled}
									valid={validationStatus || validFields.Tel}
									defaultValue={fields.Tel}
								/>
							</SP.Col50>
						)}

						{(settings.contactRequest.clientBsnNumberRequired ||
							settings.contactRequest.clientBsnNumberOptional ||
							settings.contactRequest.clientEmailOrBsnNumberRequired) && (
							<SP.Col>
								<InputNumber
									onChange={handleChangeFormField}
									name="Bsn"
									label={getLabel('WidgetFormLabelBsn', settings.applicationTexts, true)}
									valid={validationStatus || validFields.Bsn}
									disabled={isDisabled}
								></InputNumber>
							</SP.Col>
						)}

						{(settings.contactRequest.clientEmailRequired ||
							settings.contactRequest.clientEmailOptional ||
							settings.contactRequest.clientEmailOrBsnNumberRequired) && (
							<SP.Col>
								<InputText
									onChange={handleChangeFormField}
									name="Email"
									label={getLabel('WidgetFormLabelEmail', settings.applicationTexts, true)}
									valid={validationStatus || validFields.Email}
									disabled={isDisabled}
								></InputText>
							</SP.Col>
						)}

						{settings.contactRequest.clientAddressRequired && (
							<SP.Col>
								<InputText
									onChange={handleChangeFormField}
									name="Address"
									label={getLabel('WidgetFormLabelAddress', settings.applicationTexts, true)}
									valid={validationStatus || validFields.Address}
									disabled={isDisabled}
								></InputText>
							</SP.Col>
						)}

						{settings.contactRequest.clientHouseNumberRequired && (
							<SP.Col>
								<InputText
									onChange={handleChangeFormField}
									name="HouseNumber"
									label={getLabel('WidgetFormLabelHouseNumber', settings.applicationTexts, true)}
									valid={validationStatus || validFields.HouseNumber}
									disabled={isDisabled}
								></InputText>
							</SP.Col>
						)}

						{settings.contactRequest.clientZipCodeRequired && (
							<SP.Col>
								<InputZipCode
									onChange={handleChangeFormField}
									name="ZipCode"
									label={getLabel('WidgetFormLabelZipCode', settings.applicationTexts, true)}
									valid={validationStatus || validFields.ZipCode}
									disabled={isDisabled}
								></InputZipCode>
							</SP.Col>
						)}

						{(settings.contactRequest.clientDoctorRequired || settings.contactRequest.clientDoctorOptional) && (
							<SP.Col>
								<InputText
									onChange={handleChangeFormField}
									name="Doctor"
									label={getLabel('WidgetFormLabelDoctor', settings.applicationTexts, true)}
									valid={validationStatus || validFields.Doctor}
									disabled={isDisabled}
								></InputText>
							</SP.Col>
						)}

						{settings.contactRequest.locationsForCallbackConfigs.length > 1 && (
							<SP.Col>
								<InputCallbackLocation
									onChange={handleChangeFormField}
									name="CallbackLocation"
									label={getLabel('WidgetFormLabelSelectCallbackLocation', settings.applicationTexts, true)}
									locationsForCallbackConfigs={settings.contactRequest.locationsForCallbackConfigs}
									valid={validationStatus || validFields.CallbackLocation}
									disabled={isDisabled}
								/>
							</SP.Col>
						)}

						{settings.contactRequest.locationsForCallbackConfigs.length === 0 &&
							settings.contactRequest.clientHapLocationRequired &&
							settings.contactRequest.hapLocations &&
							settings.contactRequest.hapLocations.some((i) => i.title && i.title.length > 0) && (
								<SP.Col>
									<InputHapLocation
										onChange={handleChangeFormField}
										name="HapLocation"
										label={getLabel('WidgetFormLabelHapLocation', settings.applicationTexts, true)}
										hapLocations={settings.contactRequest.hapLocations}
										valid={validationStatus || validFields.HapLocation}
										disabled={isDisabled}
									></InputHapLocation>
								</SP.Col>
							)}

						{settings.contactRequest.clientPhotoOptional && props.showImageField && (
							<SP.Col>
								<InputFile
									onChange={handleChangeFormField}
									name="Image"
									label={getLabel('WidgetFormLabelImage', settings.applicationTexts, true)}
									accept=".png,.jpg,image/jpeg,image/png"
									disabled={isDisabled}
								></InputFile>
							</SP.Col>
						)}

						{(settings.contactRequest.clientCommentOptional || settings.contactRequest.clientCommentRequired) && (
							<SP.Row>
								<SP.Col64>
									<TextArea
										onChange={handleChangeFormField}
										name="Comment"
										autoComplete="off"
										valid={validationStatus || validFields.Comment}
										disabled={isDisabled}
										label={renderMarkdown(settings.contactRequest.labels.formLabelComment)}
									/>
								</SP.Col64>
							</SP.Row>
						)}

						<SP.Row>
							<InputCheckBox
								onChange={handleChangeFormField}
								name="Consent"
								label={renderMarkdown(settings.contactRequest.labels.consent)}
								type="checkbox"
								disabled={isDisabled}
							></InputCheckBox>
						</SP.Row>

						<SP.RowClean>
							{validationStatus === false && (
								<SP.Error>
									{renderMarkdown(settings.contactRequest.labels.introError)}
									<ul>
										{!validFields.Name && <li>{getLabel('callBackRequestErrorName', settings.applicationTexts, true)}</li>}
										{!validFields.GivenName && <li>{getLabel('callBackRequestErrorGivenName', settings.applicationTexts, true)}</li>}
										{!validFields.Surname && <li>{getLabel('callBackRequestErrorSurname', settings.applicationTexts, true)}</li>}
										{!validFields.Dob && <li>{getLabel('callBackRequestErrorDob', settings.applicationTexts, true)}</li>}
										{!validFields.Gender && <li>{getLabel('callBackRequestErrorGender', settings.applicationTexts, true)}</li>}
										{!validFields.Tel && <li>{getLabel('callBackRequestErrorTel', settings.applicationTexts, true)}</li>}
										{!validFields.Bsn && <li>{getLabel('callBackRequestErrorBsn', settings.applicationTexts, true)}</li>}
										{!validFields.Email && <li>{getLabel('callBackRequestErrorEmail', settings.applicationTexts, true)}</li>}
										{!validFields.EmailOrBsn && <li>{getLabel('callBackRequestErrorEmailOrBsn', settings.applicationTexts, true)}</li>}
										{!validFields.Address && <li>{getLabel('callBackRequestErrorAddress', settings.applicationTexts, true)}</li>}
										{!validFields.HouseNumber && <li>{getLabel('callBackRequestErrorHouseNumber', settings.applicationTexts, true)}</li>}
										{!validFields.ZipCode && <li>{getLabel('callBackRequestErrorZipCode', settings.applicationTexts, true)}</li>}
										{!validFields.Doctor && <li>{getLabel('callBackRequestErrorDoctor', settings.applicationTexts, true)}</li>}
										{!validFields.HapLocation && <li>{getLabel('callBackRequestErrorHapLocation', settings.applicationTexts, true)}</li>}
										{!validFields.Consent && <li>{getLabel('callBackRequestErrorConsent', settings.applicationTexts, true)}</li>}
										{!validFields.CallbackLocation && (
											<li>{getLabel('callBackRequestErrorSelectCallbackLocation', settings.applicationTexts, true)}</li>
										)}
										{!validFields.Comment && <li>{getLabel('callBackRequestErrorComment', settings.applicationTexts, true)}</li>}
									</ul>
								</SP.Error>
							)}

							<SA.ActivityActions>
								<SpinnerButton onClick={handleSendData} primary={true} isLoading={isSending} state={isDisabled} disabled={isDisabled}>
									{buttonLabel}
								</SpinnerButton>
							</SA.ActivityActions>
						</SP.RowClean>
					</SP.Form>
				)}
				{isError && <SP.Error>{renderMarkdown(errorMessageLabel)}</SP.Error>}
				{isError && (
					<ActivityEndActions
						callbackRequestSent={false}
						showFeedbackAction={true}
						hideDownloadButton={settings.downloadConversationDisabled}
						handleActivityResponse={props.handleActivityResponse}
					/>
				)}
			</SA.ActivityBubble>
		</React.Fragment>
	);
};

export default ProcedureContactRequest;

const InputCheckBox = (props) => {
	return (
		<React.Fragment>
			<SP.RowConsent>
				<SP.Field id={props.name} {...props} />
				<SP.LabelInline htmlFor={props.name}>{props.label}</SP.LabelInline>
			</SP.RowConsent>
		</React.Fragment>
	);
};

const InputText = (props) => {
	return (
		<React.Fragment>
			<SP.Label htmlFor={props.name}>{props.label}</SP.Label>
			<SP.Field type="text" {...props} autoComplete="off" />
		</React.Fragment>
	);
};

const InputDate = (props) => {
	return (
		<React.Fragment>
			<SP.Label htmlFor={props.name}>{props.label}</SP.Label>
			<S.DateField type="date" {...props} autoComplete="off" />
		</React.Fragment>
	);
};

const InputFile = (props) => {
	return (
		<React.Fragment>
			<SP.Label htmlFor={props.name}>{props.label}</SP.Label>
			<SP.Field type="file" {...props} />
		</React.Fragment>
	);
};

const InputNumber = (props) => {
	const inputNumberField = useRef();
	const setInputFilter = (textbox, inputFilter) => {
		['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function (event) {
			textbox.current.addEventListener(event, function () {
				if (inputFilter(this.value)) {
					this.oldValue = this.value;
					this.oldSelectionStart = this.selectionStart;
					this.oldSelectionEnd = this.selectionEnd;
				} else if (Object.prototype.hasOwnProperty.call(this, 'oldValue')) {
					this.value = this.oldValue;
					this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
				} else {
					this.value = '';
				}
			});
		});
	};

	useEffect(() => {
		setInputFilter(inputNumberField, function (value) {
			return /^[0-9]*$/.test(value);
			//return /^[\d*(.?)\s\d]*$/.test(value); // Allow digits and '.' only, using a RegExp
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<SP.Label htmlFor={props.name}>{props.label}</SP.Label>
			<SP.Field ref={inputNumberField} type="text" pattern="[0-9]*" {...props} autoComplete="off" />
		</React.Fragment>
	);
};

const InputGender = (props) => {
	const [{ settings }] = useStateContext();

	return (
		<>
			<SP.Label htmlFor={props.name}>{props.label}</SP.Label>
			<SP.FieldSelect {...props}>
				<option value=""></option>
				<option value="Male">{getLabel('GenderSelectorMaleLabel', settings.applicationTexts, true)}</option>
				<option value="Female">{getLabel('GenderSelectorFemaleLabel', settings.applicationTexts, true)}</option>
			</SP.FieldSelect>
		</>
	);
};

const InputCallbackLocation = (props) => {
	return (
		<>
			<SP.Label htmlFor={props.name}>{props.label}</SP.Label>
			<SP.FieldSelect {...props}>
				<option value=""></option>
				{props.locationsForCallbackConfigs
					.filter((i) => i.title && i.title.length > 0)
					.map((i) => (
						<option key={i.title} value={i.title}>
							{i.title}
						</option>
					))}
			</SP.FieldSelect>
		</>
	);
};

const InputHapLocation = (props) => {
	return (
		<>
			<SP.Label htmlFor={props.name}>{props.label}</SP.Label>
			<SP.FieldSelect {...props}>
				<option value=""></option>
				{props.hapLocations
					.filter((i) => i.title && i.title.length > 0)
					.map((i) => (
						<option key={i.title} value={i.title}>
							{i.title}
						</option>
					))}
			</SP.FieldSelect>
		</>
	);
};

const InputZipCode = (props) => {
	const inputZipCodeField = useRef();
	const setInputFilter = (textbox, inputFilter) => {
		['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function (event) {
			textbox.current.addEventListener(event, function () {
				if (inputFilter(this.value)) {
					this.oldValue = this.value;
					this.oldSelectionStart = this.selectionStart;
					this.oldSelectionEnd = this.selectionEnd;
				} else if (Object.prototype.hasOwnProperty.call(this, 'oldValue')) {
					this.value = this.oldValue;
					this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
				} else {
					this.value = '';
				}
			});
		});
	};

	useEffect(() => {
		setInputFilter(inputZipCodeField, function (value) {
			return /^$|^[1-9][0-9]{0,3}$|^[1-9][0-9]{3}\s?[A-Za-z]{0,2}$/.test(value);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<SP.Label htmlFor={props.name}>{props.label}</SP.Label>
			<SP.Field ref={inputZipCodeField} type="text" pattern="[1-9][0-9]{3}\s?[A-Za-z]{0,2}" {...props} autoComplete="off" />
		</React.Fragment>
	);
};
