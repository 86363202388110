import styled from 'styled-components';
import { Drawer } from 'vaul';

const DrawerWrapper = styled.div.attrs({ 'data-mindclass': 'drawerwrapper' })`
	& div[vaul-drawer] {
		z-index: 999999999 !important;
	}
`;

const DrawerOverlay = styled(Drawer.Overlay)`
	position: fixed;
	inset: 0;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 999999999 !important;
`;

const DrawerContent = styled(Drawer.Content)`
	max-height: 80vh;
	background-color: #f3f4f6;
	display: flex;
	flex-direction: column;
	margin-top: 6rem;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
`;

const DrawerInnerContent = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 1rem;
	background-color: white;
	flex-grow: 1;
`;

const DrawerHandle = styled.div`
	width: 3rem;
	height: 0.375rem;
	background-color: #d1d5db;
	//margin-bottom: 2rem; // removed the margin here, leave the margin to the elements under this one
	border-radius: full;
	margin-left: auto;
	margin-right: auto;
`;

const DrawerChildrenWrapper = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	max-width: 100%;
	//margin-left: auto;
	//margin-right: auto;
`;

const S = {
	DrawerWrapper,
	DrawerOverlay,
	DrawerContent,
	DrawerInnerContent,
	DrawerHandle,
	DrawerChildrenWrapper
};

export default S;
