import React, { ChangeEvent, forwardRef, useEffect, useMemo, useState } from 'react';

import S from './styled';

import Button from '../Button';

import { getLabel } from '../../helpers/constants/getLabels';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CalculatedAge, DateOfBirth } from '../../models';

type ValidationSatus = '' | 'valid' | 'invalid' | 'maxAgeExceded';

type AgeYearInputProps = {
	confirmButtonClassName: string;
	disabled: boolean;
	step: number;
	title: string;
	answer?: { dob?: DateOfBirth; age: CalculatedAge; yearInput?: string; ageUnit?: AgeUnit; additionalInput?: string };
	handleActivityResponse: (answer: { dob?: DateOfBirth; age: CalculatedAge; yearInput?: string; ageUnit?: AgeUnit; additionalInput?: string }) => void;
};

export enum AgeUnit {
	Year = 'jaar',
	Month = 'maanden',
	Week = 'weken'
}

type AgeYearLabels = {
	WidgetAgeYearLabel: string;
	WidgetDisplayAgeYearsUnit: string;
	WidgetDisplayAgeMonthsUnit: string;
	WidgetDisplayAgeMonthUnit: string;
	WidgetDisplayAgeWeeksUnit: string;
	WidgetDisplayAgeWeekUnit: string;
	WidgetDateOfBirthRequestError: string;
	WidgetMaxAgeRequestError: string;
	WidgetConfirmButton: string;
	WidgetAgeWeekPlaceholder: string;
	WidgetAgeMonthPlaceholder: string;
	WidgetAgeYearShortPlaceholder: string;
	WidgetAgeDescription: string;
};

const AgeYearInput = forwardRef<HTMLInputElement, AgeYearInputProps>((props: AgeYearInputProps, ref) => {
	const [{ profile, settings }, dispatch] = useStateContext();

	const labels = useMemo<AgeYearLabels>(() => {
		return {
			WidgetAgeYearLabel: getLabel('WidgetAgeYearLabel', settings.applicationTexts, true),
			WidgetDisplayAgeYearsUnit: getLabel('WidgetDisplayAgeYearsUnit', settings.applicationTexts, true),
			WidgetDisplayAgeMonthsUnit: getLabel('WidgetDisplayAgeMonthsUnit', settings.applicationTexts, true),
			WidgetDisplayAgeMonthUnit: getLabel('WidgetDisplayAgeMonthUnit', settings.applicationTexts, true),
			WidgetDisplayAgeWeeksUnit: getLabel('WidgetDisplayAgeWeeksUnit', settings.applicationTexts, true),
			WidgetDisplayAgeWeekUnit: getLabel('WidgetDisplayAgeWeekUnit', settings.applicationTexts, true),
			WidgetDateOfBirthRequestError: getLabel('WidgetDateOfBirthRequestError', settings.applicationTexts, true),
			WidgetMaxAgeRequestError: getLabel('WidgetMaxAgeRequestError', settings.applicationTexts, true),
			WidgetConfirmButton: getLabel('WidgetConfirmButton', settings.applicationTexts, true),
			WidgetAgeWeekPlaceholder: getLabel('WidgetAgeWeekPlaceholder', settings.applicationTexts, true),
			WidgetAgeMonthPlaceholder: getLabel('WidgetAgeMonthPlaceholder', settings.applicationTexts, true),
			WidgetAgeYearShortPlaceholder: getLabel('WidgetAgeYearShortPlaceholder', settings.applicationTexts, true),
			WidgetAgeDescription: getLabel('WidgetAgeDescription', settings.applicationTexts, true)
		};
	}, [settings.applicationTexts]);

	const [age, setAge] = useState<CalculatedAge>(getAgeDefaultValueFunction(settings.forceInitialStateEmpty, props.answer?.age));

	const [initialAge] = useState<string | null>(profile.age ?? null); // profile.age could be set from url parameter (initialState.ts)

	const [yearInput, setYearInput] = useState<string>(props.answer && props.answer.yearInput ? props.answer.yearInput : '');

	const [ageUnit, setAgeUnit] = useState<AgeUnit>(props.answer && props.answer.ageUnit ? props.answer.ageUnit : AgeUnit.Year);
	const [additionalInput, setAdditionalInput] = useState<string>(props.answer && props.answer.additionalInput ? props.answer.additionalInput : '');
	const [validationStatus, setvalidationStatus] = useState<ValidationSatus>('');

	const showAdditionalInput: boolean = ageUnit === AgeUnit.Month || ageUnit === AgeUnit.Week;

	const updateAgeUnit = (newAgeUnit: AgeUnit) => {
		setAgeUnit(newAgeUnit);

		const inputValue = newAgeUnit === AgeUnit.Year ? yearInput : additionalInput;
		const recalculatedAge = getCalculatedAge(inputValue, newAgeUnit, labels);

		if (recalculatedAge) {
			setAge(recalculatedAge);

			dispatch({
				type: 'updateProfile/Age',
				dob: { day: undefined, month: undefined, year: undefined },
				age: recalculatedAge.age
			});
		} else {
			setAge({ age: '', ageInYears: 0, displayAge: '' });

			dispatch({
				type: 'updateProfile/Age',
				dob: { day: undefined, month: undefined, year: undefined },
				age: undefined
			});
		}
	};

	useEffect(() => {
		// profile.age isn't used here directly because that would trigger a rerun (via handleOnChange)
		// of this effect if profile.age is added to the dependency list. And omitting profile.age from the dependency list
		// would result in a compiler warning. Therefore introduced variable 'initialAge'.
		if (initialAge) {
			if (initialAge === '0') {
				setAgeUnit(AgeUnit.Month);
				setYearInput(initialAge);
				setAge({ age: '0', ageInYears: 0, displayAge: '' });
			} else {
				setAgeUnit(AgeUnit.Year);
				const calculatedAge = getCalculatedAge(initialAge, AgeUnit.Year, labels);
				if (calculatedAge) {
					setYearInput(calculatedAge.ageInYears.toString());
					setAge(calculatedAge);
					setvalidationStatus('valid'); // note: in other places is checked for valid values
				}
			}
		}
	}, [initialAge, labels]);

	const handleOnChange = (field: ChangeEvent<HTMLInputElement>) => {
		const value = field.target.value.slice(0, field.target.maxLength).replace(/\D/, '');

		if (value === '0') {
			setAgeUnit(AgeUnit.Month);
			setYearInput(value);
			setAge({ age: '0', ageInYears: 0, displayAge: '' });
		} else {
			setAgeUnit(AgeUnit.Year);
			const calculatedAge = getCalculatedAge(value, AgeUnit.Year, labels);
			if (calculatedAge) {
				setYearInput(value);
				setAge(calculatedAge);

				dispatch({
					type: 'updateProfile/Age',
					dob: { day: undefined, month: undefined, year: undefined },
					age: calculatedAge.age
				});

				if (calculatedAge.ageInYears > settings.maxAge) {
					setvalidationStatus('maxAgeExceded');
				} else {
					setvalidationStatus('valid');
				}
			} else {
				setYearInput('');
				setAge({ age: '', ageInYears: 0, displayAge: '' });

				dispatch({
					type: 'updateProfile/Age',
					dob: { day: undefined, month: undefined, year: undefined },
					age: undefined
				});

				setvalidationStatus('invalid');
			}
		}
	};

	const handleOnChangeAdditional = (field: ChangeEvent<HTMLInputElement>) => {
		const value = field.target.value.slice(0, field.target.maxLength).replace(/\D/, '');

		const intValue = parseInt(value, 10);
		if ((ageUnit === AgeUnit.Month && intValue > 24) || (ageUnit === AgeUnit.Week && intValue > 52)) {
			setvalidationStatus('maxAgeExceded');
		} else {
			const calculatedAge = getCalculatedAge(value, ageUnit, labels);
			if (calculatedAge) {
				setAdditionalInput(value);
				setAge(calculatedAge);

				dispatch({
					type: 'updateProfile/Age',
					dob: { day: undefined, month: undefined, year: undefined },
					age: calculatedAge.age
				});

				if (calculatedAge.ageInYears > settings.maxAge) {
					setvalidationStatus('maxAgeExceded');
				} else {
					setvalidationStatus('valid');
				}
			} else {
				setAdditionalInput('');
				setAge({ age: '', ageInYears: 0, displayAge: '' });

				dispatch({
					type: 'updateProfile/Age',
					dob: { day: undefined, month: undefined, year: undefined },
					age: undefined
				});

				setvalidationStatus('invalid');
			}
		}
	};

	const handleActivityResponse = () => {
		const calculatedAge = age;
		if (calculatedAge?.age && calculatedAge.ageInYears > 0) {
			if (calculatedAge.ageInYears > settings.maxAge) {
				setvalidationStatus('maxAgeExceded');
			} else {
				props.handleActivityResponse({
					dob: { day: undefined, month: undefined, year: undefined },
					age: calculatedAge,
					yearInput: yearInput,
					ageUnit: ageUnit,
					additionalInput: additionalInput
				});

				setvalidationStatus('valid');
			}
		} else {
			setvalidationStatus('invalid');
		}
	};

	const handleSelectWeeks = () => {
		updateAgeUnit(AgeUnit.Week);
	};

	const handleSelectMonths = () => {
		updateAgeUnit(AgeUnit.Month);
	};

	return (
		<S.Form
			onSubmit={(e) => {
				e.preventDefault();
			}}
		>
			<S.FieldSet>
				<S.Legend>{props.title}</S.Legend>
				<S.Label htmlFor={`MINDD-Widget-${props.step}-AgeYearInput`}>{labels.WidgetAgeYearLabel}</S.Label>
				<S.InputLarge
					ref={ref}
					id={`MINDD-Widget-${props.step}-AgeYearInput`}
					disabled={props.disabled}
					autoComplete="off"
					name="year"
					maxLength={3}
					type="number"
					inputMode="numeric"
					pattern="[0-9]*"
					placeholder={labels.WidgetAgeYearShortPlaceholder}
					value={yearInput}
					onChange={handleOnChange}
				/>
				{showAdditionalInput && (
					<div>
						<S.Row>{labels.WidgetAgeDescription}</S.Row>
						<S.Row>
							<S.AgeUnitButton onClick={handleSelectMonths} state={ageUnit === AgeUnit.Month}>
								{labels.WidgetDisplayAgeMonthsUnit}
							</S.AgeUnitButton>
							<S.AgeUnitButton onClick={handleSelectWeeks} state={ageUnit === AgeUnit.Week}>
								{labels.WidgetDisplayAgeWeeksUnit}
							</S.AgeUnitButton>
						</S.Row>
						<S.Row>
							<S.InputLarge
								ref={ref}
								id={`MINDD-Widget-${props.step}-AgeAdditionalInput`}
								disabled={props.disabled}
								autoComplete="off"
								name="additionalInput"
								maxLength={3}
								type="number"
								inputMode="numeric"
								pattern="[0-9]*"
								placeholder={ageUnit === AgeUnit.Month ? labels.WidgetAgeMonthPlaceholder : labels.WidgetAgeWeekPlaceholder}
								value={additionalInput}
								onChange={handleOnChangeAdditional}
							/>
							{ageUnit === AgeUnit.Month ? labels.WidgetDisplayAgeMonthsUnit : labels.WidgetDisplayAgeWeeksUnit}
						</S.Row>
					</div>
				)}
			</S.FieldSet>
			{validationStatus === 'invalid' && <S.Error>{labels.WidgetDateOfBirthRequestError}</S.Error>}
			{validationStatus === 'maxAgeExceded' && <S.Error>{labels.WidgetMaxAgeRequestError}</S.Error>}
			<S.Row>
				<Button onClick={handleActivityResponse} state={props.answer ? true : false} disabled={props.disabled} className={props.confirmButtonClassName}>
					{age?.displayAge ? `${labels.WidgetConfirmButton} (${age.displayAge})` : labels.WidgetConfirmButton}
				</Button>
			</S.Row>
		</S.Form>
	);
});

AgeYearInput.displayName = 'AgeYearInput';
export default AgeYearInput;

const getAgeDefaultValueFunction = (forceInitialStateEmpty: boolean | undefined, answerAge: CalculatedAge | undefined): (() => CalculatedAge) => {
	return () => {
		const defaultAge: CalculatedAge = answerAge ? answerAge : { age: '', ageInYears: 0, displayAge: '' };
		if (forceInitialStateEmpty) {
			return defaultAge;
		}

		// We do not have enough profile information to set all fields so reset input
		return { age: '', ageInYears: 0, displayAge: '' };
	};
};

const getCalculatedAge = (value: string, ageUnit: AgeUnit, labels: AgeYearLabels): CalculatedAge | undefined => {
	const intValue = parseInt(value, 10);

	if (isNaN(intValue)) {
		return undefined;
	}

	let ageInYears: number | undefined = undefined;
	let displayAgeUnit = '';

	switch (ageUnit) {
		case AgeUnit.Year:
			ageInYears = intValue;
			displayAgeUnit = labels.WidgetDisplayAgeYearsUnit;
			break;
		case AgeUnit.Month:
			ageInYears = intValue / 12;
			if (intValue > 1) {
				displayAgeUnit = labels.WidgetDisplayAgeMonthsUnit;
			} else {
				displayAgeUnit = labels.WidgetDisplayAgeMonthUnit;
			}
			break;
		case AgeUnit.Week:
			ageInYears = intValue / 52;
			if (intValue > 1) {
				displayAgeUnit = labels.WidgetDisplayAgeWeeksUnit;
			} else {
				displayAgeUnit = labels.WidgetDisplayAgeWeekUnit;
			}
			break;
	}

	return {
		age: ageInYears.toFixed(3),
		ageInYears: ageInYears,
		displayAge: `${intValue} ${displayAgeUnit}`
	};
};
