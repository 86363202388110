import React from 'react';
import SessionSummaryForm from '../SessionSummaryForm';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import { CallGetSessionSummary } from '../../helpers/services';
import AwesomeButton from '../AwesomeButton/AwesomeButton';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import S from './DownloadButton.styled';

const DownloadButton = () => {
	const [{ profile, settings, session }] = useStateContext();

	const handleSessionReportDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		CallGetSessionSummary(settings.ApiKey, {
			sessionId: session.id,
			sessionToken: session.token,
			pdfType: 'Default',
			languageCode: settings.selectedLanguage.code
		})
			.then((sessionSummary) => {
				if (!sessionSummary) return;

				const url = window.URL.createObjectURL(sessionSummary as Blob);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'file.pdf');
				link.download = `MINDD-Zelftriage-${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`;
				document.body.appendChild(link);
				link.click();
				link.remove();
			})
			.catch((error) => {
				console.error('Error downloading session report:', error);
			});
	};

	return (
		<>
			{/* U kunt hier het hele gespreksverslag en advies downloaden. */}
			{(!settings.prefill || (settings.prefill && settings.prefill.active !== true)) && (
				<S.DownloadButton>
					{!settings.getSessionSummaryUsingForm && (
						<AwesomeButton onClick={handleSessionReportDownload} icon={faDownload} variant="link">
							{getLabel('SessionReportDownloadLink', settings.applicationTexts, true)}
						</AwesomeButton>
					)}
					{settings.getSessionSummaryUsingForm && (
						<SessionSummaryForm
							showAsDownloadLink={true}
							fields={{ Name: profile.name, Dob: `${profile.dob.day ?? ''}-${profile.dob.month ?? ''}-${profile.dob.year ?? ''}` }}
							title={getLabel('SessionReportDownloadLinkTitle', settings.applicationTexts, true)}
						>
							{getLabel('SessionReportDownloadLink', settings.applicationTexts, true)}
						</SessionSummaryForm>
					)}
				</S.DownloadButton>
			)}
		</>
	);
};

export default DownloadButton;
