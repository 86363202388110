import React from 'react';

import S from './EndButtons.styled';

import { useResetWidget } from '../../helpers/hooks/useResetWidget';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import AwesomeButton from '../AwesomeButton/AwesomeButton';
import { faComment, faRefresh } from '@fortawesome/free-solid-svg-icons';

interface EndButtonProps {
	showFeedbackAction: boolean;
	handleActivityResponse: (messageObject: object) => void;
	disabled?: boolean;
}

const EndButtons = (props: EndButtonProps) => {
	const [{ conversation, settings }, dispatch] = useStateContext();
	const resetWidget = useResetWidget();
	const showFeedback = typeof settings.showFeedback === 'boolean' ? settings.showFeedback : true;
	const showFeedbackAction = typeof props.showFeedbackAction === 'undefined' ? true : props.showFeedbackAction;
	const conversationHasFeedback = conversation.some((c) => c.type === 'feedback');

	const handleFeedback = () => {
		props.handleActivityResponse({ message: 'feedback' });
		dispatch({
			type: 'conversation/addStep',
			step: {
				step: 0,
				id: 'endActions-Feedback',
				type: 'feedback'
			}
		});
	};

	const handleRestart = () => {
		resetWidget(true).catch(console.error);
	};

	return (
		<>
			{showFeedback && showFeedbackAction && (
				<S.EndButtonsContainer justifyContent={'space-between'}>
					<AwesomeButton disabled={props.disabled || conversationHasFeedback} onClick={() => handleFeedback()} icon={faComment} variant="link">
						{getLabel('AdviceButtonLabelFeedback', settings.applicationTexts)}
					</AwesomeButton>
					<AwesomeButton onClick={() => handleRestart()} icon={faRefresh} variant="link">
						{getLabel('AdviceButtonLabelRestart', settings.applicationTexts)}
					</AwesomeButton>
				</S.EndButtonsContainer>
			)}
			{!(showFeedback && showFeedbackAction) && (
				<S.EndButtonsContainer justifyContent={'flex-end'}>
					<AwesomeButton onClick={() => handleRestart()} icon={faRefresh} variant="link">
						{getLabel('AdviceButtonLabelRestart', settings.applicationTexts)}
					</AwesomeButton>
				</S.EndButtonsContainer>
			)}
		</>
	);
};

export default EndButtons;
