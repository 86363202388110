import styled from 'styled-components';
import { default as SA } from '../Activity/styled';
import { default as SP } from '../Procedure/styled';
import LanguageSelector from '../LanguageSelector';

const S = {};

S.EmpathyBubbleTitle = styled(SA.BubbleTitle).attrs({ 'data-minddclass': 'empathy', as: 'div' })``;

S.LanguageSelector = styled(LanguageSelector)`
	position: absolute;
	inset-inline-end: 30px;

	@media ${(props) => props.theme.screens.small} {
		inset-inline-end: 15px;
	}
`;

S.BubbleTitle = styled(SA.BubbleTitle)`
	${S.LanguageSelector} + & {
		width: calc(100% - 80px);
		min-height: 64px;
	}
`;

S.ActivityBubble = styled(SA.ActivityBubble)`
	&&& {
		max-height: none;
	}
`;

S.Form = styled(SP.Form)`
	margin: 1rem 0;
`;

S.TriageSearchContainer = styled.div.attrs({ 'data-minddclass': 'triagesearchcontainer' })`
	position: relative;

	${SP.FieldRound} {
		padding-left: 29px;

		:hover {
			box-shadow: 0 1px 6px ${(props) => props.theme.colors.accent};
		}
	}

	svg {
		position: absolute;
		top: 6px;
		left: 5px;
		width: 24px;
		height: 24px;
	}
`;

S.Complaints = styled.div.attrs({ 'data-minddclass': 'complaints' })``;

S.ComplaintsTitle = styled.div.attrs({ 'data-minddclass': 'complaintstitle' })`
	width: calc(100% - 0.35rem);
	color: ${(props) => props.theme.colors.text};
	text-transform: uppercase;
	display: flex;
	align-items: center;
	white-space: nowrap;
	margin-inline-start: 0.35rem;
	:after {
		content: ' ';
		display: inline-block;
		height: 1px;
		background-color: ${(props) => props.theme.colors.text};
		width: 100%;
		margin-inline-start: 7px;
	}
	@media ${(props) => props.theme.screens.small} {
		display: none;
	}
`;

S.ComplaintsEmpty = styled.div.attrs({ 'data-minddclass': 'complaintsempty' })`
	margin-inline-start: 0.35rem;
	font-style: italic;
`;

S.ComplaintsList = styled(SA.ComplaintsList)``;

S.Complaint = styled(SA.Complaint)``;

S.ComplaintButton = styled(SA.ComplaintButton)``;

export default S;
