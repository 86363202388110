import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import animateScrollTo from 'animated-scroll-to';

import S from './styled';

import Activity from '../Activity';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { useDetermineNextStep } from '../../helpers/hooks/nextStep/useDetermineNextStep';
import { useToggleModal } from '../../helpers/hooks/useToggleModal';

const Conversation = (props) => {
	const [{ settings, conversation, refs, modal }, dispatch] = useStateContext();
	const determineNextStep = useDetermineNextStep();
	const conversationRef = useRef();
	const [filler, setFiller] = useState(0);
	const handleToggleModal = useToggleModal();

	const lastActivityIndex = conversation && conversation.length > 0 ? conversation.length - 1 : 0;

	const continueConversation = async (activityStep) => {
		const nextStep = await determineNextStep(activityStep);
		if (nextStep) {
			dispatch({
				type: 'conversation/addStep',
				step: nextStep
			});
		}
	};

	useEffect(() => {
		if (settings.sessionTimeOutInSec > 0 && modal.open) {
			const timeoutId = setTimeout(() => {
				handleToggleModal();
				dispatch({
					type: 'updateProfile/reset',
					forceInitialStateEmpty: true // make sure date of birth is empty on widget restart
				});
			}, settings.sessionTimeOutInSec * 1000);
			return () => {
				clearTimeout(timeoutId);
			};
		}
	}, [modal.open, conversation, settings.sessionTimeOutInSec, handleToggleModal, dispatch]);

	const getLastItem = (from) => {
		return from.children.item(conversationRef.current.children.length - 1) || false;
	};

	const getBoundingClientRectJSON = (element) => {
		const { top, right, bottom, left, width, height, x, y } = element.getBoundingClientRect();
		return { top, right, bottom, left, width, height, x, y };
	};

	useLayoutEffect(() => {
		// This value is linked to the active padding-top of the Widget component, formula is ({Widget padding-top in px} * -1) + 20
		const hasHeader = modal.open && settings.branding && settings.branding.layout;
		const verticalOffSet = hasHeader ? -17 : 10;
		// Since we don't see conversation height to 100% anymore (because of issues at specific huisartsenpost site)
		// we need to get the bounding client rect from the parent element to calculate the filler
		const conversationDimension = getBoundingClientRectJSON(conversationRef.current.parentElement) || false;
		const lastItem = getLastItem(conversationRef.current);
		const lastItemDimension = lastItem ? getBoundingClientRectJSON(lastItem) : false;

		if (lastItemDimension && modal.open) {
			let fillerSize = conversationDimension.height - lastItemDimension.height - verticalOffSet;
			fillerSize = fillerSize < 0 ? 0 : fillerSize;
			fillerSize = fillerSize === filler ? filler + 1 : fillerSize; // fix issue to force next effect below even when value is the same
			setFiller(fillerSize);
		}
		// We use filler and call setFiller, adding to dependency array would result in an infinite loop
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [conversation, modal.open, settings.branding]);

	useLayoutEffect(() => {
		const verticalOffSet = modal.open && settings.branding && settings.branding.layout ? 30 : 30;
		const lastItem = getLastItem(conversationRef.current);
		if (refs.widget && refs.widget.current && lastItem) {
			animateScrollTo(lastItem, {
				verticalOffset: verticalOffSet * -1, //localFillerSize === 0 ? -90 : 0,
				elementToScroll: refs.widget.current
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filler, modal.open, refs.widget]);

	return (
		<S.Conversation ref={conversationRef} filler={filler}>
			{conversation.map((item, index) => {
				return (
					<Activity
						{...item}
						key={index}
						step={index}
						isFirstActivity={index === 0}
						isLastActivity={index === lastActivityIndex}
						continueConversation={continueConversation}
					/>
				);
			})}
		</S.Conversation>
	);
};

export default Conversation;
