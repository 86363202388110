import React from 'react';

import { default as SA } from '../Activity/styled';
import S from './styled';

import ActivityBubbleTitle from '../ActivityBubbleTitle';
import PainSlider from '../PainSlider';
import Logo from '../Logo';
import { ToggleButton } from '../Button';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';

const ActivityTriage = (props) => {
	const [{ settings, modal }] = useStateContext();

	const { question } = props;

	const answers =
		question.type !== 'MultipleChoice'
			? [
					{
						title: question.yesLabel || getLabel('QuestionDefaultYesLabel', settings.applicationTexts, true),
						value: 'y'
					},
					{
						title: question.noLabel || getLabel('QuestionDefaultNoLabel', settings.applicationTexts, true),
						value: 'n'
					}
			  ]
			: question.answers
			? question.answers
			: [];

	const handleActivityResponse = async (answer) => {
		if (answer) {
			await props.handleActivityResponse(answer); // handle answer registration centrally.
		}
	};

	return (
		<SA.ActivityGroup>
			{props.empathy && (
				<SA.ActivityBubble>
					<S.EmpathyBubbleTitle>{renderMarkdown(props.empathy)}</S.EmpathyBubbleTitle>
				</SA.ActivityBubble>
			)}
			<S.ActivityBubble>
				<ActivityBubbleTitle
					title={question.title}
					info={question.info}
					isFirstActivity={props.isFirstActivity}
					isLastActivity={props.isLastActivity}
					modalOpen={props.modalOpen}
					disabled={props.disabled}
					widgetIsNarrow={props.widgetIsNarrow}
				/>

				{question.type === 'Normal' && (
					<SA.ActivityActions>
						{answers.map((answer, index) => (
							<ToggleButton
								key={index}
								className={!modal.open && `MWO_${Math.random().toString(36).substr(2, 6)}`}
								disabled={props.disabled}
								buttonIndex={index}
								totalButtons={answers.length}
								state={props.answer === answer.value ? true : false}
								onClick={() => handleActivityResponse(`${answer.value}`)}
							>
								{answer.title}
							</ToggleButton>
						))}
					</SA.ActivityActions>
				)}

				{question.type === 'Scale' && <PainSlider disabled={props.disabled} handleActivityResponse={handleActivityResponse} />}

				{question.type === 'MultipleChoice' && (
					<S.TriageAnswerButtons>
						{answers.map((answer) => (
							<S.Button
								key={answer.id}
								disabled={props.disabled}
								state={props.answer === answer.id ? true : false}
								onClick={() => handleActivityResponse(answer.id)}
							>
								{answer.title}
							</S.Button>
						))}
					</S.TriageAnswerButtons>
				)}

				{!modal.open && !settings.hide_footer && <Logo align="end" size="145" />}
			</S.ActivityBubble>
		</SA.ActivityGroup>
	);
};

export default ActivityTriage;
