import { DateOfBirth } from '../../models/DateOfBirth';

/**
 *  Returns whether the supplied date of birth is valid
 *
 * @param dobToCheck - The date of birth to check
 * @returns True if the date of birth is valid, else false
 */
export function validateDob(dobToCheck: DateOfBirth): boolean {
	if (!dobToCheck.day || !dobToCheck.month || !dobToCheck.year) {
		return false;
	}

	const year = parseInt(dobToCheck.year, 10);
	const month = parseInt(dobToCheck.month, 10);
	const day = parseInt(dobToCheck.day, 10);

	// If any of the parseInt results in NaN the comparision will result in false
	if (year > 999 && year < 9999 && month >= 1 && month <= 12 && day >= 1 && day <= 31) {
		const parsedDob = new Date(year, month - 1, day, 0, 0, 0, 0);

		if (parsedDob.getDate() !== day || parsedDob.getMonth() + 1 !== month) {
			return false;
		}

		const today = new Date();
		if (parsedDob < today) {
			return true;
		}
	}

	return false;
}
