import React from 'react';

import S from './styled';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';

const InfoButton = (props) => {
	const [{ settings }] = useStateContext();

	return (
		<S.InfoButton {...props} title={getLabel('QuestionMoreInformationButtonTitle', settings.applicationTexts, true)}>
			<svg viewBox="0 0 330 330" xmlns="http://www.w3.org/2000/svg">
				<g>
					<path d="M164.998,70c-11.026,0-19.996,8.976-19.996,20.009c0,11.023,8.97,19.991,19.996,19.991 c11.026,0,19.996-8.968,19.996-19.991C184.994,78.976,176.024,70,164.998,70z" />
					<path d="M165,140c-8.284,0-15,6.716-15,15v90c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15v-90C180,146.716,173.284,140,165,140z" />
				</g>
			</svg>
		</S.InfoButton>
	);
};

export default InfoButton;
