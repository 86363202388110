import React, { useState, useCallback } from 'react';
import { DateTime } from 'luxon';
import { default as S } from './styled';
import { default as SA } from '../Activity/styled';
import { default as SAA } from '../ActivityAdvice/styled';
import { default as SAT } from '../Activity/Activity.styled';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import { getLabel } from '../../helpers/constants/getLabels';
import SurveyIntro from '../SurveyIntro';
import EndButtons from '../EndButtons/EndButtons';
import { faCalendarCheck, faClock } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faPhone } from '@fortawesome/free-solid-svg-icons';
import { fullDate, timeInterval, timeRange } from '../../helpers/support/dateTimeHelpers';

const ProcedureContactRequestEnd = (props) => {
	const [{ conversation, settings }] = useStateContext();
	const [showFollowupRestart, setShowFollowupRestart] = useState(true);
	const { callbackTime, urgence, timeSlot, phoneNumber, useFirstAvailableTimeSlot } = props;

	let callbackDateTime = undefined;
	if (callbackTime) {
		callbackDateTime = DateTime.fromISO(callbackTime);
	}

	const focusLastActivity = useCallback(
		(node) => {
			if (node && props.isLastActivity && props.modalOpen) {
				node.focus();
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const handleSurveyAction = async (answer) => {
		setShowFollowupRestart(false);
		await props.handleActivityResponse({ ...props.answer, surveyAnswerId: answer.answerId, surveyAnswerText: answer.answerText });
	};

	const handleActivityEndActions = (answer) => {
		switch (answer.message) {
			case 'feedback':
				props.handleActivityResponse({ ...props.answer, isFeedbackStarted: true });
				break;
			// no default
		}
	};

	const hasOnlyAdviceWithZorgMail = conversation.some(
		(item) => item.type === 'advice' && item.advice.startZorgMailRequestProcedure === true && !item.advice.startContactRequestProcedure
	);

	let successLabel = settings.contactRequest.labels['successUrgency' + urgence.replace('U', '')];

	if (successLabel) {
		if (callbackDateTime) {
			const daysDiff = callbackDateTime.diffNow('days');
			if (daysDiff.days < 1) {
				successLabel = successLabel.replace(
					'{CallbackTime}',
					callbackDateTime.toJSDate().toLocaleString(settings.selectedLanguage.locale, { timeStyle: 'short' })
				);
			} else {
				successLabel = successLabel.replace(
					'{CallbackTime}',
					callbackDateTime.toJSDate().toLocaleString(settings.selectedLanguage.locale, { dateStyle: 'short', timeStyle: 'short' })
				);
			}
		} else {
			successLabel = successLabel.replace('{CallbackTime}', 'onbekend');
		}
	}

	return (
		<SA.ActivityBubble>
			<SAT.ActivityHeader>
				<SAA.IconLabel>
					<SAA.IconBackground />
					<SAT.HeaderIcon icon={faCalendarCheck} />
				</SAA.IconLabel>
				<SA.BubbleTitle ref={focusLastActivity} tabIndex={-1}>
					{/* Terugbelverzoek ingepland of ZorgMail bericht verzonden */}
					{hasOnlyAdviceWithZorgMail
						? getLabel('ZorgMailRequestSentTitle', settings.applicationTexts)
						: getLabel('callbackRequestSentTitle', settings.applicationTexts)}
				</SA.BubbleTitle>
			</SAT.ActivityHeader>

			<SAA.FullWidthContainer>
				<SAT.LineDivider hasAdvice={true} />
			</SAA.FullWidthContainer>

			{timeSlot && (
				<>
					<div>
						<p>{getLabel('callbackRequestConfirmationIntro', settings.applicationTexts, true)}</p>
						<b>{getLabel('timeSlotCallbackRequestTitle', settings.applicationTexts, true)}</b>
					</div>
					<S.SuccessMessageContainer>
						<S.SuccessMessageIcon icon={faClock} />
						<S.Confirmation>
							<div>{fullDate(timeSlot.start, settings.selectedLanguage.code, true)}</div>
							<div>{timeInterval(timeSlot.start, timeSlot.end, settings.selectedLanguage.code)}</div>
						</S.Confirmation>
					</S.SuccessMessageContainer>
					<S.SuccessMessageContainer>
						<S.SuccessMessageIcon icon={faPhone} />
						<S.Confirmation>{phoneNumber}</S.Confirmation>
					</S.SuccessMessageContainer>
				</>
			)}

			{useFirstAvailableTimeSlot && callbackTime && (
				<>
					<div>
						<p>{getLabel('callbackRequestConfirmationIntro', settings.applicationTexts, true)}</p>
						<b>{getLabel('timeSlotCallbackRequestTitle', settings.applicationTexts, true)}</b>
					</div>
					<S.SuccessMessageContainer>
						<S.SuccessMessageIcon icon={faClock} />
						<S.Confirmation>
							<div>{fullDate(callbackTime, settings.selectedLanguage.code, true)}</div>
							<div>{timeRange(callbackTime, settings.selectedLanguage.code)}</div>
						</S.Confirmation>
					</S.SuccessMessageContainer>
					<S.SuccessMessageContainer>
						<S.SuccessMessageIcon icon={faPhone} />
						<S.Confirmation>{phoneNumber}</S.Confirmation>
					</S.SuccessMessageContainer>
				</>
			)}

			{/* unscheduled */}
			{!timeSlot && (!useFirstAvailableTimeSlot || !callbackTime) && (
				<S.SuccessMessageContainer>
					<S.SuccessMessageIcon icon={faCheck} />
					<S.SuccessLabel>{successLabel && renderMarkdown(successLabel)}</S.SuccessLabel>
				</S.SuccessMessageContainer>
			)}

			{settings.survey.active && settings.survey.surveyId > 0 ? (
				<>
					<SAA.FullWidthContainer>
						<SA.LineDevider hasAdvice={true} />
					</SAA.FullWidthContainer>

					<SurveyIntro disabled={props.disabled} handleActivityResponse={handleSurveyAction} />
				</>
			) : (
				showFollowupRestart && (
					<>
						<SAA.FullWidthContainer>
							<SA.LineDevider hasAdvice={true} />
						</SAA.FullWidthContainer>

						<EndButtons showFeedbackAction={true} handleActivityResponse={handleActivityEndActions} />
					</>
				)
			)}
		</SA.ActivityBubble>
	);
};

export default ProcedureContactRequestEnd;
