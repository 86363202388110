import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { Language } from '../../models';

export type GetLanguagesOutput = {
	languages: Language[];
};

async function CallGetLanguages(apiKey: string): Promise<Language[]> {
	try {
		const { data } = await minddApiAxios.get<GetLanguagesOutput>('GetLanguages?api-version=2.1', {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return data.languages;
	} catch (error) {
		logAxiosError(error, 'CallGetLanguages');
	}

	return [];
}

export { CallGetLanguages };
