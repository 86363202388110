import { DateTime } from 'luxon';
import { CallbackRequestSchedule, ScheduleDay } from '../../models';

export const fillMissingDates = (schedule: CallbackRequestSchedule): CallbackRequestSchedule => {
	// Ensure there's a fallback for potentially null timeSlots
	const filledSchedule: CallbackRequestSchedule = {
		...schedule,
		timeSlots: schedule.timeSlots ? [...schedule.timeSlots] : []
	};

	// If timeSlots is null or empty, return filledSchedule early
	if (!filledSchedule.timeSlots || filledSchedule.timeSlots.length === 0) {
		return filledSchedule;
	}

	let currentDate: DateTime = DateTime.fromISO(filledSchedule.timeSlots[0].date);
	const endDate: DateTime = DateTime.fromISO(filledSchedule.timeSlots[filledSchedule.timeSlots.length - 1].date);

	const filledSlots: ScheduleDay[] = [];

	while (currentDate <= endDate) {
		const dateString: string = currentDate.toISODate();
		const existingSlot = filledSchedule.timeSlots.find((slot) => DateTime.fromISO(slot.date).toISODate() === dateString);

		if (existingSlot) {
			filledSlots.push(existingSlot);
		} else {
			filledSlots.push({
				date: currentDate.toUTC().startOf('day').toISO(),
				slots: []
			});
		}

		currentDate = currentDate.plus({ days: 1 });
	}

	filledSchedule.timeSlots = filledSlots;
	return filledSchedule;
};
