import styled from 'styled-components';
import { default as ST } from '../ActivityTriage/styled';

const S = {};

S.EmpathyBubbleTitle = styled(ST.EmpathyBubbleTitle)``;

S.ActivityBubble = styled(ST.ActivityBubble)``;

S.TriageAnswerButtons = styled(ST.TriageAnswerButtons)``;

S.Button = styled(ST.Button)``;

export default S;
