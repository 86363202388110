// general styles which can be used by various componenents

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const ActivityHeader = styled.div`
	&&& {
		position: relative;
		display: flex;
		align-items: center;
		margin-bottom: 15px;
		h2 {
			font-weight: bold !important;
			padding-left: 10px;
		}
		@media ${(props) => props.theme.screens.medium} {
			margin-bottom: 24px;
		}
	}
`;

const HeaderIcon = styled(FontAwesomeIcon)`
	width: 1.5rem;
	height: 1.5rem;
`;

interface LineDividerProps {
	hasAdvice?: boolean;
}

const LineDivider = styled.label<LineDividerProps>`
	width: 100%;
	display: flex;
	height: 2px;
	background-color: ${(props) => (props.hasAdvice ? props.theme.colors.advicebackground : props.theme.colors.appbackground)};
	margin: 15px 0px;
`;

const S = {
	ActivityHeader,
	HeaderIcon,
	LineDivider
};

export default S;
