import styled from 'styled-components';
import { default as SP } from '../Procedure/styled';

const S = {};

S.DateField = styled(SP.Field)`
	min-height: 38px;

	:focus {
		border-color: ${(props) => (props.valid === false ? '#dc3545' : '#60606a')};
	}
`;
export default S;
