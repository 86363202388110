/**
 * Be able to directly start a triage (ie corona case)
 */

import React, { useCallback, useEffect, useState } from 'react';

import { default as SA } from '../Activity/styled';

import ActivityWelcome from '../ActivityWelcome';
import ActivityTriage from '../ActivityTriage';
import TermsOfUse from '../TermsOfUse';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallGetStartQuestion, CallStartSession } from '../../helpers/services';
import { validateSession } from '../../helpers/support/validateSession';
import useWidgetIsNarrow from '../../helpers/hooks/useWidgetIsNarrow';

const ActivityDirectTriage = (props) => {
	const [{ profile, settings, conversation, session }, dispatch] = useStateContext();

	const [question, setQuestion] = useState(conversation[0].question || null);
	const propsHandleActivityResponse = props.handleActivityResponse;

	const handleActivityResponse = useCallback(
		(answer) => {
			// Component using handleActivityResponse is only shown if question is not empty
			propsHandleActivityResponse({ questionId: question.id, answer: answer });
		},
		[propsHandleActivityResponse, question]
	);

	const widgetIsNarrow = useWidgetIsNarrow();

	useEffect(() => {
		if (settings.prefill && settings.prefill.active) {
			const fetchStartQuestion = async () => {
				if (!question || settings.selectedLanguage.code !== props.questionLanguageCode) {
					if (!validateSession(session)) {
						const startSessionResult = await CallStartSession(settings.ApiKey, {
							widgetType: settings.widgetType,
							restart: true,
							externalId: profile.externalId,
							phoneNumber: profile.phoneNumber,
							languageCode: settings.selectedLanguage.code
						});

						if (startSessionResult) {
							dispatch({
								type: 'updateSession',
								session: {
									id: startSessionResult.sessionId,
									token: startSessionResult.sessionToken
								}
							});
						}
					} else {
						const startQuestion = await CallGetStartQuestion(settings.ApiKey, {
							triageId: Number(settings.prefill.complaint),
							age: 0,
							requestType: 'StartTriage',
							languageCode: settings.selectedLanguage.code,
							sessionId: session.id,
							sessionToken: session.token
						});

						if (startQuestion && startQuestion.startQuestion) {
							setQuestion(startQuestion.startQuestion);

							const directTriageQuestionIndex = conversation.findIndex((c) => c.type === 'directTriage');
							if (directTriageQuestionIndex !== -1) {
								const updatedStep = {
									...conversation[directTriageQuestionIndex],
									id: startQuestion.startQuestion.id,
									question: startQuestion.startQuestion,
									questionLanguageCode: settings.selectedLanguage.code
								};

								dispatch({
									type: 'updateConversationStep',
									stepIndex: directTriageQuestionIndex,
									step: updatedStep
								});
							}
						}
					}
				}
			};
			fetchStartQuestion();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [settings.ApiKey, settings.selectedLanguage, session]);

	return (
		<SA.ActivityGroup>
			{props.modalOpen && <TermsOfUse modalOpen={props.modalOpen} />}
			{(settings.widget_showWelcomeText || props.modalOpen) && <ActivityWelcome modalOpen={props.modalOpen} />}
			{question && (
				<ActivityTriage
					{...props}
					question={question}
					disabled={props.disabled}
					handleActivityResponse={handleActivityResponse}
					widgetIsNarrow={widgetIsNarrow}
				/>
			)}
			{!props.modalOpen && settings.branding && !settings.branding.hide_footer && <TermsOfUse modalOpen={props.modalOpen} />}
		</SA.ActivityGroup>
	);
};

export default ActivityDirectTriage;
