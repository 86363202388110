import styled from 'styled-components';

const S = {
	PhoneInputWrapper: styled.div`
		& :has(input):first-child {
			margin-left: 0;
		}
		input {
			height: auto;
		}
	`,
	PhoneCountryOptionWrapper: styled.div`
		position: relative;
		overflow: hidden;
		text-overflow: ellipsis;
	`,
	PhoneCountryOptionImageWrapper: styled.div`
		width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
		height: auto;
		display: inline-block;
	`,
	PhoneCountryOptionImage: styled.img`
		width: 100%;
		height: var(--PhoneInputCountryFlag-height);
		display: inline-block;
	`,
	PhoneCountryOptionLabel: styled.span`
		margin-left: 5px;
	`
};

export default S;
