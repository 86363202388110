import styled from 'styled-components';

import { default as SSQ } from '../SurveyQuestion/styled';
import { default as ST } from '../ActivityTriage/styled';

const S = {};

S.ConsultationPreparationAnswerButtons = styled(SSQ.SurveyAnswerButtons).attrs({ 'data-minddclass': 'consultationpreparationanswerbuttons' })``;

S.Button = styled(SSQ.SurveyAnswerButton)``;

S.EmpathyBubbleTitle = styled(ST.EmpathyBubbleTitle)``;

S.ActivityBubble = styled(ST.ActivityBubble)``;

export default S;
