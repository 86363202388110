import React from 'react';

import { default as SA } from '../Activity/styled';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import DownloadButton from '../DownloadButton/DownloadButton';
import EndButtons from '../EndButtons/EndButtons';

const ActivityEndActions = (props) => {
	const [{ conversation }] = useStateContext();
	const showFeedbackAction = typeof props.showFeedbackAction === 'undefined' ? true : props.showFeedbackAction;
	const hideDownloadButton = props.hideDownloadButton || conversation.some((c) => c.type === 'requestCallbackEnd');

	const handleActivityEndActions = async (answer) => {
		switch (answer.message) {
			case 'feedback':
				await props.handleActivityResponse({ ...props.answer, isFeedbackStarted: true });
				break;
			// no default
		}
	};

	return (
		<>
			{!hideDownloadButton && <DownloadButton />}

			<SA.LineDevider hasAdvice={true} />

			<EndButtons showFeedbackAction={showFeedbackAction} handleActivityResponse={handleActivityEndActions} />
		</>
	);
};

export default ActivityEndActions;
