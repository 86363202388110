import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { SessionInput } from './BaseInput';
import { CallbackRequestSchedule } from '../../models';

export type CallbackTimeslotsInput = SessionInput & {
	callbackLocation?: string;
};

async function GetCallbackTimeSlots(apiKey: string, input: CallbackTimeslotsInput): Promise<CallbackRequestSchedule | null> {
	try {
		const { data } = await minddApiAxios.post<CallbackRequestSchedule>('GetCallbackTimeslots?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return data;
	} catch (error) {
		logAxiosError(error, 'GetCallbackTimeSlots');
	}

	return null;
}

export { GetCallbackTimeSlots };
