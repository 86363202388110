import styled from 'styled-components';
//import { default as SA } from '../Activity/styled';
const S = {};

S.Form = styled.div.attrs({ 'data-minddclass': 'form' })`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	/* justify-content: space-between; */
`;

S.Row = styled.div.attrs({ 'data-minddclass': 'row' })`
	width: 100%;
	padding: 1rem 0rem 0 0;
`;

S.RowClean = styled(S.Row).attrs({ 'data-minddclass': 'rowclean' })`
	padding: 0rem 0rem 0 0;
`;

S.RowCheckbox = styled(S.Row).attrs({ 'data-minddclass': 'rowcheckbox' })`
	padding: 0rem 0rem 0 0;
	display: flex;
	> *:last-child {
		flex-grow: 2;
	}
`;

S.RowConsent = styled(S.RowCheckbox).attrs({ 'data-minddclass': 'rowconsent' })``;

S.Col = styled.div.attrs({ 'data-minddclass': 'col' })`
	&&& {
		width: 32%;
		padding-inline-end: 1%;
		@media ${(props) => props.theme.screens.small} {
			width: 100%;
		}
	}
`;

S.Col50 = styled(S.Col).attrs({ 'data-minddclass': 'col50' })`
	&&& {
		width: 48%;
		padding-inline-end: 2%;
		@media ${(props) => props.theme.screens.small} {
			width: 100%;
		}
	}
`;

S.Col64 = styled(S.Col).attrs({ 'data-minddclass': 'col64' })`
	&&& {
		width: 64%;
		padding-inline-end: 1%;
		@media ${(props) => props.theme.screens.small} {
			width: 100%;
		}
	}
`;

S.Label = styled.label.attrs({ 'data-minddclass': 'label' })`
	color: ${(props) => props.theme.colors.text};
	display: block;
	margin: 0;
`;
S.LabelInline = styled(S.Label).attrs({ 'data-minddclass': 'labelinline' })`
	display: inline-block;
	margin: 0;
	margin-inline-start: 1em;
`;

S.Field = styled.input.attrs({ 'data-minddclass': 'field' })`
	&& {
		display: block !important;
		border: 1px solid ${(props) => (props.valid === false ? '#dc3545' : '#60606a')};
		background: transparent;
		padding: 5px 10px;
		margin: 0;
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
		width: 100%;
		font-weight: normal;
		height: auto;
		:focus {
			outline: 0;
			border-color: ${(props) => (props.valid === false ? '#dc3545' : '#60606a')};
		}
		&[type='date'] {
			-webkit-appearance: textfield;
			-moz-appearance: textfield;
			background: #fff;
			line-height: 1.5;
		}
		&[type='checkbox'] {
			appearance: auto;
			display: inline-block !important;
			margin-top: 0.3rem;
			vertical-align: top;
			width: auto;

			:focus-visible {
				outline: 2px auto ${(props) => props.theme.colors.focus};
			}
		}
		&[type='number'] {
			appearance: textfield;
			&::-webkit-inner-spin-button,
			&::-webkit-outer-spin-button {
				webkit-appearance: none;
				margin: 0;
			}
			&:hover,
			&:focus {
				appearance: number-input;
			}
		}
		&[type='file'] {
			padding: 2px 10px;
		}
	}
`;

S.FieldRound = styled(S.Field)`
	border-radius: 5px !important;
	border: 1px solid ${(props) => props.theme.colors.accent} !important;
	padding: 0.25rem 0.5rem;
`;

S.FieldSelect = styled.select.attrs({ 'data-minddclass': 'fieldselect' })`
	&& {
		display: block !important;
		border: 1px solid ${(props) => (props.valid === false ? '#dc3545' : '#60606a')};
		background: transparent;
		padding: 8px 10px 7px 10px;
		margin: 0;
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
		width: 100%;
		font-weight: normal;
		height: auto;
		:focus {
			outline: 0;
			border-color: ${(props) => (props.valid === false ? '#dc3545' : '#60606a')};
		}
	}
`;

S.FieldTextArea = styled.textarea.attrs({ 'data-minddclass': 'fieldtextarea' })`
	&& {
		display: block;
		border: 1px solid ${(props) => (props.valid === false ? '#dc3545' : '#60606a')};
		background: transparent;
		border-radius: 0;
		font-family: inherit;
		font-size: inherit;
		font-weight: inherit;
		line-height: inherit;
		width: 100%;
		height: 70px;
		padding: 10px;
		&:focus {
			border: 1px solid ${(props) => (props.valid === false ? '#dc3545' : '#60606a')};
			outline: none;
		}
		@media ${(props) => props.theme.screens.small} {
			height: 100px;
		}
	}

	& + .textarea-character-counter {
		width: 100%;
		display: flex;
		flex-direction: row-reverse;
		font-size: 0.875rem;
	}
`;

S.Error = styled.div.attrs({ 'data-minddclass': 'error', role: 'alert' })`
	color: #dc3545 !important;
	padding: 1rem 0;

	ul {
		margin-block-start: 0.5em;
		margin-block-end: 0;
		margin-inline-start: 0;
		margin-inline-end: 0;
		padding-inline-start: 40px;

		li {
			list-style-type: disc;
		}
	}
`;

S.Succes = styled.div.attrs({ 'data-minddclass': 'succes' })`
	color: #28a745 !important;
	padding: 1rem 0;
`;

S.Alert = styled.div.attrs({ 'data-minddclass': 'alert' })`
	cursor: pointer;
	color: ${(props) => props.theme.colors.accent} !important;
	padding-bottom: 1rem;
`;

export default S;
