import React, { useState, useCallback, useEffect } from 'react';

import { default as SA } from '../Activity/styled';
import S from './styled';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallPostSearchTriageProgress, CallPostSessionLogProperties } from '../../helpers/services';

const ActivitySearchComplaintSelector = (props) => {
	const [{ profile, session, settings }, dispatch] = useStateContext();
	const [selectedTriageGroup, setSelectedTriageGroup] = useState({});

	const triageGroups = profile.selectedSearchTriageHit !== null ? profile.selectedSearchTriageHit.groups : [];

	const focusLastActivity = useCallback(
		(node) => {
			if (node && props.isLastActivity && props.modalOpen) {
				node.focus();
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const handleActivityResponse = async (triageGroup) => {
		setSelectedTriageGroup(triageGroup);

		dispatch({
			type: 'updateProfile',
			profile: {
				...profile,
				selectedSearchTriageGroup: triageGroup
			}
		});

		await CallPostSearchTriageProgress(settings.ApiKey, {
			sessionId: session.id,
			sessionToken: session.token,
			hitChoosen: profile.selectedSearchTriageHit.searchHitText,
			triageChoosen: triageGroup ? triageGroup.groupName : null,
			askForBodyPart: triageGroup ? triageGroup.askForBodyPart : null,
			askForGender: triageGroup ? triageGroup.askForGender : null,
			askForAge: triageGroup ? triageGroup.askForAge : null
		});

		await props.handleActivityResponse({ triageGroup: triageGroup });
	};

	useEffect(() => {
		CallPostSessionLogProperties(settings.ApiKey, {
			sessionId: session.id,
			sessionToken: session.token,
			sessionLogProperties: [
				{
					sessionLogPropertyName: 'SearchTriageTriageSelectorShown',
					sessionLogPropertyValue: true
				}
			]
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<SA.ActivityBubble>
			<SA.BubbleTitle ref={focusLastActivity} tabIndex="-1">
				{props.title}
			</SA.BubbleTitle>

			<S.Complaints numberOfComplaints={triageGroups.length}>
				<S.ComplaintsList>
					{triageGroups &&
						triageGroups.map((item, index) => {
							return (
								<S.Complaint key={index}>
									<S.ComplaintButton
										disabled={props.disabled}
										onClick={() => !props.disabled && handleActivityResponse(item)}
										selected={item.groupName === selectedTriageGroup.groupName ? true : false}
									>
										{item.groupName}
									</S.ComplaintButton>
								</S.Complaint>
							);
						})}
				</S.ComplaintsList>
			</S.Complaints>
		</SA.ActivityBubble>
	);
};

export default ActivitySearchComplaintSelector;
