import styled from 'styled-components';

import Button from '../Button';

const S = {};

S.SkipButton = styled(Button)`
	&&& {
		margin-inline-end: 1rem;
	}
`;

export default S;
