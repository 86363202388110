import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const S = {};
S.BaseButton = styled.button.attrs((props) => ({
	'data-minddclass': 'basebutton',
	'aria-pressed': props.toggle ? 'false' : null
}))`
	&&& {
		all: unset;
		cursor: pointer;
		background: transparent;
		color: ${(props) => props.theme.colors.accent};

		border: 2px solid ${(props) => props.theme.colors.accent};
		padding: 6px 22px !important;
		outline: 0;
		box-sizing: border-box;
		border-radius: 10px;
		cursor: pointer !important;
		font-weight: initial !important;
		text-transform: initial !important;
		transition: initial !important;
		font-size: initial !important;

		svg path {
			fill: ${(props) => props.theme.colors.accent};
		}

		/* style buttons depending on list of button */
		border-radius: 10px 10px 10px 10px;
		margin-right: 10px;

		&[disabled] {
			cursor: default !important;
			border-color: ${(props) => props.theme.colors.disabled};
			color: ${(props) => props.theme.colors.disabled};
			svg path {
				fill: ${(props) => props.theme.colors.disabled};
			}
		}

		&:focus,
		&:hover {
			outline: none;
			box-shadow: unset;
		}
		&:hover:not([disabled]),
		&:active,
		&:focus {
			color: #fff !important;
			svg path {
				fill: #fff;
			}
			background-color: ${(props) => props.theme.colors.accent};
		}

		@media ${(props) => props.theme.screens.small} {
			display: ${(props) => (props.show === 'desktopOnly' ? 'none' : 'block')};
		}

		@media ${(props) => props.theme.screens.medium} {
			${(props) =>
				props.widgetIsNarrow &&
				`	
				padding: 6px 4px !important;
				text-align: center !important;
			}
		  `}
		}
	}
`;

S.ActiveButton = styled(S.BaseButton).attrs((props) => ({
	'data-minddclass': 'activebutton',
	'aria-pressed': props.toggle ? 'true' : null
}))`
	&&& {
		background: ${(props) => props.theme.colors.accent};
		border-color: ${(props) => props.theme.colors.disabled};
		color: #fff;
		&[disabled] {
			background: ${(props) => props.theme.colors.accent};
			border-color: ${(props) => props.theme.colors.accent};
			color: #fff;
		}
	}
`;

S.Icon = styled(FontAwesomeIcon)`
	${(props) => (props.position === 'before' ? 'margin-inline-end: 0.75rem;' : '')};
	${(props) => (props.position === 'after' ? 'margin-inline-start: 0.75rem;' : '')};
`;

const spin = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

S.Loader = styled.div`
	margin-inline-start: 0.75rem;
	border: 0.2em solid rgba(0, 0, 0, 0.1);
	border-top: 0.2em solid ${(props) => (props.primary ? '#fff' : props.theme.colors.accent)};
	border-radius: 50%;
	width: 1.25rem;
	height: 1.25rem;
	animation: ${spin} 1s linear infinite;
`;

S.BaseIconButton = styled.button.attrs((props) => ({
	'data-minddclass': 'base-icon-button',
	'aria-pressed': props.toggle ? 'false' : null
}))`
	&&& {
		all: unset;
		cursor: pointer;
		background: ${(props) => (props.primary ? props.theme.colors.accent : 'transparent')};
		color: ${(props) => (props.primary ? '#fff' : props.theme.colors.accent)};

		border: 2px solid ${(props) => props.theme.colors.accent};
		padding: 6px 16px !important;
		outline: 0;
		box-sizing: border-box;
		border-radius: 10px;
		cursor: pointer !important;
		font-weight: initial !important;
		text-transform: initial !important;
		transition: initial !important;
		font-size: initial !important;

		display: flex;
		align-items: center;

		${(props) => (props.position === 'after' ? 'justify-content: space-between;' : '')};

		svg path {
			fill: ${(props) => (props.primary ? '#fff' : props.theme.colors.accent)};
		}

		/* style buttons depending on list of button */
		border-radius: 10px 10px 10px 10px;

		&[disabled] {
			cursor: default !important;
			border-color: ${(props) => props.theme.colors.disabled};
			color: ${(props) => props.theme.colors.disabled};
		}

		&:focus:not([disabled]),
		&:active:not([disabled]) {
			outline: 2px solid ${(props) => props.theme.colors.accent};
			outline-offset: 1px;
			box-shadow: unset;
		}

		&:hover:not([disabled]),
		&:active:not([disabled]) {
			color: ${(props) => (props.primary ? '#fff' : props.theme.colors.accentDarker)} !important;
			svg path {
				fill: ${(props) => (props.primary ? '#fff' : props.theme.colors.accentDarker)};
			}
			background-color: ${(props) => (props.primary ? props.theme.colors.accentDarker : '#fff')};
			border-color: ${(props) => props.theme.colors.accentDarker};
		}
	}
`;

S.ActiveIconButton = styled(S.BaseIconButton).attrs((props) => ({
	'data-minddclass': 'active-icon-button',
	'aria-pressed': props.toggle ? 'true' : null
}))`
	&&& {
		background: ${(props) => (props.primary ? props.theme.colors.accent : '#fff')};
		color: ${(props) => (props.primary ? '#fff' : props.theme.colors.accent)};
		&[disabled] {
			cursor: default !important;
			background: ${(props) => props.theme.colors.accent};
			border-color: ${(props) => props.theme.colors.accent};
			color: #fff;
			svg path {
				fill: ${(props) => (props.primary ? '#fff' : props.theme.colors.accent)};
			}
		}

		svg path {
			fill: ${(props) => (props.primary ? '#fff' : props.theme.colors.accent)};
		}
	}
`;

export default S;
