import { LabelKey } from 'react-phone-number-input';
import ar from 'react-phone-number-input/locale/ar.json';
import de from 'react-phone-number-input/locale/de.json';
import en from 'react-phone-number-input/locale/en.json';
import fr from 'react-phone-number-input/locale/fr.json';
import nl from 'react-phone-number-input/locale/nl.json';
import pl from 'react-phone-number-input/locale/pl.json';
import ru from 'react-phone-number-input/locale/ru.json';
import tr from 'react-phone-number-input/locale/tr.json';
import ua from 'react-phone-number-input/locale/ua.json';

const PhoneInputLabels: {
	en: { [key in LabelKey]: string };
	nl: { [key in LabelKey]: string };
	[key: string]: { [key in LabelKey]: string };
} = {
	ar,
	be: nl,
	de,
	en,
	fr,
	nl,
	pl,
	ru,
	tr,
	uk: ua
};

export default PhoneInputLabels;
