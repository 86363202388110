import React, { ReactNode } from 'react';
import { compiler, MarkdownToJSX } from 'markdown-to-jsx';

import { default as SA } from '../../components/Activity/styled';

/**
 * 
 * @param {*} text 
 * @param {*} overrides add or replace ovverides  example:{
						h1: {
							component: ({ children, ...props }) => <h1 {...props}>{children}</h1>,
							props: {
								'data-texttype': 'markdown'
							}
						}
					}
 */
export const renderMarkdown = (text: string, overrides: MarkdownToJSX.Overrides = {}) => {
	if (!text) return false;
	text = text.length > 0 ? text.replace(/\\n/g, '\n') : text;

	return compiler(text, {
		overrides: {
			...markdownCompilerOverrides,
			...overrides
		}
	});
};

type OverrideComponentProps = {
	children?: ReactNode;
};

export const markdownCompilerOverrides = {
	p: {
		component: ({ children, ...props }: OverrideComponentProps) => <p {...props}>{children}</p>,
		props: {
			'data-texttype': 'markdown'
		}
	},
	h1: {
		component: ({ children, ...props }: OverrideComponentProps) => <SA.BubbleSubTitle {...props}>{children}</SA.BubbleSubTitle>,
		props: {
			'data-texttype': 'markdown'
		}
	},
	h2: {
		component: ({ children, ...props }: OverrideComponentProps) => <SA.BubbleSubTitle {...props}>{children}</SA.BubbleSubTitle>,
		props: {
			'data-texttype': 'markdown'
		}
	},
	h3: {
		component: ({ children, ...props }: OverrideComponentProps) => <SA.BubbleSubTitle {...props}>{children}</SA.BubbleSubTitle>,
		props: {
			'data-texttype': 'markdown'
		}
	},
	h4: {
		component: ({ children, ...props }: OverrideComponentProps) => <SA.BubbleSubTitle {...props}>{children}</SA.BubbleSubTitle>,
		props: {
			'data-texttype': 'markdown'
		}
	},
	h5: {
		component: ({ children, ...props }: OverrideComponentProps) => <SA.BubbleSubTitle {...props}>{children}</SA.BubbleSubTitle>,
		props: {
			'data-texttype': 'markdown'
		}
	},
	h6: {
		component: ({ children, ...props }: OverrideComponentProps) => <SA.BubbleSubTitle {...props}>{children}</SA.BubbleSubTitle>,
		props: {
			'data-texttype': 'markdown'
		}
	},
	ul: {
		component: ({ children, ...props }: OverrideComponentProps) => <SA.MarkDownUl {...props}>{children}</SA.MarkDownUl>,
		props: {
			'data-texttype': 'markdown'
		}
	},
	li: {
		component: ({ children, ...props }: OverrideComponentProps) => <SA.MarkDownLi {...props}>{children}</SA.MarkDownLi>,
		props: {
			'data-texttype': 'markdown'
		}
	},
	a: {
		component: ({ children, ...props }: OverrideComponentProps) => (
			<a target="_blank" rel="noopener noreferrer" data-markdownclass="anchor" {...props}>
				{children}
			</a>
		)
	},
	img: {
		component: ({ children, ...props }: OverrideComponentProps) => <SA.MarkdownImage {...props}>{children}</SA.MarkdownImage>
	}
};
