import styled from 'styled-components';
import { FocusOn } from 'react-focus-on';

const S = {};
S.BaseModal = styled.div.attrs({ 'data-minddclass': 'basemodal' })`
	&&& {
		--document-height: 100vh;
		position: fixed;
		width: 100vw;
		height: var(--document-height, 100vh);
		z-index: 99999999;
		top: 0;
		inset-inline-start: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

S.Modal = styled(S.BaseModal).attrs((props) => ({ 'data-minddclass': 'modal', dir: props.theme.isRtl ? 'rtl' : 'ltr' }))`
	&&& {
		transition: opacity 0.5s;
		opacity: ${({ state }) => (state === 'entered' || state === 'exited' ? 1 : 0)};
		background: ${(props) => (props.mode === 'dialog' ? 'rgba(0, 0, 0, 0.5)' : props.theme.colors.appbackground)};
		box-sizing: border-box;

		* {
			box-sizing: border-box;
		}
	}
`;

S.FocusOn = styled(FocusOn)`
	position: relative;
	display: flex;
	justify-content: center;
	&&& {
		margin: ${(props) => (props.mode === 'dialog' ? '20px' : '0')};
		width: 100%;
		height: 100%;
		max-width: ${(props) => (props.mode === 'dialog' ? props.theme.sizes.maxWidth + 'px' : '100%')};
		max-height: ${(props) => (props.mode === 'dialog' ? props.theme.sizes.maxHeight + 'px' : '100%')};
		@media ${(props) => props.theme.screens.small} {
			margin: 0;
		}
	}
`;

S.NonModal = styled.div.attrs({ 'data-minddclass': 'nonmodal' })`
	&&& {
		transition: opacity 1.5s;
		opacity: ${({ state }) => (state === 'entered' || state === 'exited' ? 1 : 0)};
		height: 100%;
		display: inline-block;
		width: 100%;
	}
`;

S.ModalInner = styled.div.attrs({ 'data-minddclass': 'modalinner' })`
	&&& {
		background-color: ${(props) => props.theme.colors.appbackground};
		box-shadow: ${(props) => (props.mode === 'dialog' ? '0 0 40px 0px rgba(0,0,0,0.1)' : 'none')};
		margin: 0;
		width: 100%;
		height: 100%;

		// padding-top with header was 98px, 98 - 38 (height removed progess bar) = 60px
		padding-top: ${(props) => (props.hasHeader ? '60px' : '38px')};

		&:not(:has(.mind-logo-loader)) {
			background-color: ${(props) => (props.hasAdvice ? props.theme.colors.advicebackground : props.theme.colors.appbackground)};
		}

		@media ${(props) => props.theme.screens.medium} {
			border-radius: 10px;
			padding-bottom: 10px; // otherwise the bottom right border-radius isn't visible due to the vertical scroll bar. Also provides a clearer seperation modal / non-modal
		}
	}
`;

S.ModelCloseButtonWrapper = styled.div.attrs({ 'data-minddclass': 'modalclosebuttonwrapper' })`
	max-width: ${(props) => props.theme.sizes.maxWidth}px;
	position: absolute;
	height: 30px;
	width: 100%;
	top: 0px;
	margin: 0 auto;
`;

S.ModalCloseButton = styled.button.attrs({ 'data-minddclass': 'modalclosebutton' })`
	&&& {
		position: absolute;
		top: 0px;
		inset-inline-end: 0px;
		z-index: 999999999;
		box-shadow: unset;
		background: transparent;
		padding: 5px 0px;
		border: 0;
		margin: 0;
		cursor: pointer;
		:focus,
		:hover {
			outline: none;
			box-shadow: unset;
			background: transparent !important;
		}

		:focus-visible {
			outline: 2px solid ${(props) => props.theme.colors.focus};
		}

		svg path {
			fill: ${(props) => props.theme.colors.text};
		}

		@media ${(props) => props.theme.screens.small} {
			padding: 5px 0px;
			filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.4));
			svg path {
				fill: ${(props) => props.theme.colors.text};
			}
		}

		svg {
			width: 24px;
			height: 24px;
		}
		@media ${(props) => props.theme.screens.medium} {
			top: ${(props) => (props.hasHeader ? '2px' : '-9px')}; // vertically align cross
			svg {
				width: 48px;
				height: 48px;
			}
		}
	}
`;

export default S;
