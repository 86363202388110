import React from 'react';

import { default as SA } from '../Activity/styled';
import S from './styled';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabelWithOverride } from '../../helpers/constants/getLabelWithOverride';

const ActivityWelcome = (props) => {
	const [{ settings }] = useStateContext();
	const TypeOfStyledActivity = props.modalOpen ? S.ClearActivityBubbleWhenOpen : S.ClearActivityBubble;

	const welcomeText = getLabelWithOverride(settings.welcome_text, 'WidgetWelcomeText', settings.applicationTexts, settings.selectedLanguage.code);

	return (
		<TypeOfStyledActivity foregroundColor={settings.widget_foreground}>
			<SA.BubbleTitle>{welcomeText}</SA.BubbleTitle>
		</TypeOfStyledActivity>
	);
};

export default ActivityWelcome;
