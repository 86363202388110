import React, { useEffect } from 'react';

import { default as SA } from '../Activity/styled';

import ActivityWelcome from '../ActivityWelcome';
import Logo from '../Logo';
import TermsOfUse from '../TermsOfUse';
import { ToggleButton } from '../Button';

import ActivityBubbleTitle from '../ActivityBubbleTitle';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallPostTriageProgress } from '../../helpers/services';
import { getLabel } from '../../helpers/constants/getLabels';
import { getOverrideLabel } from '../../helpers/constants/getOverrideLabel';
import useWidgetIsNarrow from '../../helpers/hooks/useWidgetIsNarrow';

const ActivityGender = (props) => {
	const [{ settings, session, profile }, dispatch] = useStateContext();

	const label = settings.labels && settings.labels.QuestionWhatGender ? settings.labels.QuestionWhatGender : null;
	const bubbleText = getOverrideLabel(label, props.title, settings.selectedLanguage.code);

	const maleQuestionTitle = getLabel('GenderSelectorMaleLabel', settings.applicationTexts, true) || 'Man';
	const maleAnswerValue = 'Male';
	const maleButtonClassName = props.modalOpen
		? `MWO_${Math.random().toString(36).substring(2, 8)}`
		: `MWO_${Math.random().toString(36).substring(2, 8)} mindd-widget-startbutton`;
	const femaleQuestionTitle = getLabel('GenderSelectorFemaleLabel', settings.applicationTexts, true) || 'Vrouw';
	const femaleAnswerValue = 'Female';
	const femaleButtonClassName = props.modalOpen
		? `MWO_${Math.random().toString(36).substring(2, 8)}`
		: `MWO_${Math.random().toString(36).substring(2, 8)} mindd-widget-startbutton`;

	const handleActivityResponse = async (answer) => {
		dispatch({
			type: 'updateProfile/Gender',
			gender: answer
		});

		await props.handleActivityResponse({ gender: answer });
	};

	const widgetIsNarrow = useWidgetIsNarrow();

	useEffect(() => {
		if (session && props.answer) {
			CallPostTriageProgress(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				updateGender: true,
				gender: props.answer.gender
			});
		}
	}, [props.answer, session, settings.ApiKey]);

	return (
		<SA.ActivityGroup>
			{props.modalOpen && props.isFirstActivity && <TermsOfUse modalOpen={props.modalOpen} />}
			{(settings.widget_showWelcomeText || props.modalOpen) && props.isFirstActivity && <ActivityWelcome modalOpen={props.modalOpen} />}

			<SA.ActivityBubble>
				<ActivityBubbleTitle
					title={bubbleText}
					info={props.info}
					isFirstActivity={props.isFirstActivity}
					isLastActivity={props.isLastActivity}
					modalOpen={props.modalOpen}
					disabled={props.disabled}
					widgetIsNarrow={widgetIsNarrow}
				/>
				<SA.ActivityActions widgetIsNarrow={widgetIsNarrow}>
					<ToggleButton
						disabled={props.disabled}
						buttonIndex={0}
						totalButtons={2}
						className={maleButtonClassName}
						state={props.answer?.gender === maleAnswerValue || profile.gender === maleAnswerValue}
						onClick={() => handleActivityResponse(maleAnswerValue)}
						widgetIsNarrow={widgetIsNarrow}
					>
						{maleQuestionTitle}
					</ToggleButton>
					<ToggleButton
						disabled={props.disabled}
						buttonIndex={1}
						totalButtons={2}
						className={femaleButtonClassName}
						state={props.answer?.gender === femaleAnswerValue || profile.gender === femaleAnswerValue}
						onClick={() => handleActivityResponse(femaleAnswerValue)}
						widgetIsNarrow={widgetIsNarrow}
					>
						{femaleQuestionTitle}
					</ToggleButton>
				</SA.ActivityActions>
				{!props.modalOpen && !settings.hide_footer && props.isFirstActivity && <Logo align="end" size="145" />}
			</SA.ActivityBubble>
			{!props.modalOpen && props.isFirstActivity && <TermsOfUse modalOpen={props.modalOpen} />}
		</SA.ActivityGroup>
	);
};

export default ActivityGender;
