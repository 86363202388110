import { useCallback } from 'react';

import { useStateContext } from '../useStateContext';
import { CallConsultationPreparationGetNext, ConsultationPreparationGetNextOutput } from '../../services';
import { ActivityStep, ConsultationPreparationQuestion } from '../../../models';

export type UseNextStepConsultationPreparationResultType = (
	questionId: number | undefined,
	answer: number | undefined,
	answerChoices: number[] | undefined,
	answerText: string | undefined,
	urgence: string,
	question: ConsultationPreparationQuestion | undefined,
	empathy: string | undefined,
	showImageField: boolean
) => Promise<ActivityStep>;

export const useNextStepConsultationPreparation: () => UseNextStepConsultationPreparationResultType = () => {
	const [{ session, settings }] = useStateContext();

	return useCallback(
		async (
			questionId: number | undefined,
			answer: number | undefined,
			answerChoices: number[] | undefined,
			answerText: string | undefined,
			urgence: string,
			question: ConsultationPreparationQuestion | undefined,
			empathy: string | undefined,
			showImageField: boolean
		): Promise<ActivityStep> => {
			let nextQuestion: ConsultationPreparationGetNextOutput | undefined = { message: 'ConsultationPreparation', question };

			if (questionId && answer) {
				nextQuestion = await CallConsultationPreparationGetNext(settings.ApiKey, {
					sessionId: session.id,
					sessionToken: session.token,
					questionId: questionId,
					answerId: answer,
					answerChoices: answerChoices,
					answerText: answerText,
					languageCode: settings.selectedLanguage.code
				});
			}

			if (nextQuestion && nextQuestion.question) {
				return {
					step: 0,
					id: `consultationPreparation-${nextQuestion.question.id}`,
					type: 'consultationPreparationQuestion',
					question: nextQuestion.question,
					urgence: urgence,
					empathy: empathy,
					showImageField: showImageField
				};
			} else {
				return {
					step: 0,
					id: 'consultationPreparation-requestCallback',
					type: 'requestCallback',
					urgence: urgence,
					showImageField: showImageField
				};
			}
		},
		[session, settings.ApiKey, settings.selectedLanguage]
	);
};
