import React, { forwardRef } from 'react';
import { Drawer } from 'vaul';
import S from './WidgetDrawer.styled';

interface WidgetDrawerProps {
	isOpen: boolean;
	onRelease?: (event: React.PointerEvent<HTMLDivElement>, open: boolean) => void;
	children: React.ReactNode;
	container: HTMLElement | null;
	showHandle?: boolean;
	snapPoints?: (number | string)[];
}

type DrawerRootProps = {
	snapPoints?: (number | string)[];
	open?: boolean;
	dismissible?: boolean;
	onRelease?: (event: React.PointerEvent<HTMLDivElement>, open: boolean) => void;
};

const WidgetDrawer = forwardRef<HTMLDivElement, WidgetDrawerProps>(({ isOpen, onRelease, children, container, showHandle = true, snapPoints }, ref) => {
	const drawerRootProps: DrawerRootProps = {
		dismissible: true,
		open: isOpen,
		onRelease: onRelease
	};

	// Provide the option to pass an empty snapPoints array, so dragging can be disabled (which provides a more robust UX).
	// Other methods, like attribute [data-vaul-no-drag], don't seem to work.
	// Maybe later providy the ability to only drag when DrawerHandle is touched.
	if (snapPoints) {
		drawerRootProps.snapPoints = snapPoints;
	}

	return (
		<S.DrawerWrapper ref={ref}>
			<Drawer.Root {...drawerRootProps}>
				<Drawer.Portal container={container}>
					<S.DrawerOverlay />
					<S.DrawerContent className="DrawerContent">
						<S.DrawerInnerContent>
							{showHandle && <S.DrawerHandle />}
							<S.DrawerChildrenWrapper>{children}</S.DrawerChildrenWrapper>
						</S.DrawerInnerContent>
					</S.DrawerContent>
				</Drawer.Portal>
			</Drawer.Root>
		</S.DrawerWrapper>
	);
});

WidgetDrawer.displayName = 'WidgetDrawer';
export default WidgetDrawer;
