import React from 'react';
import S from './styled';

function Button(props) {
	const Button = props.state ? S.ActiveButton : S.BaseButton;
	return <Button {...props}>{props.children}</Button>;
}

function ToggleButton(props) {
	const Button = props.state ? S.ActiveButton : S.BaseButton;
	return (
		<Button {...props} toggle={true}>
			{props.children}
		</Button>
	);
}

function IconButton(props) {
	const IconButton = props.state ? S.ActiveIconButton : S.BaseIconButton;
	const Icon = props.icon ? S.Icon : undefined;

	// This returns Button, but Button uses other styling
	if (!Icon) {
		//return Button(props);
	}

	const renderIcon = () => {
		if (Icon !== undefined) {
			return <Icon icon={props.icon} position={props.position} />;
		}
		return null;
	};

	return (
		<IconButton {...props}>
			{props.position === 'before' && renderIcon()}
			{props.children}
			{props.position === 'after' && renderIcon()}
		</IconButton>
	);
}

function SpinnerButton(props) {
	const IconButton = props.state ? S.ActiveIconButton : S.BaseIconButton;

	return (
		<IconButton {...props}>
			{props.children}
			{props.isLoading && <S.Loader primary={props.primary} />}
		</IconButton>
	);
}

export default Button;
export { ToggleButton, IconButton, SpinnerButton };
