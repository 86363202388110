import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { SessionInput } from './BaseInput';

export type VerifyChosenTimeSlotInput = SessionInput & {
	timeslot: string;
};

export type VerifyChosenTimeSlotOutput = {
	isAvailable: boolean;
};

async function CallVerifyChosenTimeSlot(apiKey: string, input: VerifyChosenTimeSlotInput): Promise<VerifyChosenTimeSlotOutput | undefined> {
	try {
		const { data } = await minddApiAxios.post<VerifyChosenTimeSlotOutput>('VerifyTeleQChosenTimeslot?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return data;
	} catch (error) {
		logAxiosError(error, 'CallVerifyChosenTimeslot');
	}

	return undefined;
}

export { CallVerifyChosenTimeSlot };
