import React from 'react';
import styled from 'styled-components';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';

const S = {};

S.TermsOfUse = styled.div.attrs({ 'data-minddclass': 'termsofuse' })`
	font-size: 0.75em;
	font-style: italic;
	margin-inline-end: 10px;
	opacity: 0.78;
	text-align: ${(props) => (props.modalOpen ? 'start' : 'end')};

	a {
		color: ${(props) => (props.modalOpen ? props.theme.colors.textdarker : props.settings.widget_foreground || props.theme.colors.textinverted)};

		:hover {
			text-decoration: none;
		}

		:focus-visible {
			outline: 2px solid ${(props) => props.theme.colors.focus};
		}
	}
`;

const TermsOfUse = (props) => {
	const [{ settings }] = useStateContext();

	const termsOfUseUrl =
		settings.branding && settings.branding.termsOfUseUrl
			? settings.branding.termsOfUseUrl
			: getLabel('WidgetTermsOfUseUrl', settings.applicationTexts, true);

	return (
		<S.TermsOfUse modalOpen={props.modalOpen} settings={settings}>
			<a href={termsOfUseUrl} target="_blank" rel="noopener noreferrer">
				{getLabel('WidgetTermsOfUseButtonLabel', settings.applicationTexts, true)}
			</a>
		</S.TermsOfUse>
	);
};

export default TermsOfUse;
