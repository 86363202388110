import styled from 'styled-components';

import { Slider } from 'react-compound-slider';

const S = {};

S.SliderWrapper = styled.div.attrs({ 'data-minddclass': 'sliderwrapper' })`
	> div {
		display: flex;
		align-items: center;
		position: relative;
	}
`;

S.Slider = styled(Slider)``;

S.SliderHandle = styled.div.attrs({ 'data-minddclass': 'sliderhandle' })``;

S.Rail = styled.div.attrs({ 'data-minddclass': 'rail' })`
	top: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	background-color: transparent;
	cursor: pointer;
	display: flex;
	align-items: center;
	transform: translateY(-50%) l;
`;

S.InnerRail = styled.div.attrs({ 'data-minddclass': 'innerrail' })`
	height: 4px;
	width: 100%;
	background-color: #8b9cb6;
	background: linear-gradient(to ${(props) => (props.theme.isRtl ? 'left' : 'right')}, #89eece, #f2ecbb, #e6c36b, #efb3b3, #e66b6b);
`;

S.HandleController = styled.div.attrs({ 'data-minddclass': 'handlecontroller' })`
	left: ${(props) => props.percent}%;
	position: absolute;
	margin-left: -15px;
	z-index: 2;
	width: 30px;
	height: 30px;
	text-align: center;
	cursor: pointer;
	border-radius: 50%;
	background-color: #fff;
	border: 2px solid ${(props) => props.theme.colors.accent};
	border-color: ${(props) => props.$color};
	color: #333;

	:focus-visible {
		outline: 2px solid ${(props) => props.theme.colors.accent};
		background-color: ${(props) => props.$color};
	}
`;

S.Ticks = styled.div.attrs({ 'data-minddclass': 'ticks' })`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
`;

S.TickLine = styled.div.attrs({ 'data-minddclass': 'tickline' })`
	width: 4px;
	height: ${(props) => props.value * 16 + 4}px;
	background-color: ${(props) => props.$color};
	border-radius: 2px;
`;

S.TickLabel = styled.div.attrs({ 'data-minddclass': 'ticklabel' })``;

export default S;
