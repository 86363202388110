import React, { useCallback } from 'react';

import { default as SA } from '../Activity/styled';

import ActivityEndActions from '../ActivityEndActions';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';

const SurveyEnd = (props) => {
	const [{ settings }] = useStateContext();

	const focusLastActivity = useCallback(
		(node) => {
			if (node && props.isLastActivity && props.modalOpen) {
				node.focus();
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const handleActivityEndActions = async (answer) => {
		switch (answer.message) {
			case 'feedback':
				await props.handleActivityResponse({ ...props.answer, isFeedbackStarted: true });
				break;
			// no default
		}
	};

	return (
		<SA.ActivityBubble ref={focusLastActivity} tabIndex="-1">
			<SA.BubbleTitle>{renderMarkdown(settings.survey.labels.success)}</SA.BubbleTitle>

			<ActivityEndActions
				showFeedbackAction={false}
				hideDownloadButton={settings.downloadConversationDisabled}
				handleActivityResponse={handleActivityEndActions}
			/>
		</SA.ActivityBubble>
	);
};

export default SurveyEnd;
