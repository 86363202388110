import React, { useState, useRef, useEffect } from 'react';
import { DateTime } from 'luxon';

import { default as SA } from '../Activity/styled';
import { default as SP } from '../Procedure/styled';
//import S from './styled';

import Button from '../Button';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallPostZipCode } from '../../helpers/services';
import { getLabel } from '../../helpers/constants/getLabels';
import { createDateForDob } from '../../helpers/support/createDateForDob';

const ProcedurePostcodeRequest = (props) => {
	const [{ profile, settings, session }] = useStateContext();
	const [isDisabled, setIsDisabled] = useState(props.disabled);
	const [isSending, setIsSending] = useState(false);
	const [sendStatus, setSendStatus] = useState(false);
	const [validationStatus, setvalidationStatus] = useState(null);
	const [fields, setFields] = useState({
		ZipCode: null,
		UserAgreedToUsage: null,
		Dob: null
	});

	let profileDobDateTime = null;
	const profileDobDate = createDateForDob(profile.dob);
	if (profileDobDate) {
		profileDobDateTime = DateTime.fromJSDate(profileDobDate, { zone: 'utc' });
	}

	useEffect(() => {
		if (settings.askForzipCodeIncludeBirthdate && profileDobDateTime)
			setFields({
				...fields,
				Dob: profileDobDateTime.toFormat('dd-MM-yyyy')
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChangeFormField = (field) => {
		if (field.target.type === 'checkbox') {
			setFields({
				...fields,
				[field.target.name]: field.target.checked ? true : null
			});
		} else if (field.target.type === 'date') {
			setFields({
				...fields,
				[field.target.name]:
					field.target.value === '' || field.target.value.trim() === '' ? null : DateTime.fromISO(field.target.value).toFormat('dd-MM-yyyy')
			});
		} else {
			setFields({
				...fields,
				[field.target.name]: field.target.value === '' || field.target.value.trim() === '' ? null : field.target.value
			});
		}
	};

	const handleSendData = async () => {
		setIsDisabled(true);
		setIsSending(true);
		let valid = true;

		valid = session === null ? false : valid;
		valid = fields.ZipCode === null ? false : valid;
		//valid = fields.Dob === null ? false : valid;
		valid = fields.UserAgreedToUsage === null ? false : valid;

		if (valid) {
			const result = await CallPostZipCode(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				zipCode: fields.ZipCode.trim(),
				birthdate: fields.Dob,
				userAgreedToUsage: fields.UserAgreedToUsage
			});

			if (result) {
				setSendStatus(true);
				setIsSending(false);
				setIsDisabled(false);

				props.setBackButtonStatus(true);
			} else {
				setvalidationStatus(false);
				setIsSending(false);
				setIsDisabled(false);
			}
		} else {
			setvalidationStatus(false);
			setIsSending(false);
			setIsDisabled(false);
		}
	};

	return (
		<React.Fragment>
			{!sendStatus && session && (
				<SP.Form>
					<SP.Col>
						<InputNumber
							onChange={handleChangeFormField}
							name="ZipCode"
							label={getLabel('postcodeFieldLabel', settings.applicationTexts)}
						></InputNumber>
					</SP.Col>
					{settings.askForzipCodeIncludeBirthdate && (
						<SP.Col>
							<InputText
								onChange={handleChangeFormField}
								name="Dob"
								label={getLabel('WidgetFormLabelDob', settings.applicationTexts, true)}
								type="date"
								defaultValue={profileDobDateTime?.toFormat('yyyy-MM-dd')}
							/>
						</SP.Col>
					)}

					<SP.Row>
						<InputCheckBox
							onChange={handleChangeFormField}
							name="UserAgreedToUsage"
							label={getLabel('postcodeRequestConsent', settings.applicationTexts)}
							type="checkbox"
						></InputCheckBox>
					</SP.Row>

					<SP.RowClean>
						{validationStatus === false && <SP.Error>{getLabel('postcodeRequestError', settings.applicationTexts)}</SP.Error>}
						<SA.ActivityActions>
							<Button onClick={handleSendData} disabled={isDisabled}>
								{isSending
									? getLabel('WidgetSendingButton', settings.applicationTexts, true)
									: getLabel('WidgetSendButton', settings.applicationTexts, true)}
							</Button>
						</SA.ActivityActions>
					</SP.RowClean>
				</SP.Form>
			)}
			{sendStatus && <SP.Succes>{getLabel(`postcodeRequestSuccess`, settings.applicationTexts)}</SP.Succes>}
		</React.Fragment>
	);
};

export default ProcedurePostcodeRequest;

const InputCheckBox = (props) => {
	return (
		<React.Fragment>
			<SP.Field id={props.name} {...props} />
			<SP.LabelInline htmlFor={props.name}>{props.label}</SP.LabelInline>
		</React.Fragment>
	);
};

const InputText = (props) => {
	return (
		<React.Fragment>
			<SP.Label htmlFor={props.name}>{props.label}</SP.Label>
			<SP.Field type="text" {...props} autoComplete="off" />
		</React.Fragment>
	);
};

const InputNumber = (props) => {
	const inputNumberField = useRef();
	const setInputFilter = (textbox, inputFilter) => {
		['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function (event) {
			textbox.current.addEventListener(event, function () {
				if (inputFilter(this.value)) {
					this.oldValue = this.value;
					this.oldSelectionStart = this.selectionStart;
					this.oldSelectionEnd = this.selectionEnd;
				} else if (Object.prototype.hasOwnProperty.call(this, 'oldValue')) {
					this.value = this.oldValue;
					this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
				} else {
					this.value = '';
				}
			});
		});
	};

	useEffect(() => {
		setInputFilter(inputNumberField, function (value) {
			return /^[0-9]*$/.test(value);
			//return /^[\d*(.?)\s\d]*$/.test(value); // Allow digits and '.' only, using a RegExp
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<SP.Label htmlFor={props.name}>{props.label}</SP.Label>
			<SP.Field ref={inputNumberField} type="text" pattern="[0-9]*" maxLength="4" {...props} autoComplete="off" />
		</React.Fragment>
	);
};
