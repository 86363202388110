import styled from 'styled-components';
import { default as SABA } from '../ActivityComplaint/styled';

const S = {};

S.ActivityBodyAreaWrapper = styled(SABA.ActivityComplaintWrapper).attrs({ 'data-minddclass': 'activitybodyareawrapper' })``;

S.Body = styled(SABA.Body)``;

S.SvgLoader = styled(SABA.SvgLoader)``;

S.Switch = styled(SABA.Switch)``;

export default S;
