import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { SessionInput } from './BaseInput';

export type SessionLogPropertiesInput = SessionInput & {
	sessionLogProperties: SessionLogProperty[];
};

export type SessionLogProperty = {
	sessionLogPropertyName:
		| 'AdviceClicked'
		| 'CalledToHAP'
		| 'NavigatedToHAP'
		| 'CallButtonShown'
		| 'SearchTriageTriageSelectorShown'
		| 'ContactFormShown'
		| 'StartCallbackProcedureClicked'
		| 'StartZorgMailProcedureClicked';
	sessionLogPropertyValue: boolean;
};

async function CallPostSessionLogProperties(apiKey: string, input: SessionLogPropertiesInput): Promise<boolean> {
	try {
		const { status } = await minddApiAxios.post('PostSessionLogProperties?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return status === 200;
	} catch (error) {
		logAxiosError(error, 'CallPostSessionLogProperties');
	}

	return false;
}

export { CallPostSessionLogProperties };
