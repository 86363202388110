import { Profile, Settings, RootContext, WidgetConfig } from '../../models';
import { GetChannelSettingsOutput } from '../services/CallGetChannelSettings';
import { capitalizeFirstLetter } from '../support/utils';

export const InitialState = (config: WidgetConfig): RootContext => {
	// Settings is a combination of WidgetConfig and GetChannelSettingsOutput.
	// Since in App component the values of both get combined to set the state settings.
	const initialChannelSettings = InitialChannelSettings();
	const initialProfile = InitialProfile(config.forceInitialStateEmpty);

	const startWithTriageSearch = config.startWithTriageSearch === 'forceTrue' ? true : false;

	const initialSettings: Settings = {
		...initialChannelSettings,
		getSessionSummaryUsingForm: false,
		showLanguageSelector: true,
		startQuestionType: 'gender',
		sessionTimeOutInSec: 0,
		clickOutsideModalClosesModal: false,
		applicationTexts: [],
		...config,
		type: undefined,
		widgetType: 'Widget',
		startWithTriageSearch: startWithTriageSearch,
		apiKeyIsValid: null,
		selectedLanguage: {
			code: 'NL',
			name: 'Nederlands',
			locale: 'nl-NL',
			flagSvg:
				'<svg xmlns="http://www.w3.org/2000/svg" width="150" height="100" viewBox="0 0 9 6"><rect fill="#21468B" width="9" height="6" /><rect fill="#FFF" width="9" height="4" /><rect fill="#AE1C28" width="9" height="2" /></svg>',
			isDefault: true,
			isRtl: false
		}
	};

	const initialState = {
		modal: {
			id: null,
			open: null,
			target: null
		},
		settings: initialSettings,
		languages: [],
		session: {
			id: '',
			token: ''
		},
		profile: initialProfile,
		refs: {},
		conversation: []
	};

	return initialState;
};

// Get reasonable default ChannelSettings, should all be overriden in the settings,
// later, using the action API call result
const InitialChannelSettings = (): GetChannelSettingsOutput => {
	return {
		title: '',
		contactOptions: {
			labels: {}
		},
		contactRequest: {
			hasCallbackRequestTypeWithTimeSchedule: false,
			includePdf: false,
			clientNameRequired: false,
			clientExtensiveNameRequired: false,
			clientDateOfBirthRequired: false,
			clientGenderRequired: false,
			clientPhonenumberRequired: false,
			active: false,
			clientAddressRequired: false,
			clientHouseNumberRequired: false,
			clientZipCodeRequired: false,
			clientBsnNumberRequired: false,
			clientBsnNumberOptional: false,
			clientEmailRequired: false,
			clientEmailOptional: false,
			clientEmailOrBsnNumberRequired: false,
			clientCommentOptional: false,
			clientCommentRequired: false,
			clientPhotoOptional: false,
			clientDoctorRequired: false,
			clientDoctorOptional: false,
			clientHapLocationRequired: false,
			clientPhonenumberRegion: 'Netherlands',
			labels: {},
			hapLocations: [],
			locationsForCallbackConfigs: []
		},
		emailAddress: '',
		useLanguageSelector: true,
		showFeedback: true,
		askForBirthday: true,
		maxAge: 100,
		callButtonActive: false,
		askForZipCode: false,
		askForzipCodeIncludeBirthdate: false,
		askForGPPost: false,
		askForGPPostShowSkipButton: true,
		redirectToDoctorPageActive: false,
		survey: {
			active: false,
			surveyId: 0,
			labels: {
				intro: '',
				formIntro: '',
				formConsent: '',
				formSuccess: '',
				formError: '',
				success: ''
			}
		},
		patientPortal: {
			active: false,
			mgnEventlistenerActive: false,
			uzoEventlistenerActive: false,
			labels: {
				mgnButton: '',
				mgnButtonIntro: ''
			}
		}
	};
};

export const InitialProfile = (forceInitialStateEmpty = false): Profile => {
	let externalId = null;
	let phoneNumber = null;
	let gender = null;
	let age = null;
	if (document.location.search) {
		const searchParams = new URLSearchParams(document.location.search);
		searchParams.forEach((spValue, spKey) => {
			if (spKey && spKey.toLowerCase() === 'externalid') {
				externalId = decodeURIComponent(spValue);
			} else if (spKey && spKey.toLowerCase() === 'tel') {
				phoneNumber = decodeURIComponent(spValue);
			} else if (!forceInitialStateEmpty && spKey && spKey.toLowerCase() === 'gender') {
				if (spValue === 'male' || spValue === 'female') {
					gender = capitalizeFirstLetter(spValue);
				}
			} else if (!forceInitialStateEmpty && spKey && spKey.toLowerCase() === 'age') {
				const ageNum = Number(spValue.trim());
				if (Number.isInteger(ageNum) && ageNum >= 0) {
					age = spValue.trim();
				}
			}
		});
	}

	return {
		selectedSearchTriageHit: null,
		selectedSearchTriageGroup: null,
		dob: { day: null, month: null, year: null },
		age: age,
		gender: gender,
		bodypart: null,
		complaint: null,
		consent: null,
		name: null,
		externalId: externalId,
		phoneNumber: phoneNumber
	};
};
