import React, { useEffect, useRef } from 'react';

import S from './styled';
import Conversation from '../Conversation';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';

const Widget = (props) => {
	const [{ refs, modal, settings, conversation }, dispatch] = useStateContext();
	const widgetRef = useRef();
	const conversationHasAdvice = conversation.some((a) => a.type === 'advice');
	useEffect(
		() => {
			dispatch({
				type: 'updateRefs',
				refs: {
					...refs,
					widget: widgetRef
				}
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch]
	);

	const renderLogo = () => {
		if (settings.logoUrl) {
			return <img src={settings.logoUrl} alt={settings.branding.name} />;
		} else if (settings.branding.name) {
			return settings.branding.name;
		} else {
			return null;
		}
	};
	const hasHeader = modal.open && settings.branding && settings.branding.layout;

	return (
		<React.Fragment>
			{modal.open && <S.WidgetHeader>{getLabel('WidgetHeaderText', settings.applicationTexts, true)}</S.WidgetHeader>}
			{modal.open && settings.branding && settings.branding.layout && (
				<S.Header>
					<S.HeaderInner>
						<S.HeaderInnerLogo>{renderLogo()}</S.HeaderInnerLogo>
						{settings.branding.phone_number && (
							<S.HeaderInnerPhone>
								<a href={`tel:${settings.branding.phone_number}`}>
									<S.PhoneIcon>
										<svg
											version="1.1"
											xmlns="http://www.w3.org/2000/svg"
											width={12}
											height={12}
											viewBox="0 0 25.9 25.9"
											enableBackground="new 0 0 25.9 25.9"
										>
											<path d="m7.9,18.9c3.2,3.2 8.1,7 11,7 2.7,0 4.8-2.4 6.7-4.3 0.2-0.2 0.3-0.5 0.3-0.7 0-0.3-0.1-0.5-0.3-0.7l-4-4c-0.4-0.4-1-0.4-1.4,0l-3.9,3.9c-0.2,0.2-0.4,0.2-0.6,0.1-1.4-1-3.2-2.4-4.9-4.1l-1-1c-1.7-1.7-3.1-3.5-4.1-4.9-0.1-0.2-0.1-0.5 0.1-0.6l3.9-3.9c0.4-0.4 0.4-1 0-1.4l-4-4c-0.2-0.2-0.5-0.3-0.7-0.3-0.3,0-0.5,0.1-0.7,0.3-1.9,1.9-4.3,4.1-4.3,6.7 0,2.9 3.8,7.8 7,11l.9,.9z" />
										</svg>
									</S.PhoneIcon>
									<S.PhoneLabel>{settings.branding.phone_label}</S.PhoneLabel>
								</a>
							</S.HeaderInnerPhone>
						)}
					</S.HeaderInner>
				</S.Header>
			)}
			<S.Widget modalOpen={modal.open} ref={widgetRef} hasHeader={hasHeader} widgetBackground={!modal.open ? settings.widget_background : 'transparent'}>
				<S.Body>
					{modal.open && !hasHeader && <S.CloseButtonBar hasAdvice={conversationHasAdvice} />}
					<Conversation />
				</S.Body>
			</S.Widget>
		</React.Fragment>
	);
};

export default Widget;
