import { DateTime } from 'luxon';

import { getLabel } from '../constants/getLabels';
import { ApplicationText, CalculatedAge, DateOfBirth } from '../../models';

export const calculateAgeFromDob = (dob: DateOfBirth, applicationTexts: ApplicationText[]): CalculatedAge | undefined => {
	if (!dob.year || !dob.month || !dob.day) {
		return undefined;
	}

	const year = parseInt(dob.year, 10);
	const month = parseInt(dob.month, 10);
	const day = parseInt(dob.day, 10);

	if (isNaN(year) || isNaN(month) || isNaN(day)) {
		return undefined;
	}

	// Luxon month start at '1' instead of '0' like Date / Moment
	const parsedDob = DateTime.local(year, month, day);
	const now = DateTime.now();
	const diffYears = now.diff(parsedDob, 'years');
	const diffMonth = now.diff(parsedDob, 'months');

	const ageInYears = diffYears.years;
	// Always atleast 1 month
	const ageInMonths = diffMonth.months < 1 ? 1 : Math.floor(diffMonth.months);

	let displayAge = '';
	if (ageInYears >= 1) {
		const ageInYearsUnit = getLabel('WidgetDisplayAgeYearsUnit', applicationTexts, true);
		displayAge = `${Math.floor(ageInYears)} ${ageInYearsUnit}`;
	} else if (ageInMonths > 1) {
		const ageInMonthsUnit = getLabel('WidgetDisplayAgeMonthsUnit', applicationTexts, true);
		displayAge = `${ageInMonths} ${ageInMonthsUnit}`;
	} else {
		const ageInMonthUnit = getLabel('WidgetDisplayAgeMonthUnit', applicationTexts, true);
		displayAge = `${ageInMonths} ${ageInMonthUnit}`;
	}

	return {
		age: ageInYears.toFixed(3),
		ageInYears: ageInYears,
		displayAge: displayAge
	};
};
