import React, { useCallback, useState } from 'react';
import { compiler } from 'markdown-to-jsx';

import { default as SA } from '../Activity/styled';
import S from './styled';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import { getLabel } from '../../helpers/constants/getLabels';
import InfoButton from '../InfoButton';

interface Props {
	title: string;
	info: string;
	isFirstActivity: boolean;
	isLastActivity: boolean;
	modalOpen: boolean;
	disabled: boolean;
	limitHeight?: boolean;
	titleAriaHidden?: boolean;
	widgetIsNarrow?: boolean;
}

const ActivityBubbleTitle = (props: Props) => {
	const [{ settings }] = useStateContext();
	const [showInfo, setShowInfo] = useState(false);

	const infoButtonClicked = () => {
		setShowInfo(!showInfo);
	};

	const focusLastActivity = useCallback(
		(node: HTMLDivElement) => {
			if (node && props.isLastActivity && props.modalOpen) {
				node.focus();
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const BubbleTitle = props.limitHeight ? S.BubbleTitleLower : S.BubbleTitle;

	return (
		<>
			{props.info && showInfo && (
				<>
					<S.InfoPanelOverlay>
						<S.infoPanelBackground onClick={infoButtonClicked}></S.infoPanelBackground>
					</S.InfoPanelOverlay>
					<S.InfoPanel>
						<S.InfoCloseButton onClick={infoButtonClicked} title={getLabel('WidgetModalCloseButtonTitle', settings.applicationTexts, true)}>
							<svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
								<path
									fill="#fff"
									d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
								/>
							</svg>
						</S.InfoCloseButton>
						{typeof props.info === 'string' ? renderMarkdown(props.info) : props.info}
					</S.InfoPanel>
				</>
			)}
			<S.BubbleTitleWrapper widgetIsNarrow={props.widgetIsNarrow}>
				<BubbleTitle ref={focusLastActivity} tabIndex={-1} aria-hidden={props.titleAriaHidden}>
					{typeof props.title === 'string'
						? compiler(props.title, {
								overrides: {
									p: {
										component: ({ children, ...props }) => <p {...props}>{children}</p>,
										props: {
											'data-texttype': 'markdown'
										}
									},
									img: {
										component: ({ children, ...props }) => <SA.MarkdownImage {...props}>{children}</SA.MarkdownImage>
									}
								}
							})
						: props.title}
					{props.info && <InfoButton onClick={infoButtonClicked} pressed={showInfo} disabled={props.disabled} />}
				</BubbleTitle>

				{props.isFirstActivity && settings.showLanguageSelector && settings.useLanguageSelector && <S.LanguageSelector disabled={props.disabled} />}
			</S.BubbleTitleWrapper>
		</>
	);
};

export default ActivityBubbleTitle;
