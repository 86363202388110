import styled from 'styled-components';
import { default as SA } from '../Activity/styled';
import AwesomeButton from '../AwesomeButton/AwesomeButton';

const DrawerHeader = styled.div`
	&&& {
		margin-top: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-flow: wrap-reverse;
		margin-bottom: 20px;
	}
`;

// fix for vertical alignment with the close button
const DrawerBubbleSubTitle = styled(SA.BubbleSubTitle)`
	&&& {
		margin-bottom: 0 !important;
	}
`;

const CloseDrawerButton = styled(AwesomeButton)`
	&&& {
		margin-left: auto;
		white-space: nowrap;
	}
`;

const SD = {
	DrawerHeader,
	DrawerBubbleSubTitle,
	CloseDrawerButton
};

export default SD;
