import styled from 'styled-components';

const S = {};

S.LanguageSelectorWrapper = styled.div.attrs({ 'data-minddclass': 'languageselectorwrapper' })`
	width: 70px;
	cursor: pointer;
	position: relative;
`;

S.FlagWrapper = styled.div.attrs({ 'data-minddclass': 'flagwrapper' })`
	overflow: hidden;
	border-radius: 16px;
	height: 34px;
	width: 34px;
	margin-right: 8px;
	margin-left: 8px;
	background-color: #fff;
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
	position: relative;
	svg {
		height: 100%;
		width: 100%;
	}
`;

S.LanguageSelectorButton = styled.div.attrs({ 'data-minddclass': 'languageselectorbutton' })`
	height: 34px;
	width: 80px;
	padding-left: 4px;
	margin-top: 4px;
	align-items: center;
	display: inline-flex;
`;

S.LanguageSelectorMenu = styled.div.attrs({ 'data-minddclass': 'languageselectormenu', role: 'menu' })`
	display: none;
	background: #fff;
	width: 285px;
	z-index: 1000;
	border-radius: 10px;
	margin-top: 20px;
	position: absolute;
	inset-inline-end: -18px;
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
	.mindd-widget-languageSelector-showDropDownMenu & {
		display: inline-flex;
	}
`;

S.LanguageSelectorMenuArrow = styled.div.attrs({ 'data-minddclass': 'LanguageSelectorMenuArrow' })`
	width: 50px;
	height: 25px;
	position: absolute;
	bottom: 100%;
	inset-inline-end: 12px;
	transform: rotate(180deg);
	overflow: hidden;
	&:after {
		content: '';
		position: absolute;
		width: 20px;
		height: 20px;
		background: white;
		transform: translateX(-50%) translateY(-50%) rotate(45deg);
		top: 0;
		left: 50%;
		box-shadow: -1px -1px 10px 0px rgba(0, 0, 0, 0.6);
	}
`;
S.LanguageSelectorOptionsWrapper = styled.div.attrs({ 'data-minddclass': 'languageSelectorOptionsWrapper' })`
	width: 100%;
	height: 100%;
	display: inline-block;
	overflow: hidden;
	border-radius: 10px;
`;

S.LanguageSelectorOption = styled.div.attrs({ 'data-minddclass': 'languageselectoroption' })`
	height: 46px;
	width: 95px;
	padding: 10px 10px;
	display: inline-flex;
	align-items: center;
	font-weight: bold;
	&:hover:not([disabled]),
	&:active {
		background-color: #212121;
		color: #fff;
	}
`;

S.LanguageOptionCode = styled.div.attrs({ 'data-minddclass': 'LanguageOptionCode' })`
	display: inline-flex;
`;

export default S;
