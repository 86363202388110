import styled from 'styled-components';

const S = {};

S.InfoButton = styled.button.attrs((props) => ({
	'data-minddclass': 'activeinfobuttonbutton',
	'aria-pressed': props.pressed
}))`
	&& {
		all: unset;
		display: flex;
		cursor: pointer;
		background: ${(props) => (props.pressed ? props.theme.colors.accent : '#fff')};
		width: 24px;
		height: 24px;
		align-items: center;
		justify-content: center;
		border: 1px solid ${(props) => props.theme.colors.accent};
		border-radius: 100%;
		box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
		margin-left: 15px;
		margin-right: 15px;
		@media ${(props) => props.theme.screens.small} {
			top: 2px;
			inset-inline-end: 10px;
			position: relative;
			align-self: flex-start;
		}

		&[disabled] {
			cursor: default !important;
			border-radius: 100%;
			border-color: ${(props) => (props.pressed ? props.theme.colors.accent : props.theme.colors.disabled)};

			svg path {
				fill: ${(props) => (props.pressed ? '#fff' : props.theme.colors.disabled)};
			}
		}

		:hover:not([disabled]) {
			background: ${(props) => props.theme.colors.accent};
			color: #fff;
			border-radius: 100%;

			svg path {
				fill: #fff;
			}
		}

		:focus-visible {
			outline: 2px auto #000;
		}

		svg {
			width: 34px;
			height: 34px;

			path {
				fill: ${(props) => (props.pressed ? '#fff' : props.theme.colors.accent)};
			}
		}
	}
`;

export default S;
