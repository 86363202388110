import React, { ReactNode } from 'react';

import styled from 'styled-components';

interface ContactRequestContainerProps {
	hasRequestCallback: boolean;
	isVisible: boolean;
	children: ReactNode;
}

const ContactButtonContainer = styled.div<{ hasRequestCallback: boolean; isVisible: boolean }>`
	@media ${(props) => props.theme.screens.small} {
		${(props) =>
			!props.hasRequestCallback &&
			`	
				background: #fff;
				position: fixed;
				z-index: 2;
				left: 0;
				right: 0;
				bottom: 0;
				box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, .3);
				transform: ${props.isVisible ? 'translateY(0%)' : 'translateY(95%)'};
				transition: transform 0.5s ease-in-out;	
				padding: 15px 25px 10px 25px;
		`}
	}
`;

const ContactRequestContainer = ({ children, hasRequestCallback, isVisible }: ContactRequestContainerProps) => {
	return (
		<ContactButtonContainer hasRequestCallback={hasRequestCallback} isVisible={isVisible}>
			{children}
		</ContactButtonContainer>
	);
};

export default ContactRequestContainer;
