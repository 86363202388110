// https://github.com/diegohaz/arc/wiki/Styling

import { Settings, ThemeColors, ThemeFonts, ThemeScreens, ThemeSizes, ThemeType, ThemeBreakpoints } from '../../models';

const darkenColor = (hexColor: string, darkenAmount = 0.1): string => {
	const hexToRgb = (hex: string) => {
		const bigint = parseInt(hex.slice(1), 16);
		let r = (bigint >> 16) & 255;
		let g = (bigint >> 8) & 255;
		let b = bigint & 255;

		// Decrease each component by the specified percentage, ensuring it doesn't go below 0
		r = Math.max(0, r - Math.floor(255 * darkenAmount));
		g = Math.max(0, g - Math.floor(255 * darkenAmount));
		b = Math.max(0, b - Math.floor(255 * darkenAmount));

		return `${r}, ${g}, ${b}`;
	};
	return `rgb(${hexToRgb(hexColor)})`;
};

const calculateRgba = (accentColor: string, alpha: number): string => {
	const hexToRgb = (hex: string) => {
		const bigint = parseInt(hex.slice(1), 16);
		const r = (bigint >> 16) & 255;
		const g = (bigint >> 8) & 255;
		const b = bigint & 255;
		return `${r}, ${g}, ${b}`;
	};
	return `rgba(${hexToRgb(accentColor)}, ${alpha})`;
};

export const Theme = (props: Settings): ThemeType => {
	let fontFamily = 'Montserrat';
	if (props.branding && props.branding.font_family) {
		switch (props.branding.font_family) {
			case 'Arial':
				fontFamily = 'Arial, "Helvetica Neue", Helvetica, "Liberation Sans", Arimo';
				break;
			case 'Verdana':
				fontFamily = 'Verdana, "Helvetica Neue", Helvetica, "Liberation Sans", Arimo';
				break;
			case 'Calibri':
				fontFamily = 'Calibri, Carlito';
				break;
			case 'Roboto':
				fontFamily = 'Roboto, Arial, "Helvetica Neue", Helvetica, "Liberation Sans", Arimo';
				break;
			default:
				fontFamily = 'Montserrat';
				break;
		}
	}

	fontFamily += ', sans-serif';

	const themePalette = {
		primary: ['#1976d2', '#2196f3', '#71bcf7', '#c2e2fb'],
		secondary: ['#c2185b', '#e91e63', '#f06292', '#f8bbd0'],
		danger: ['#d32f2f', '#f44336', '#f8877f', '#ffcdd2'],
		alert: ['#ffa000', '#ffc107', '#ffd761', '#ffecb3'],
		success: ['#388e3c', '#4caf50', '#7cc47f', '#c8e6c9'],
		white: ['#fff', '#fff', '#eee'],
		grayscale: ['#212121', '#414141', '#616161', '#9e9e9e', '#bdbdbd', '#e0e0e0', '#eeeeee', '#ffffff']
	};

	const accentColor = props.accentColor ?? '#000';
	const themeColors: ThemeColors = {
		appbackground: '#DDF4FF',
		advicebackground: '#DCEFE1',
		accent: accentColor,
		accentLight: calculateRgba(accentColor, 0.05),
		accentLighter: calculateRgba(accentColor, 0.4),
		accentDarker: darkenColor(accentColor), // note: is calculated differently than accentLighter
		accentbw: themePalette.grayscale[4],
		text: '#60606a',
		textdarker: '#4d4d4d',
		textinverted: '#fff',
		background: accentColor,
		disabled: themePalette.grayscale[2],
		focus: accentColor
	};

	const themeFonts: ThemeFonts = {
		primary: fontFamily, //Helvetica Neue, Helvetica, Roboto, sans-serif',
		pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
		quote: 'Georgia, serif'
	};

	const themeSizes: ThemeSizes = {
		maxWidth: 1140,
		maxHeight: 860
	};

	const mediumBreakpoint = 768; // inspired by https://getbootstrap.com/docs/5.2/layout/breakpoints/
	const narrowBreakpoint = 350; // added because of widget display in narrow container (desktop) at https://huisartsenpraktijkdebrink.uwartsonline.nl/

	const themeBreakpoints: ThemeBreakpoints = {
		narrow: narrowBreakpoint,
		medium: mediumBreakpoint
	};

	const themeScreens: ThemeScreens = {
		small: `(max-width: 767px)`,
		// extraSmall: '(max-width: 409px)',
		extraSmall: '(max-width: 480px)',
		medium: `(min-width: ${mediumBreakpoint}px)`
	};

	return {
		colors: themeColors,
		fonts: themeFonts,
		sizes: themeSizes,
		screens: themeScreens,
		breakpoints: themeBreakpoints,
		isRtl: props.selectedLanguage.isRtl
	};
};
