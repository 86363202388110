import styled from 'styled-components';
import { default as SA } from '../Activity/styled';
import { default as SP } from '../Procedure/styled';

import Button from '../Button';

const AgeUnitButton = styled(Button)`
	&&& {
		display: inline-block;
		margin-top: 0.5rem;
		text-transform: capitalize !important;
	}
`;

const Form = styled.form.attrs({ 'data-minddclass': 'form' })`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 1rem 0;
	@media ${(props) => props.theme.screens.extraSmall} {
		margin-top: 1rem;
	}
`;

const Row = styled.div.attrs({ 'data-minddclass': 'row' })`
	width: 100%;
	margin-top: 1em;
`;

const FieldSet = styled.fieldset`
	border: 0;
	:focus-visible {
		outline: none;
	}
`;

const Legend = styled(SA.VisuallyHidden).attrs({ as: 'legend' })``;

const Label = styled(SA.VisuallyHidden).attrs<{ htmlFor: string }>((props) => ({ as: 'label', htmlFor: props.htmlFor }))<{ htmlFor: string }>``;

const Input = styled.input.attrs({ 'data-minddclass': 'input' })`
	text-align: center;
	font-size: inherit;
	border-radius: 5px !important;
	border: 1px solid ${(props) => props.theme.colors.accent} !important;
	padding: 0.25rem 0.5rem;
	width: 5em;
	margin-inline-end: 0.75rem;
	background: transparent !important;
	appearance: 'textfield';
	text-align: start;
	box-shadow: none;

	background: transparent;
	font-weight: normal;
	height: auto;

	&:focus {
		outline: 0;
	}
	,
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		webkit-appearance: none;
	}
	@media ${(props) => props.theme.screens.small} {
		width: 4.5em;
		margin-inline-end: 0.25rem;
	}
`;

const InputLarge = styled(Input).attrs({ 'data-minddclass': 'inputlarge' })`
	width: 5.5em;
	@media ${(props) => props.theme.screens.small} {
		width: 5.5em;
	}
`;

const Select = styled.select.attrs({ 'data-minddclass': 'fieldselect' })`
	&& {
		display: block !important;
		border: 1px solid #60606a;
		background: transparent;
		padding: 8px 10px 7px 10px;
		margin: 0;
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
		width: 100%;
		font-weight: normal;
		height: auto;
		:focus {
			outline: 0;
			border-color: #60606a;
		}
	}
`;

const Error = styled(SP.Error)`
	width: 100%;
`;

const S = {
	AgeUnitButton,
	Form,
	Row,
	FieldSet,
	Legend,
	Label,
	Input,
	InputLarge,
	Select,
	Error
};

export default S;
