import styled from 'styled-components';

const DownloadButton = styled.div`
	margin-top: 1rem;
`;

const S = {
	DownloadButton
};

export default S;
