import { AxiosResponse } from 'axios';

import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { setDeviceId } from '../constants/setDeviceId';
import { SettingsWidgetType } from '../../models';
import { getUA, isMobile } from '../constants/setUserAgent';

type WidgetType = SettingsWidgetType | 'Speech';

export type StartSessionInput = {
	widgetType: WidgetType;
	restart: boolean;
	externalId?: string | null;
	phoneNumber?: string | null;
	languageCode: string;
};

type StartSessionInputFull = StartSessionInput & {
	widgetHostname: string;
	deviceId: string;
	userAgent?: string | null;
	isMobileBrowser: boolean;
};

export type StartSessionOutput = {
	sessionId: string;
	sessionToken: string;
};

async function CallStartSession(apiKey: string, settings: StartSessionInput): Promise<StartSessionOutput | undefined> {
	try {
		const { data } = await minddApiAxios.post<StartSessionOutput, AxiosResponse<StartSessionOutput>, StartSessionInputFull>(
			'StartSession?api-version=2.1',
			{
				...settings,
				widgetHostname: window.location.hostname,
				deviceId: setDeviceId(),
				userAgent: getUA()?.ua,
				isMobileBrowser: isMobile()
			},
			{
				headers: {
					'MINDD-OC-ApiKey': apiKey
				}
			}
		);

		return data;
	} catch (error) {
		logAxiosError(error, 'CallStartSession');
	}

	return undefined;
}

export { CallStartSession };
