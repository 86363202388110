import { useCallback } from 'react';

import { StateContextType, useStateContext } from '../useStateContext';
import { CallAbcdTriageGetNext, CallAbcdTriageStartQuestion } from '../../services';
import { getLabel } from '../../constants/getLabels';
import { createDateForDob } from '../../support/createDateForDob';
import { ActivityStep, Gender } from '../../../models';
import { useNextStepCallbackRequest } from './useNextStepCallbackRequest';

export type UseNextStepAbcdTriageResultType = (questionId?: number, answer?: string | number) => Promise<ActivityStep>;

export const useNextStepAbcdTriage: () => UseNextStepAbcdTriageResultType = () => {
	const [{ profile, session, settings }]: StateContextType = useStateContext();

	const handleNextStepCallbackRequest = useNextStepCallbackRequest();

	const getStartQuestion = useCallback(
		async (gender: Gender | undefined | null, age: string, birthdate: Date | undefined): Promise<ActivityStep> => {
			const startQuestion = await CallAbcdTriageStartQuestion(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				gender: gender,
				age: age,
				birthdate: birthdate,
				languageCode: settings.selectedLanguage.code
			});

			if (startQuestion) {
				return {
					step: 0,
					id: `abcdQuestion-${startQuestion.startQuestion.id}`,
					type: 'abcdTriage',
					question: startQuestion.startQuestion,
					empathy: startQuestion.empathy
				};
			}

			return {
				step: 0,
				id: 'abcdStartQuestion',
				type: 'noNextStepFound'
			};
		},
		[session, settings.ApiKey, settings.selectedLanguage.code]
	);

	const getNext = useCallback(
		async (questionId: number, answer: string | number): Promise<ActivityStep> => {
			const nextResult = await CallAbcdTriageGetNext(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				questionId: questionId,
				answer: answer,
				languageCode: settings.selectedLanguage.code
			});

			if (!nextResult) {
				return {
					step: 0,
					id: `abcdGetNext-${questionId}`,
					type: 'noNextStepFound'
				};
			}

			if (nextResult.question) {
				return {
					step: 0,
					id: `abcdQuestion-${nextResult.question.id}`,
					type: 'abcdTriage',
					question: nextResult.question,
					empathy: nextResult.empathy
				};
			} else if (nextResult.advice) {
				if (nextResult.advice.skipAdvicePage && nextResult.advice.startContactRequestProcedure) {
					return handleNextStepCallbackRequest(nextResult.advice);
				}

				return {
					step: 0,
					id: 'advice',
					type: 'advice',
					advice: nextResult.advice,
					callButtonPhoneNumbers: nextResult.callButtonPhoneNumbers
				};
			}

			return {
				step: 0,
				id: 'abcdTriageSearchTriage',
				type: 'searchTriage',
				title: getLabel('QuestionWhatSearchTriage', settings.applicationTexts),
				profile: 'selectedSearchTriageHit',
				empathy: nextResult.empathy
			};
		},
		[session, settings.ApiKey, settings.applicationTexts, settings.selectedLanguage.code, handleNextStepCallbackRequest]
	);

	return useCallback(
		(questionId?: number, answer?: string | number): Promise<ActivityStep> => {
			if (questionId && answer) {
				return getNext(questionId, answer);
			}

			const birthdate = createDateForDob(profile.dob);
			return getStartQuestion(profile.gender, profile.age ?? '0', birthdate);
		},
		[profile.dob, profile.age, profile.gender, getStartQuestion, getNext]
	);
};
