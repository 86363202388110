import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SuccessMessageIcon = styled(FontAwesomeIcon)`
	color: ${(props) => props.theme.colors.accent};
	margin-inline-end: 1.5rem;

	width: 1.5rem;
	height: 1.5rem;
`;

const SuccessMessageContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 1.5rem;
	margin: 1.5rem 0;
	background-color: ${(props) => props.theme.colors.accent}15;
`;

const SuccessLabel = styled.div``;

const Confirmation = styled.div`
	font-weight: bold;
`;

const S = {
	SuccessMessageIcon,
	SuccessMessageContainer,
	SuccessLabel,
	Confirmation
};

export default S;
