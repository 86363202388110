import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';

import { ThemeContext } from 'styled-components';

import SVG from 'react-inlinesvg';
import ReactTooltip from 'react-tooltip';

import S from './styled';

import ActivityBubbleTitle from '../ActivityBubbleTitle';
import Button from '../Button';
import Loader from '../Loader';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallGetBodyAreas, CallGetComplaints } from '../../helpers/services';
import { getLabel } from '../../helpers/constants/getLabels';
import { createDateForDob } from '../../helpers/support/createDateForDob';

const ActivityComplaint = (props) => {
	const [{ settings, profile, session }, dispatch] = useStateContext();
	const themeContext = useContext(ThemeContext);
	const svgRef = useRef();

	const [bodypart, setBodypart] = useState();
	const [bodypartName, setBodypartName] = useState('');
	const [bodySide, setBodySide] = useState(true);
	const [autoFocusCompaint, setAutoFocusComplaint] = useState(false);
	const [complaints, setComplaints] = useState([]);
	const [complaint, setComplaint] = useState();
	const [svg, setSvg] = useState({
		front: null,
		back: null
	});

	let questionWhatTriageWithKnownBodyPart = getLabel('QuestionWhatTriageWithKnownBodyPart', settings.applicationTexts, true);
	questionWhatTriageWithKnownBodyPart = questionWhatTriageWithKnownBodyPart.replace('%s', bodypartName.toLowerCase());

	const bubbleText = complaints.length === 0 ? props.title : questionWhatTriageWithKnownBodyPart;

	// We load the body areas when the control loads for the first time
	useEffect(() => {
		const fetchBodyareas = async () => {
			const bodyAreas = await CallGetBodyAreas(settings.ApiKey, {
				gender: profile.gender,
				age: profile.age,
				bodyAreaIds: [],
				languageCode: settings.selectedLanguage.code
			});

			setSvg({
				front: bodyAreas.bodyImageSVGs.filter((svg) => {
					return svg.perspective === 'Voor';
				})[0].svg,
				back: bodyAreas.bodyImageSVGs.filter((svg) => {
					return svg.perspective === 'Achter';
				})[0].svg
			});

			ReactTooltip.rebuild();
		};
		fetchBodyareas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (bodypart) {
			const fetchComplaintsFor = async (bodypart) => {
				const complaints = await CallGetComplaints(settings.ApiKey, {
					gender: profile.gender,
					age: profile.age,
					birthdate: createDateForDob(profile.dob),
					bodyAreaId: bodypart,
					languageCode: settings.selectedLanguage.code,
					sessionId: session.id,
					sessionToken: session.token
				});

				setComplaints(complaints);
			};
			fetchComplaintsFor(bodypart);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bodypart]);

	const firstComplaintFocusRef = useCallback(
		(node) => {
			if (node !== null) {
				if (autoFocusCompaint) {
					node.focus();
					setAutoFocusComplaint(false);
				}

				// Scroll to the complaints when we are on a small screen
				if (complaints && complaints.length > 0) {
					const screenSmallMediaQueryList = window.matchMedia(themeContext.screens.small);
					if (screenSmallMediaQueryList.matches) {
						node.parentNode.parentNode.parentNode.scrollIntoView({ block: 'nearest', inline: 'nearest' });
					}
				}
			}
		},
		[autoFocusCompaint, complaints, themeContext.screens.small]
	);

	const handleActivityResponse = (answer) => {
		if (answer) {
			setComplaint(answer.id);
			props.handleActivityResponse({ bodyAreaId: bodypart, triageId: answer.id });
		}
	};

	const processBodyAreaSelect = useCallback(
		(bodyAreaElement, svgElement) => {
			const bodyAreaIdString = bodyAreaElement.getAttribute('data-bodyareaid') || null;
			if (!bodyAreaIdString || bodyAreaElement.length === 0) {
				return;
			}

			const bodyAreaId = parseInt(bodyAreaIdString);
			if (bodyAreaId) {
				try {
					svgElement.querySelector('[data-selected]').removeAttribute('data-selected');
					const ariaPressedelements = svgElement.querySelectorAll('[aria-pressed="true"]');
					ariaPressedelements.forEach((el) => el.setAttribute('aria-pressed', 'false'));
				} catch (e) {
					// We ignore the error
				}

				bodyAreaElement.setAttribute('data-selected', 'true');
				bodyAreaElement.setAttribute('aria-pressed', 'true');

				setBodypart(bodyAreaId);
				dispatch({
					type: 'updateProfile',
					profile: {
						...profile,
						bodypart: bodyAreaId
					}
				});

				const bodyAreaName = bodyAreaElement.getAttribute('data-tip') || null;
				if (bodyAreaName) {
					setBodypartName(bodyAreaName);
				} else {
					setBodypartName('');
				}
			}
		},
		[dispatch, profile]
	);

	const handleBodyAreaSelect = (event) => {
		event.preventDefault();

		const bodyAreaElement = event.target.closest('[data-bodyareaid]');
		const svgElement = event.target.closest('svg');
		if (bodyAreaElement && svgElement) {
			setAutoFocusComplaint(false);
			processBodyAreaSelect(bodyAreaElement, svgElement);
		}
	};

	// When control enabled make sure the tabindex on the SVG is 0, else -1 to disable tabbing
	// When control enabled attach a document event listener to keydown so we can select the body area using keyboard
	// Also use event listener on keydown to hide the tooltip on esc
	useEffect(() => {
		if (svgRef.current) {
			const tabIndexElements = svgRef.current.querySelectorAll('svg [tabindex]');
			if (tabIndexElements && tabIndexElements.length > 0) {
				const tabIndex = props.disabled ? '-1' : '0';
				tabIndexElements.forEach((el) => el.setAttribute('tabindex', tabIndex));
			}
		}

		if (!props.disabled) {
			const documentKeyDown = (event) => {
				if (event.key === 'Escape' || event.key === 'Esc') {
					ReactTooltip.hide();

					event.preventDefault();
				} else if (event.key === 'Enter' || event.key === 'Spacebar' || event.key === ' ') {
					const bodyAreaElement = event.target.closest('[data-bodyareaid]');
					const svgElement = event.target.closest('svg');
					if (bodyAreaElement && svgElement) {
						setAutoFocusComplaint(true);
						processBodyAreaSelect(bodyAreaElement, svgElement);

						event.preventDefault();
					}
				}
			};

			document.addEventListener('keydown', documentKeyDown);

			return () => {
				document.removeEventListener('keydown', documentKeyDown);
			};
		}
	}, [props.disabled, processBodyAreaSelect]);

	const handleBodySideChange = () => {
		setBodySide(!bodySide);
	};

	const preProcesSvg = (code) => {
		// replace color codes with accent
		code = code.replace(/fill="#00b2ff"/g, `fill="${themeContext.colors.accent}"`);
		code = code.replace(/data-bodyareaid="(\d+)"/gm, '$& tabindex="0" role="button" aria-pressed="false"');
		code = code.replace(/data-tip="(.*?)"/gm, '$& aria-label="$1"');
		return code;
	};

	return (
		<S.ActivityBubble>
			<ActivityBubbleTitle
				title={bubbleText}
				info={props.info}
				isFirstActivity={props.isFirstActivity}
				isLastActivity={props.isLastActivity}
				modalOpen={props.modalOpen}
				disabled={props.disabled}
			/>
			<S.ActivityComplaintWrapper>
				<S.Body disabled={props.disabled} numberOfComplaints={complaints.length}>
					{svg.front && svg.back ? (
						<SVG
							innerRef={svgRef}
							onError={(error) => console.log(error.message)}
							onLoad={ReactTooltip.rebuild}
							preProcessor={preProcesSvg}
							src={bodySide === true ? svg.front : svg.back}
							onClick={handleBodyAreaSelect}
							onTouchEnd={handleBodyAreaSelect}
						/>
					) : (
						<S.SvgLoader>
							<Loader />
						</S.SvgLoader>
					)}

					<S.Switch>
						<Button
							disabled={props.disabled}
							onClick={handleBodySideChange}
							title={getLabel('WidgetBodySwitchButtonTitle', settings.applicationTexts, true)}
						>
							<svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
								<path
									fill="#666"
									d="M12,5C16.97,5 21,7.69 21,11C21,12.68 19.96,14.2 18.29,15.29C19.36,14.42 20,13.32 20,12.13C20,9.29 16.42,7 12,7V10L8,6L12,2V5M12,19C7.03,19 3,16.31 3,13C3,11.32 4.04,9.8 5.71,8.71C4.64,9.58 4,10.68 4,11.88C4,14.71 7.58,17 12,17V14L16,18L12,22V19Z"
								/>
							</svg>
						</Button>
					</S.Switch>
				</S.Body>

				<S.Complaints numberOfComplaints={complaints.length}>
					<S.ComplaintsTitle numberOfComplaints={complaints.length}>
						{getLabel('BodyAreasLabelsResults', settings.applicationTexts)}
					</S.ComplaintsTitle>
					{complaints.length === 0 && <S.ComplaintsEmpty>{getLabel('BodyAreasLabelsNoResults', settings.applicationTexts)}</S.ComplaintsEmpty>}
					<S.ComplaintsList>
						{complaints &&
							complaints.map((item, index) => {
								return (
									<S.Complaint key={item.id}>
										<S.ComplaintButton
											disabled={props.disabled}
											onClick={() => !props.disabled && handleActivityResponse(item)}
											selected={item.id === complaint ? true : false}
											ref={index === 0 ? firstComplaintFocusRef : null}
										>
											{item.title}
										</S.ComplaintButton>
									</S.Complaint>
								);
							})}
					</S.ComplaintsList>
				</S.Complaints>

				{!props.disabled && (
					<ReactTooltip globalEventOff="click" disableInternalStyle={true}>
						{}
					</ReactTooltip>
				)}
			</S.ActivityComplaintWrapper>
		</S.ActivityBubble>
	);
};

export default ActivityComplaint;
