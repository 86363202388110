import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { LanguageInput, SessionInput } from './BaseInput';
import { Advice, AdvicePhoneNumber, Question } from '../../models';

export type AbcdTriageGetNextInput = SessionInput &
	LanguageInput & {
		questionId: number;
		answer: string | number;
	};

export type AbcdTriageGetNextOutput = {
	question?: Question;
	advice?: Advice;
	callButtonPhoneNumbers: AdvicePhoneNumber[];
	empathy?: string;
};

async function CallAbcdTriageGetNext(apiKey: string, input: AbcdTriageGetNextInput): Promise<AbcdTriageGetNextOutput | undefined> {
	try {
		const { data } = await minddApiAxios.post<AbcdTriageGetNextOutput>('AbcdTriageGetNext?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return data;
	} catch (error) {
		logAxiosError(error, 'CallAbcdTriageGetNext');
	}

	return undefined;
}

export { CallAbcdTriageGetNext };
