import React, { useEffect, useRef } from 'react';
import { Transition } from 'react-transition-group';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { useToggleModal } from '../../helpers/hooks/useToggleModal';
import { getLabel } from '../../helpers/constants/getLabels';

import S from './styled';

function Modal(props) {
	const [{ modal, settings, conversation }] = useStateContext();
	const handleToggleModal = useToggleModal();
	const modalRef = useRef(null);
	const nonModalRef = useRef(null);
	const conversationHasAdvice = conversation.some((a) => a.type === 'advice');

	useEffect(() => {
		const windowResized = () => {
			if (modalRef.current) {
				modalRef.current.style.setProperty('--document-height', `${window.innerHeight}px`);
			}
		};

		if (modal.open) {
			window.addEventListener('resize', windowResized);
			windowResized();

			return () => {
				window.removeEventListener('resize', windowResized);
			};
		}
	}, [modal.open]);

	useEffect(() => {
		const handleEscKeyPress = (event) => {
			if (event.key === 'Escape') {
				handleToggleModal();
			}
		};

		if (modal.open) {
			window.addEventListener('keydown', handleEscKeyPress);

			return () => {
				window.removeEventListener('keydown', handleEscKeyPress);
			};
		}

		// Without handleToggleModal in dependencies it doesn't correctly capture the conversation and can't determine
		// if the converstation is finished when closing the modal
	}, [modal.open, handleToggleModal]);

	const onClickOutsideHandler = () => {
		if (settings.clickOutsideModalClosesModal) {
			handleToggleModal();
		}
	};

	if (modal.open) {
		return (
			<Transition nodeRef={modalRef} in={true} timeout={0} appear={true}>
				{(state) => (
					<S.Modal ref={modalRef} state={state} mode={settings.modalDisplayMode}>
						<S.FocusOn mode={settings.modalDisplayMode} onClickOutside={onClickOutsideHandler}>
							{modal.open && (
								<S.ModelCloseButtonWrapper>
									<S.ModalCloseButton
										onClick={handleToggleModal}
										title={getLabel('WidgetModalCloseButtonTitle', settings.applicationTexts, true)}
										className={'mindd-widget-clossButton mindd-widget-closeButton'}
										hasHeader={settings.branding && settings.branding.layout}
									>
										<svg viewBox="0 0 24 24">
											<path
												fill="#fff"
												d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
											/>
										</svg>
									</S.ModalCloseButton>
								</S.ModelCloseButtonWrapper>
							)}
							<S.ModalInner
								hasHeader={settings.branding && settings.branding.layout}
								mode={settings.modalDisplayMode}
								hasAdvice={conversationHasAdvice}
							>
								{props.children}
							</S.ModalInner>
						</S.FocusOn>
					</S.Modal>
				)}
			</Transition>
		);
	} else {
		return (
			<Transition nodeRef={nonModalRef} in={true} timeout={0} appear={true}>
				{(state) => (
					<S.NonModal ref={nonModalRef} state={state}>
						{props.children}
					</S.NonModal>
				)}
			</Transition>
		);
	}
}

export default Modal;
