import styled from 'styled-components';
import { default as SA } from '../Activity/styled';
import { default as SSQ } from '../SurveyQuestion/styled';
import Button from '../Button';
import LanguageSelector from '../LanguageSelector';

const S = {};

S.EmpathyBubbleTitle = styled(SA.BubbleTitle).attrs({ 'data-minddclass': 'empathy', as: 'div' })``;

S.LanguageSelector = styled(LanguageSelector)`
	position: absolute;
	inset-inline-end: 30px;

	@media ${(props) => props.theme.screens.small} {
		inset-inline-end: 15px;
	}
`;

S.ActivityBubble = styled(SA.ActivityBubble)`
	position: relative;
`;

S.TriageAnswerButtons = styled(SSQ.SurveyAnswerButtons).attrs({ 'data-minddclass': 'triageanswerbuttons' })`
	margin-top: 0.75rem;
`;

S.Button = styled(Button)`
	&&& {
		margin: 0.25rem 0.5rem 0.25rem 0;

		@media ${(props) => props.theme.screens.small} {
			margin: 0.25rem 0 0.25rem 0;
		}
	}
`;

export default S;
