import styled from 'styled-components';
import Button from '../Button';

const S = {};

S.Form = styled.form`
	display: grid;
	margin: 0rem;
	@media ${(props) => props.theme.screens.small} {
		width: 100%;

		flex-direction: column;
		padding-inline-start: 0px;
	}
`;

S.Button = styled(Button)`
	&&& {
		margin: 0.25rem 0.5rem 0.25rem 0;
		@media ${(props) => props.theme.screens.small} {
			margin: 0.25rem 0 0.25rem 0;
		}
	}
`;

// note: this is not necessary here anymore, made adjustments in SessionSummaryForm\index.js
// S.DownloadLink = styled.button.attrs({ 'data-minddclass': 'downloadlink', 'type': 'submit' })`
// 	color: ${(props) => props.theme.colors.accent} !important;
// 	background-color: transparent;
// 	padding: 0;
// 	border-radius: 0;
// 	font-weight: 400;

// 	&:hover {
// 		text-decoration: underline;
// 	}

// 	&:focus {
// 		outline: 0;
// 	}
// `;

S.Alert = styled.button.attrs({ 'data-minddclass': 'alert', type: 'submit' })`
	color: ${(props) => props.theme.colors.accent} !important;
	background-color: transparent;
	padding: 0 0 1rem 0;
	border-radius: 0;
	font-weight: 400;
	display: block;

	&:focus {
		outline: 0;
	}
`;

export default S;
