import { DateTime } from 'luxon';
import { capitalizeFirstLetter } from './utils';

// Possibly provide parameter to show year or not.
// If a year is shown, DateTime.DATE_FULL can be used for better internationalization support.
export const fullDate = (date: string, langCode: string, capFirstLetter = false): string => {
	const dt = DateTime.fromISO(date).setLocale(langCode).toLocal();
	const now = DateTime.local();
	const isToday = dt.hasSame(now, 'day');
	const isTomorrow = dt.hasSame(now.plus({ days: 1 }), 'day');

	let wordForTodayOrTomorrow: string | null = null;
	if (isToday || isTomorrow) {
		wordForTodayOrTomorrow = dt.toRelativeCalendar({ unit: 'days' });
	}

	if (wordForTodayOrTomorrow) {
		const str =
			wordForTodayOrTomorrow +
			' ' +
			dt.toLocaleString({
				month: 'long',
				day: 'numeric'
			});
		if (capFirstLetter) {
			return capitalizeFirstLetter(str);
		}
		return str;
	}

	const str = dt.toLocaleString({
		weekday: 'long',
		month: 'long',
		day: 'numeric'
	});
	if (capFirstLetter) {
		return capitalizeFirstLetter(str);
	}
	return str;
};

export const dayName = (date: string, langCode: string): string => {
	const dt = DateTime.fromISO(date).setLocale(langCode).toLocal();
	const now = DateTime.local();
	const isToday = dt.hasSame(now, 'day');
	const isTomorrow = dt.hasSame(now.plus({ days: 1 }), 'day');
	let wordForTodayOrTomorrow = null;
	if (isToday || isTomorrow) {
		wordForTodayOrTomorrow = dt.toRelativeCalendar({ unit: 'days' });
	}
	if (wordForTodayOrTomorrow) {
		return wordForTodayOrTomorrow;
	}
	return DateTime.fromISO(date).setLocale(langCode).toFormat('cccc');
};

export const monthName = (date: string, langCode: string): string => {
	return DateTime.fromISO(date).setLocale(langCode).toFormat('LLLL');
};

export const dayOfTheMonth = (date: string, langCode: string): string => {
	return DateTime.fromISO(date).setLocale(langCode).toFormat('d');
};

export const timeInterval = (start: string, end: string, langCode: string): string => {
	let startTime = DateTime.fromISO(start).setLocale(langCode).toLocaleString(DateTime.TIME_24_SIMPLE);
	const endTime = DateTime.fromISO(end).setLocale(langCode).toLocaleString(DateTime.TIME_24_SIMPLE);

	// if the current time is within the first available time slot, the api gives a result like "start": "2024-03-19T10:39:16.15Z",
	// so for consistency with the other time slots this is rounded down to to nearest half hour.
	const time = DateTime.fromISO(start).setLocale(langCode);
	let roundedStart;
	if (time.minute > 0 && time.minute < 30) {
		roundedStart = time.set({ minute: 0 });
		startTime = roundedStart.toLocaleString(DateTime.TIME_24_SIMPLE);
	} else if (time.minute > 30 && time.minute <= 59) {
		roundedStart = time.set({ minute: 30 });
		startTime = roundedStart.toLocaleString(DateTime.TIME_24_SIMPLE);
	}

	return `${startTime} - ${endTime}`;
};

export const timeRange = (isoTime: string, langCode: string): string => {
	const time = DateTime.fromISO(isoTime).setLocale(langCode);
	let roundedStart;
	let roundedEnd;
	// Segment the time based on its position within the hour
	if (time.minute < 30) {
		// If the time is less than half past the hour, round down to the start of the hour
		roundedStart = time.set({ minute: 0 });
		roundedEnd = time.set({ minute: 30 }); // End time is half past the same hour
	} else {
		// If the time is half past the hour or later, round up to the half hour
		roundedStart = time.set({ minute: 30 });
		roundedEnd = time.plus({ hours: 1 }).set({ minute: 0 }); // End time is the start of the next hour
	}
	// Format the start and end times into strings
	const startTime = roundedStart.toLocaleString(DateTime.TIME_24_SIMPLE);
	const endTime = roundedEnd.toLocaleString(DateTime.TIME_24_SIMPLE);

	return `${startTime} - ${endTime}`;
};
