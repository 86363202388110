import styled from 'styled-components';

const S = {};

S.Activity = styled.li.attrs({ 'data-minddclass': 'activity' })`
	&&& {
		position: relative;

		&:not(:first-child) {
			margin-top: 30px;
		}

		p,
		ul {
			&[data-texttype='markdown'] {
				margin-bottom: 5px;
			}
		}
		ul ul {
			&[data-texttype='markdown'] {
				margin-bottom: 0;
			}
		}
	}
`;

S.ActivityBack = styled.button.attrs({ 'data-minddclass': 'activityBack' })`
	&&& {
		all: unset;
		display: flex;
		position: absolute;
		top: -17px;
		inset-inline-start: 0;
		cursor: pointer;
		background: #fff;
		width: 34px;
		height: 34px;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
		z-index: 1;

		:focus,
		:hover {
			background: ${(props) => props.theme.colors.accent};
			color: #fff;
			outline: none;

			svg {
				transform: rotate(90deg);

				path {
					fill: #fff;
				}
			}
		}

		svg {
			transition: transform 0.15s ease-out;
			path {
				fill: ${(props) => props.theme.colors.accent};
			}
		}
	}
`;

S.ActivityBackLabel = styled.span.attrs({ 'data-minddclass': 'activityBackLabel' })`
	position: absolute;
	top: -17px;
	inset-inline-start: 40px;
	white-space: nowrap;
	transition: opacity 0.15s ease-out;
	opacity: ${(props) => (props.hide ? '0' : '1')};
	background: #fff;
	border-radius: 15px;
	padding: 0.25rem 0.5rem;
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
	z-index: 1;
`;

S.ActivityGroup = styled.div.attrs({ 'data-minddclass': 'activitygroup' })`
	&&& {
	}
`;

S.ActivityBubble = styled.div.attrs((props) => ({
	'data-minddclass': 'activitybubble',
	role: props.group ? 'group' : null
}))`
	&&& {
		background: #fff;
		border-radius: 10px;
		box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
		color: ${(props) => props.theme.colors.text};
		padding: 30px;
		text-align: start;
		height: 100%;
		box-sizing: border-box;
		position: relative;

		& + & {
			margin-top: 20px;
		}

		@media ${(props) => props.theme.screens.small} {
			max-height: initial;
			padding: 15px;
		}
	}
`;

S.BubbleTitle = styled.h2.attrs({ 'data-minddclass': 'bubbletitle' })`
	&&& {
		font-size: 20px;
		font-weight: 400;
		line-height: 1.5;
		margin: 0;

		span {
			width: unset;
			max-width: unset;
			text-align: unset;
			margin-bottom: 0;
		}

		[data-texttype='markdown'] {
			width: unset;
			max-width: unset;
			text-align: unset;
			margin-bottom: 0;

			strong {
				font-size: 1.15em;
				font-weight: 600;
			}
		}

		:focus-visible {
			outline: none;
		}
		&.text-centered {
			text-align: center;
		}
	}
`;

S.BubbleSubTitle = styled.h3.attrs({ 'data-minddclass': 'bubblesubtitle' })`
	&&& {
		margin-bottom: 5px;
		font-size: 1em;
		font-weight: 600;
		line-height: 1.5;
		[data-texttype='markdown'] {
			margin-bottom: 0;
			font-weight: 300;

			strong {
				font-size: 1.05em;
				font-weight: 600;
			}
		}
	}
`;

S.ActivityActions = styled.div`
	margin: 1rem 0;
	display: flex;

	@media ${(props) => props.theme.screens.medium} {
		${(props) =>
			props.widgetIsNarrow &&
			`
		flex-direction: column!important;
		gap: 8px !important;
  	`}
	}
`;

S.ComplaintsList = styled.ul.attrs({ 'data-minddclass': 'complaintslist' })`
	margin: 0;
	padding: 0;
	list-style: none;
	column-count: 2;
	@media ${(props) => props.theme.screens.small} {
		column-count: 1;
		max-height: initial;
	}
`;

S.Complaint = styled.li.attrs({ 'data-minddclass': 'complaint' })`
	margin-bottom: 0.25rem;
	display: inline-block;
	width: 100%;
`;

S.ComplaintButton = styled.button.attrs((props) => ({
	'data-minddclass': 'complaintbutton',
	'aria-pressed': props.selected
}))`
	&&& {
		all: unset;
		width: calc(100% - 0.7rem);
		padding: 0.25rem 0.35rem;
		cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
		background: ${(props) => (props.selected ? props.theme.colors.accent : 'transparent')};
		color: ${(props) => (props.selected ? props.theme.colors.textinverted : props.disabled ? props.theme.colors.text : props.theme.colors.accent)};

		:hover {
			background-color: ${(props) => !props.disabled && props.theme.colors.accent};
			color: ${(props) => (!props.disabled || props.selected ? props.theme.colors.textinverted : props.theme.colors.text)};
		}

		:focus {
			outline: 2px auto ${(props) => props.theme.colors.focus};
		}
	}
`;

S.VisuallyHidden = styled.div`
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
`;

S.MarkDownUl = styled.ul`
	list-style: disc;
	padding-inline-start: 1.5rem;
`;

S.MarkDownLi = styled.li`
	padding-inline-start: 0rem;
	list-style: disc;
`;

S.MarkdownImage = styled.img.attrs({ 'data-minddclass': 'markdownimage' })`
	display: block;
	max-width: 100%;
	max-height: 300px;
	object-fit: contain;
`;

S.LineDevider = styled.div.attrs({ 'data-minddclass': 'linedevider' })`
	width: 100%;
	display: flex;
	height: 2px;
	background-color: ${(props) => (props.hasAdvice ? props.theme.colors.advicebackground : props.theme.colors.appbackground)};
	margin: 15px 0px;
`;

export default S;
