import React, { useCallback } from 'react';

import { default as SA } from '../Activity/styled';
//import S from './styled';

import Button from '../Button';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';

const ActivityDefault = (props) => {
	const [{ profile, settings }, dispatch] = useStateContext();

	/**
	 * Answers for activity and default to ja/j en nee/n
	 */
	const answers = props.answers || [
		{
			title: getLabel('QuestionDefaultYesLabel', settings.applicationTexts, true) || 'Ja',
			value: 'y'
		},
		{
			title: getLabel('QuestionDefaultNoLabel', settings.applicationTexts, true) || 'Nee',
			value: 'n'
		}
	];

	const handleActivityResponse = useCallback(
		(answer) => {
			props.handleActivityResponse(answer);

			if (props.profile) {
				dispatch({
					type: 'updateProfile',
					profile: {
						...profile,
						[props.profile]: answer
					}
				});
			}
		},
		[props, dispatch, profile]
	);

	return (
		<SA.ActivityBubble>
			<SA.BubbleTitle>{props.title}</SA.BubbleTitle>
			<SA.ActivityActions>
				{answers.map((answer, index) => (
					<Button
						key={index}
						disabled={props.disabled}
						buttonIndex={index}
						totalButtons={answers.length}
						state={props.answer === answer.value ? true : false}
						onClick={() => handleActivityResponse(`${answer.value}`)}
					>
						{answer.title}
					</Button>
				))}
			</SA.ActivityActions>
		</SA.ActivityBubble>
	);
};

export default ActivityDefault;
