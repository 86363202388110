import styled from 'styled-components';
import { default as SA } from '../Activity/styled';
import Button from '../Button';

const S = {};

S.Comment = styled.textarea.attrs({ 'data-minddclass': 'comment' })`
	display: block;
	border-radius: 4px;
	width: 100%;
	max-width: 400px;
	height: 200px;
	padding: 10px;
	margin-bottom: 1em;
	&:focus {
		outline: none;
	}
`;

S.SurveyAnswerButtons = styled(SA.ActivityActions).attrs({ 'data-minddclass': 'surveyanswerbuttons' })`
	justify-content: start;
	flex-direction: row;
	margin-top: 0.75rem;
	margin-bottom: 0;
	flex-wrap: wrap;

	> * {
		margin: 0 0.5rem;
		margin-bottom: 1rem;
		i,
		em,
		b,
		strong {
			font-style: normal;
			font-weight: normal;
			white-space: nowrap; /* trick to prevent ie. phonenumber to not wrap*/
		}
	}
	@media ${(props) => props.theme.screens.small} {
		margin-top: 0.25rem;
		margin-bottom: 0rem;

		flex-direction: column;
		padding-inline-start: 0px;
		> * {
			margin: 0.5rem 0rem;
		}
	}
`;

S.SurveyAnswerButton = styled(Button)`
	&&& {
		margin: 0.25rem 0.5rem 0.25rem 0;

		@media ${(props) => props.theme.screens.small} {
			margin: 0.25rem 0 0.25rem 0;
		}
	}
`;

export default S;
