import React, { FunctionComponent, useContext, useMemo, useState } from 'react';
import PhoneInput, { isValidPhoneNumber, Country, getCountryCallingCode, Value, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Select from 'react-select';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import S from './InputPhone.styled';
import SP from '../Procedure/styled';
import PhoneInputLabels from './InputPhoneLabels';
import createSelectStyles from '../../helpers/styled/Select';
import { ThemeContext } from 'styled-components';

const MINDD_LANG_TO_COUNTRY: {
	[key: string]: Country;
} = {
	nl: 'NL', // Dutch -> Netherlands
	be: 'BE', // Belgian Dutch -> Belgium
	en: 'GB', // English -> United Kingdom
	de: 'DE', // German -> Germany
	ar: 'MA', // Arabic -> Morocco
	fr: 'FR', // French -> France
	pap: 'AW', // Papiamento -> Aruba
	pl: 'PL', // Polish -> Poland
	ru: 'RU', // Russian -> Russia
	tr: 'TR', // Turkish -> Turkey
	uk: 'UA' // Ukrainian -> Ukraine
};

const PHONE_EU_COUNTRIES: Readonly<Country[]> = Object.freeze([
	'NL',
	'BE',
	'BG',
	'CY',
	'DK',
	'DE',
	'EE',
	'FI',
	'FR',
	'GR',
	'HU',
	'IE',
	'IT',
	'LV',
	'LT',
	'LU',
	'MT',
	'AT',
	'PL',
	'PT',
	'RO',
	'HR',
	'SK',
	'SI',
	'ES',
	'CZ',
	'SE',
	'GF',
	'GP',
	'IS',
	'LI',
	'MQ',
	'YT',
	'SX',
	'NO',
	'RE'
] as const);

const PHONE_NL_COUNTRY: Readonly<Country[]> = Object.freeze(['NL'] as const);

const REGION_TO_COUNTRIES: { [key: string]: Readonly<Country[]> } = {
	Europe: PHONE_EU_COUNTRIES,
	Netherlands: PHONE_NL_COUNTRY
};

interface OptionCountry {
	value: Country;
	label: string;
}

type InputPhoneProps = {
	name: string;
	defaultValue?: string;
	label: string | JSX.Element;
	valid?: boolean | null;
	disabled?: boolean;
	autoComplete?: string;
	onChange: (value?: string) => void;
};

interface SelectProps<T = { value: string; label: string }> {
	name?: string;
	value?: T;
	onChange: (value?: T) => void;
	onFocus: () => void;
	onBlur: () => void;
	options: { label: string; value: T }[];
	iconComponent: React.ElementType;
	disabled?: boolean;
	readOnly?: boolean;
	tabIndex?: number;
	className: string;
}

const InputPhone: FunctionComponent<InputPhoneProps> = (props) => {
	const [{ settings }] = useStateContext();

	const isValid = props.valid;
	const [value, setValue] = useState<string | undefined>(props.defaultValue);
	const [inputFocused, setInputFocused] = useState(false);
	const themeContext = useContext(ThemeContext);

	const countries = REGION_TO_COUNTRIES[settings.contactRequest.clientPhonenumberRegion] as Country[];

	const selectStyles = useMemo(
		() =>
			createSelectStyles<OptionCountry>(themeContext, {
				control: {
					width: 200,
					borderColor: isValid === false ? '#dc3545' : '#60606a',
					borderRadius: '0',
					':hover': {
						borderColor: isValid === false ? '#dc3545' : '#60606a'
					}
				},
				input: { marginLeft: 'calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio) + 0.5rem)' },
				singleValue: { overflowX: 'hidden' }
			}),
		[isValid, themeContext]
	);

	const inputComponent = useMemo(() => {
		const inputPhoneInputComponent = React.forwardRef<HTMLInputElement>((icProps, ref) => (
			<SP.Field ref={ref} {...icProps} name={props.name} style={{ marginLeft: '0.25rem', borderColor: isValid === false ? '#dc3545' : '#60606a' }} />
		));
		inputPhoneInputComponent.displayName = 'inputPhoneInputComponent';
		return inputPhoneInputComponent;
	}, [isValid, props.name]);

	const selectComponent = useMemo(() => {
		const inputPhoneSelectComponent = (scProps: SelectProps<Country>) => (
			<Select<OptionCountry>
				{...scProps}
				value={scProps.options.find((option) => option.value === scProps.value)}
				onChange={(option) => scProps.onChange(option?.value ?? undefined)}
				isClearable={false}
				styles={selectStyles}
				formatOptionLabel={(option) => (
					<S.PhoneCountryOptionWrapper>
						<S.PhoneCountryOptionImageWrapper>
							{/* Default flag icon url */}
							<S.PhoneCountryOptionImage src={`//${process.env.REACT_APP_PUBLICEMBEDDOMAIN!}/phone-country-flag/${option.value}.svg`} />
						</S.PhoneCountryOptionImageWrapper>
						<S.PhoneCountryOptionLabel>
							(+{getCountryCallingCode(option.value)}) {option.label}
						</S.PhoneCountryOptionLabel>
					</S.PhoneCountryOptionWrapper>
				)}
			/>
		);

		return inputPhoneSelectComponent;
	}, [selectStyles]);

	const handlePhoneInputOnChange = (value?: Value) => {
		setValue(value);

		if (value && isValidPhoneNumber(value)) {
			props.onChange(value);
		} else {
			props.onChange(undefined);
		}
	};

	const labels = PhoneInputLabels[settings.selectedLanguage.code.toLowerCase()] ?? PhoneInputLabels.nl;

	const selectedPhoneCountryCode = MINDD_LANG_TO_COUNTRY[settings.selectedLanguage.code.toLowerCase()] ?? MINDD_LANG_TO_COUNTRY.nl;

	const defaultOrSelectedCountry = !countries || countries.includes(selectedPhoneCountryCode) ? selectedPhoneCountryCode : MINDD_LANG_TO_COUNTRY.nl;

	return (
		<S.PhoneInputWrapper>
			<SP.Label htmlFor={props.name}>{props.label}</SP.Label>
			<PhoneInput
				value={value}
				onChange={handlePhoneInputOnChange}
				defaultCountry={defaultOrSelectedCountry}
				initialValueFormat="national"
				countrySelectComponent={selectComponent}
				inputComponent={inputComponent}
				autoComplete={props.autoComplete}
				disabled={props.disabled}
				countries={countries}
				addInternationalOption={false}
				labels={labels}
				focusInputOnCountrySelection={true}
				onFocus={() => setInputFocused(true)}
				onBlur={() => setInputFocused(false)}
				autoFocus={inputFocused}
			/>
		</S.PhoneInputWrapper>
	);
};

const parseInputPhoneNumber = (phoneNumber: string | null | undefined): string | null => {
	if (!phoneNumber) {
		return null;
	}

	const result = parsePhoneNumber(phoneNumber, { defaultCountry: MINDD_LANG_TO_COUNTRY.nl, extract: false });
	if (result && result.isValid()) {
		return result.number;
	}

	return null;
};

export { InputPhone, parseInputPhoneNumber };
