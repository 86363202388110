import { useState, useEffect, useContext } from 'react';
import { isMobile } from '../constants/setUserAgent';
import { ThemeContext } from 'styled-components';

const useDeviceDetection = () => {
	const [isMobileDevice, setIsMobileDevice] = useState(false);

	const themeContext = useContext(ThemeContext);
	const isMobileUA = isMobile();
	const breakpoint = themeContext.breakpoints.medium;

	useEffect(() => {
		const handleDeviceDetection = () => {
			const isMobile = isMobileUA || window.innerWidth < breakpoint;

			setIsMobileDevice(isMobile);
		};

		handleDeviceDetection();

		window.addEventListener('resize', handleDeviceDetection);

		return () => {
			window.removeEventListener('resize', handleDeviceDetection);
		};
	}, [isMobileUA, breakpoint]);

	return isMobileDevice;
};

export default useDeviceDetection;
