import styled from 'styled-components';

const S = {};

S.Conversation = styled.ul.attrs({ 'data-minddclass': 'conversation' })`
	&&& {
		list-style: none;
		padding: 0;
		margin: 0;
		position: relative;
		display: flex;
		flex-direction: column;
		&:after {
			content: '';
			display: block;
			min-height: ${(props) => props.filler}px;
		}
	}
`;

export default S;
