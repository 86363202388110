import React, { useState, useLayoutEffect, useEffect } from 'react';

import S from './styled';

import ActivityDefault from '../ActivityDefault';
import ActivityWelcome from '../ActivityWelcome';
import ActivityBodyArea from '../ActivityBodyArea';
import ActivityGPPost from '../ActivityGPPost';
import ActivityGender from '../ActivityGender';
import ActivityAge from '../ActivityAge';
import ActivityComplaint from '../ActivityComplaint';
import ActivityTriage from '../ActivityTriage';
import ActivityAbcdTriage from '../ActivityAbcdTriage';
import ActivityDirectTriage from '../ActivityDirectTriage';
import ActivitySearchComplaintSelector from '../ActivitySearchComplaintSelector';
import ActivitySearchTriage from '../ActivitySearchTriage';
import ActivityNoNextStepFound from '../ActivityNoNextStepFound';
import ActivityNoTriageFound from '../ActivityNoTriageFound';
import ActivityAdvice from '../ActivityAdvice';
import ActivityFeedback from '../ActivityFeedback';
import ProcedureContactRequestEnd from '../ProcedureContactRequestEnd';
import ConsultationPreparationQuestion from '../ConsultationPreparationQuestion';
import SurveyEnd from '../SurveyEnd';
import SurveyInquiry from '../SurveyInquiry';
import SurveyQuestion from '../SurveyQuestion';
import ProcedureContactRequest from '../ProcedureContactRequest';
import CallbackRequestScheduler from '../CallbackRequestScheduler/CallbackRequestScheduler';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { useToggleModal } from '../../helpers/hooks/useToggleModal';
import { CallStartSession } from '../../helpers/services/CallStartSession';
import { CallPostSessionLogAdviceBack } from '../../helpers/services/CallPostSessionLogAdviceBack';
import { getLabel } from '../../helpers/constants/getLabels';

const Activity = (props) => {
	const [{ refs, modal, profile, conversation, settings, session }, dispatch] = useStateContext();
	const handleToggleModal = useToggleModal();
	const [disabled, setDisabled] = useState(false);
	const [hideBackButton, setHideBackButton] = useState(props.disableBackButton !== undefined ? props.disableBackButton : false);
	const [hideBackButtonLabel, setHideBackButtonLabel] = useState(true);

	useLayoutEffect(() => {
		// disable activity
		if (props.answer) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	}, [props.answer, refs.widget]);

	const handleActivityResponse = async (answer, back = false) => {
		// Open modal if closed
		if (!modal.open) {
			const sessionResult = await CallStartSession(settings.ApiKey, {
				widgetType: settings.widgetType,
				restart: false,
				externalId: profile.externalId,
				phoneNumber: profile.phoneNumber,
				languageCode: settings.selectedLanguage.code
			});

			if (!sessionResult) {
				console.error('There was an error starting a session');
				return;
			}

			dispatch({
				type: 'updateSession',
				session: {
					id: sessionResult.sessionId,
					token: sessionResult.sessionToken
				}
			});

			handleToggleModal();
		}

		if (back) {
			// Log advice info if user goes back from a given advice.
			const [lastActivityBeforePop] = conversation.slice(-1);
			if (lastActivityBeforePop && lastActivityBeforePop.type === 'advice') {
				CallPostSessionLogAdviceBack(settings.ApiKey, {
					sessionId: session.id,
					sessionToken: session.token,
					sessionLogAdviceBack: {
						adviceBackClicked: true
					}
				});
			}

			conversation.pop();
			const lastActivity = conversation.slice(-1)[0];
			lastActivity.answer = null;

			dispatch({
				type: 'updateConversationActivities',
				conversation: conversation
			});
		}

		if (answer) {
			dispatch({
				type: 'conversation/setStepAnswer',
				stepId: props.id,
				answer: answer
			});

			// Generally activities do not add a next step them self but ask
			// the parent Conversation component to handle that.
			await props.continueConversation({ ...props, answer: answer });
		}
	};

	useEffect(() => {
		const documentKeyDown = (e) => {
			if (e.key === 'Escape' || e.key === 'Esc') {
				setHideBackButtonLabel(true);

				e.preventDefault();
			}
		};

		if (!props.isFirstActivity && props.isLastActivity && !hideBackButton) {
			document.addEventListener('keydown', documentKeyDown);

			return () => {
				document.removeEventListener('keydown', documentKeyDown);
			};
		}
	}, [props.isFirstActivity, props.isLastActivity, hideBackButton]);

	const backButtonOnFocusOrOnMouseOver = () => {
		setHideBackButtonLabel(false);
	};

	const backButtonOnBlurOrOnMouseOut = () => {
		setHideBackButtonLabel(true);
	};

	const setBackButtonStatus = (hideBackButton) => {
		setHideBackButton(hideBackButton);
	};

	const passedProps = {
		...props,
		modalOpen: modal.open,
		disabled: disabled,
		handleActivityResponse: handleActivityResponse,
		setBackButtonStatus: setBackButtonStatus,
		continueConversation: undefined // No need to pass this through
	};

	return (
		<S.Activity>
			{!props.isFirstActivity && props.isLastActivity && !hideBackButton && (
				<>
					<S.ActivityBack
						onClick={() => handleActivityResponse(false, true)}
						aria-labelledby={`${props.step}-ActivityBackTooltip`}
						onFocus={backButtonOnFocusOrOnMouseOver}
						onMouseOver={backButtonOnFocusOrOnMouseOver}
						onBlur={backButtonOnBlurOrOnMouseOut}
						onMouseOut={backButtonOnBlurOrOnMouseOut}
					>
						<svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
							<path fill="currentColor" d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
						</svg>
					</S.ActivityBack>
					<S.ActivityBackLabel id={`${props.step}-ActivityBackTooltip`} role="tooltip" hide={hideBackButtonLabel}>
						{getLabel('previousQuestionLabel', settings.applicationTexts, true)}
					</S.ActivityBackLabel>
				</>
			)}
			{{
				welcomeMessage: <ActivityWelcome {...passedProps} />,
				genderSelector: <ActivityGender {...passedProps} />,
				ageSelector: <ActivityAge {...passedProps} />,
				complaintSelector: <ActivityComplaint {...passedProps} />,
				directTriage: <ActivityDirectTriage {...passedProps} />,
				searchTriage: <ActivitySearchTriage {...passedProps} />,
				searchComplaintSelector: <ActivitySearchComplaintSelector {...passedProps} />,
				bodyAreaSelector: <ActivityBodyArea {...passedProps} />,
				askGPPost: <ActivityGPPost {...passedProps} />,
				triage: <ActivityTriage {...passedProps} />,
				abcdTriage: <ActivityAbcdTriage {...passedProps} />,
				advice: <ActivityAdvice {...passedProps} />,
				feedback: <ActivityFeedback {...passedProps} />,
				noTriageFound: <ActivityNoTriageFound {...passedProps} />,
				noNextStepFound: <ActivityNoNextStepFound {...passedProps} />,
				consultationPreparationQuestion: <ConsultationPreparationQuestion {...passedProps} />,
				surveyQuestion: <SurveyQuestion {...passedProps} />,
				surveyInquiry: <SurveyInquiry {...passedProps} />,
				surveyEnd: <SurveyEnd {...passedProps} />,
				requestCallback: <ProcedureContactRequest {...passedProps} />,
				requestCallbackEnd: <ProcedureContactRequestEnd {...passedProps} />,
				callbackRequestScheduler: <CallbackRequestScheduler {...passedProps} />
			}[props.type] || <ActivityDefault {...passedProps} />}

			{/* {process.env.NODE_ENV && process.env.NODE_ENV === 'development' && <div style={{ position: 'absolute', bottom: '0.5rem', left: '0.5rem' }}>{session}</div>} */}
		</S.Activity>
	);
};

export default Activity;
