import React, { useState, useCallback } from 'react';
import { compiler } from 'markdown-to-jsx';

import { default as SA } from '../Activity/styled';
import { default as SP } from '../Procedure/styled';
import S from './styled';

import InputChoices from './InputChoices';
import InputNumber from './InputNumber';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import { TextArea } from '../ProcedureContactRequest';

const ConsultationPreparationQuestion = (props) => {
	const [{ settings }] = useStateContext();

	const [answerText, setAnswerText] = useState(null);
	const [answerChoices, setAnswerChoices] = useState([]);
	const [sendStatus, setSendStatus] = useState(false);

	const { question } = props;
	const answers = question.answers;
	const choices = question.choices ? question.choices.map((c) => ({ id: c.id, title: c.title, selected: answerChoices.some((ac) => ac === c.id) })) : [];

	const focusLastActivity = useCallback(
		(node) => {
			if (node && props.isLastActivity && props.modalOpen) {
				node.focus();
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const handleSetComment = (e) => {
		setAnswerText(e.target.value);
	};

	const handleSetNumber = (e) => {
		setAnswerText(e.target.value);
	};

	const handleChoiceChange = (choiceId, checked) => {
		setAnswerChoices((prevAnswerChoices) => {
			if (checked) {
				// Only need to add if it's not already in the list
				if (!prevAnswerChoices.some((ac) => ac === choiceId)) {
					const newAnswerChoices = [...prevAnswerChoices, choiceId].sort((a, b) => a - b);
					return newAnswerChoices;
				}
			} else {
				const newAnswerChoices = prevAnswerChoices.filter((ac) => ac !== choiceId);
				if (newAnswerChoices.length !== prevAnswerChoices.length) {
					return newAnswerChoices;
				}
			}

			return prevAnswerChoices;
		});
	};

	const handleActivityResponse = async (answerId) => {
		setSendStatus(true);

		if (question.type === 'Number') {
			if (!answerText || answerText.length === 0) {
				return;
			}
		}

		if (question.type === 'MultipleChoice') {
			if (answerChoices.length === 0) {
				return;
			}
		}

		await props.handleActivityResponse({
			answerId: answerId,
			answerChoices: answerChoices,
			answerText: answerText
		});
	};

	return (
		<SA.ActivityGroup>
			{props.empathy && (
				<SA.ActivityBubble>
					<S.EmpathyBubbleTitle>{renderMarkdown(props.empathy)}</S.EmpathyBubbleTitle>
				</SA.ActivityBubble>
			)}
			<SA.ActivityBubble>
				<SA.BubbleTitle ref={focusLastActivity} tabIndex="-1">
					{compiler(question.title, {
						overrides: {
							p: {
								component: ({ children, ...props }) => <p {...props}>{children}</p>,
								props: {
									'data-texttype': 'markdown'
								}
							},
							img: {
								component: ({ children, ...props }) => <SA.MarkdownImage {...props}>{children}</SA.MarkdownImage>
							}
						}
					})}
				</SA.BubbleTitle>
				{question.type === 'Open' && (
					<SP.Col50>
						<TextArea
							onChange={handleSetComment}
							name="Comment"
							autoComplete="off"
							disabled={props.disabled}
						/>
					</SP.Col50>
				)}
				{question.type === 'Number' && (
					<SP.Col>
						<InputNumber onChange={handleSetNumber} disabled={props.disabled} />
					</SP.Col>
				)}
				{question.type === 'MultipleChoice' && (
					<InputChoices name="CPAnswerChoices" choices={choices} disabled={props.disabled} onChange={handleChoiceChange} />
				)}
				{sendStatus && (
					<SP.RowClean>
						{question.type === 'Number' && (!answerText || answerText.length === 0) && (
							<SP.Error>{getLabel('ConsultationPreparationErrorNumber', settings.applicationTexts, true)}</SP.Error>
						)}
						{question.type === 'MultipleChoice' && answerChoices.length === 0 && (
							<SP.Error>{getLabel('ConsultationPreparationErrorMultipleChoice', settings.applicationTexts, true)}</SP.Error>
						)}
					</SP.RowClean>
				)}
				{(question.type === 'Normal' || question.type === 'Open' || question.type === 'Number' || question.type === 'MultipleChoice') && (
					<S.ConsultationPreparationAnswerButtons>
						{answers.map((answer) => (
							<S.Button
								key={answer.id}
								disabled={props.disabled}
								state={props.answer?.answerId === answer.id ? true : false}
								onClick={() => handleActivityResponse(answer.id)}
							>
								{answer.title}
							</S.Button>
						))}
					</S.ConsultationPreparationAnswerButtons>
				)}
			</SA.ActivityBubble>
		</SA.ActivityGroup>
	);
};

export default ConsultationPreparationQuestion;
