import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { LanguageInput, SessionInput } from './BaseInput';
import { Gender, Triage } from '../../models';

export type GetComplaintsInput = SessionInput &
	LanguageInput & {
		gender: Gender;
		age: number;
		birthdate: Date;
		bodyAreaId: number;
	};

export type GetComplaintsOutput = {
	triages: Triage[];
};
async function CallGetComplaints(apiKey: string, input: GetComplaintsInput): Promise<Triage[]> {
	try {
		const { data } = await minddApiAxios.post<GetComplaintsOutput>('GetTriages?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return data.triages;
	} catch (error) {
		logAxiosError(error, 'CallGetComplaints');
	}

	return [];
}

export { CallGetComplaints };
