import axios, { isAxiosError } from 'axios';
import { ProblemDetails } from '../../models';

export const minddApiAxios = axios.create();

export function configureMinddApiAxios(baseUrl: string) {
	minddApiAxios.defaults.baseURL = baseUrl;
	minddApiAxios.defaults.headers.common = {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	};
}

export function logAxiosError(error: unknown, functionName: string) {
	if (isAxiosError<ProblemDetails>(error)) {
		if (error.response) {
			console.error(
				`Error in ${functionName}; message: `,
				error.message,
				'; status: ',
				error.response.status,
				'; traceId: ',
				error.response?.data?.traceId
			);
		} else {
			console.error(`Error in ${functionName}; message: `, error.message);
		}
	} else {
		console.error(`Unkown error in ${functionName}: `, error);
	}
}
