import React, { useState, useCallback, useEffect, useContext } from 'react';

import { ThemeContext } from 'styled-components';
import S from './styled';

import Select from 'react-select';

import { default as SA } from '../Activity/styled';
import { default as SP } from '../Procedure/styled';

import Button from '../Button';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallGetDoctors, CallPostDoctor } from '../../helpers/services';
import { getLabel } from '../../helpers/constants/getLabels';

const ActivityGPPost = (props) => {
	const [{ settings, session }] = useStateContext();
	const themeContext = useContext(ThemeContext);

	const [doctorOptions, setDoctorOptions] = useState([]);
	const [doctorId, setDoctorId] = useState(0);
	const [isSending, setIsSending] = useState(false);
	const [validationStatus, setvalidationStatus] = useState(null);

	useEffect(() => {
		const fetchDoctors = async () => {
			const doctors = await CallGetDoctors(settings.ApiKey, {
				type: 'Huisartsenpost',
				filterUsingGPPostCountryFilter: true
			});

			if (doctors) {
				const mappedDoctors = doctors.map((d) => ({
					value: d.id,
					label: d.title
				}));
				setDoctorOptions(mappedDoctors);
			}
		};
		fetchDoctors();
	}, [settings.ApiKey]);

	const focusLastActivity = useCallback(
		(node) => {
			if (node && props.isLastActivity && props.modalOpen) {
				setTimeout(() => {
					node.focus();
				}, 50);
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const handleSelectOnChange = (newValue, action) => {
		setDoctorId(newValue.value);
	};

	const handleSendData = async () => {
		setIsSending(true);
		let valid = true;

		valid = valid && doctorId !== 0;

		if (valid) {
			setvalidationStatus(true);

			const result = await CallPostDoctor(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				doctorId: doctorId
			});

			if (result) {
				await props.handleActivityResponse({ status: 'answered', doctorId: doctorId });
				setIsSending(false);
			} else {
				setvalidationStatus(false);
				setIsSending(false);
			}
		} else {
			setvalidationStatus(false);
			setIsSending(false);
		}
	};

	const handleSkipClicked = async () => {
		setvalidationStatus(true);

		props.handleActivityResponse({ status: 'skipped' });

		CallPostDoctor(settings.ApiKey, {
			sessionId: session.id,
			sessionToken: session.token,
			doctorId: null
		});
	};

	const selectStyle = {
		control: (styles, state) => ({
			...styles,
			background: 'transparent',
			borderColor: themeContext.colors.accent,
			boxShadow: 0,
			':hover': {
				...styles[':hover'],
				borderColor: themeContext.colors.accent
			}
		}),
		singleValue: (styles) => ({
			...styles,
			color: themeContext.colors.text
		}),
		input: (styles) => ({
			...styles,
			color: themeContext.colors.text
		}),
		dropdownIndicator: (styles) => ({
			...styles,
			color: themeContext.colors.text,
			':hover': {
				color: themeContext.colors.text
			}
		}),
		option: (styles, state) => ({
			...styles,
			backgroundColor: state.isSelected || state.isFocused ? themeContext.colors.accent : styles.backgroundColor,
			color: state.isSelected || state.isFocused ? themeContext.colors.textinverted : themeContext.colors.text,
			':active': {
				backgroundColor: themeContext.colors.accent,
				color: themeContext.colors.textinverted
			}
		})
	};

	return (
		<SA.ActivityBubble>
			<SA.BubbleTitle>{getLabel('WidgetAskGPPostRequestLabel', settings.applicationTexts)}</SA.BubbleTitle>

			<SP.Form>
				<SP.Row>
					<Select
						ref={focusLastActivity}
						options={doctorOptions}
						styles={selectStyle}
						placeholder={getLabel('WidgetAskGPPostPlaceholder', settings.applicationTexts, true)}
						isDisabled={isSending || props.disabled}
						onChange={handleSelectOnChange}
					/>
				</SP.Row>
			</SP.Form>

			{validationStatus === false && <SP.Error>{getLabel('WidgetAskGPPostRequestError', settings.applicationTexts)}</SP.Error>}
			<SA.ActivityActions>
				{settings.askForGPPostShowSkipButton && (
					<S.SkipButton onClick={handleSkipClicked} disabled={isSending || props.disabled}>
						{getLabel('WidgetAskGPPostSkipButton', settings.applicationTexts, true)}
					</S.SkipButton>
				)}
				<Button onClick={handleSendData} disabled={isSending || props.disabled}>
					{isSending
						? getLabel('WidgetAskGPPostSendingButton', settings.applicationTexts, true)
						: getLabel('WidgetAskGPPostSendButton', settings.applicationTexts, true)}
				</Button>
			</SA.ActivityActions>
		</SA.ActivityBubble>
	);
};

export default ActivityGPPost;
