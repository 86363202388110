import styled from 'styled-components';

const S = {};

S.App = styled.div.attrs({ 'data-minddclass': 'empathy' })`
	&&& {
		position: relative;
		font-size: 16px;
		margin: 0;
		font-family: ${(props) => props.theme.fonts.primary};
		height: 100%;
		width: 100%;
		a[data-markdownclass='anchor'] {
			color: ${(props) => props.theme.colors.accent};
		}

		* {
			font-family: ${(props) => props.theme.fonts.primary};
		}
	}
`;

S.StartButton = styled.div.attrs({ 'data-minddclass': 'startbutton', role: 'button' })`
	&&& {
	}
`;

S.Loader = styled.div.attrs({ 'data-minddclass': 'loader' })`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export default S;
