import { getLabel } from './getLabels';
import { ActivityStep, Settings } from '../../models';

export const initInitialConversation = (settings: Settings): ActivityStep[] => {
	let step = 0;
	const conversation: ActivityStep[] = [];

	/**
	 *
	 * @param {ActivityStep} step step to add
	 */
	const addConversationStep = (stepToAdd: ActivityStep) => {
		conversation.push({
			...stepToAdd,
			step: (step += 1)
		});
	};

	/**
	 * Setup steps
	 */

	const labels = settings.applicationTexts;
	const prefill = settings.prefill;
	if (prefill && prefill.active && prefill.bodypart && prefill.complaint) {
		addConversationStep({
			step: 0,
			id: 'initialDirectTriage',
			type: 'directTriage'
		});
	} else if (settings.startWithTriageSearch) {
		addConversationStep({
			step: 0,
			id: 'initialSearchTriage',
			type: 'searchTriage',
			title: getLabel('QuestionWhatSearchTriage', labels),
			profile: 'selectedSearchTriageHit'
		});
	} else if (settings.startWithAbcdTriage) {
		addConversationStep({
			step: 0,
			id: 'initialGenderSelector',
			type: 'genderSelector',
			title: getLabel('QuestionWhatGender', labels),
			info: getLabel('QuestionWhatGenderInfo', labels, false, true),
			profile: 'gender'
		});

		addConversationStep({
			step: 0,
			id: 'initialAgeSelector',
			type: 'ageSelector',
			title: getLabel('QuestionWhatAge', labels),
			info: getLabel('QuestionWhatAgeInfo', labels, false, true),
			profile: 'age'
		});
	} else {
		if (settings.startQuestionType === 'age') {
			addConversationStep({
				step: 0,
				id: 'initialAgeSelector',
				type: 'ageSelector',
				title: getLabel('QuestionWhatAge', labels),
				info: getLabel('QuestionWhatAgeInfo', labels, false, true),
				profile: 'age'
			});

			addConversationStep({
				step: 0,
				id: 'initialGenderSelector',
				type: 'genderSelector',
				title: getLabel('QuestionWhatGender', labels),
				info: getLabel('QuestionWhatGenderInfo', labels, false, true),
				profile: 'gender'
			});
		} else {
			addConversationStep({
				step: 0,
				id: 'initialGenderSelector',
				type: 'genderSelector',
				title: getLabel('QuestionWhatGender', labels),
				info: getLabel('QuestionWhatGenderInfo', labels, false, true),
				profile: 'gender'
			});

			addConversationStep({
				step: 0,
				id: 'initialAgeSelector',
				type: 'ageSelector',
				title: getLabel('QuestionWhatAge', labels),
				info: getLabel('QuestionWhatAgeInfo', labels, false, true),
				profile: 'age'
			});
		}

		addConversationStep({
			step: 0,
			id: 'initialComplaintSelector',
			type: 'complaintSelector',
			title: getLabel('QuestionWhatBodyPart', labels),
			info: getLabel('QuestionWhatBodyPartInfo', labels, false, true),
			profile: 'bodypart'
		});
	}

	return conversation;
};
