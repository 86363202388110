import { AxiosResponse } from 'axios';

import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { LanguageInput } from './BaseInput';
import { BodyImage, Gender } from '../../models';

export type GetBodyAreasInput = LanguageInput & {
	gender?: Gender;
	age: number;
	bodyAreaIds: number[];
};

type GetBodyAreasInputFull = GetBodyAreasInput & {
	excludeBodyAreas: boolean;
};

export type GetBodyAreasOutput = {
	bodyImageSVGs: BodyImage[];
};

async function CallGetBodyAreas(apiKey: string, input: GetBodyAreasInput) {
	try {
		const { data } = await minddApiAxios.post<GetBodyAreasOutput, AxiosResponse<GetBodyAreasOutput>, GetBodyAreasInputFull>(
			'GetBodyAreas?api-version=2.1',
			{
				...input,
				excludeBodyAreas: true
			},
			{
				headers: {
					'MINDD-OC-ApiKey': apiKey
				}
			}
		);

		return data;
	} catch (error) {
		logAxiosError(error, 'CallGetBodyAreas');
	}

	return undefined;
}

export { CallGetBodyAreas };
