import React, { useLayoutEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { dayName, dayOfTheMonth, monthName } from '../../helpers/support/dateTimeHelpers';
import { CallbackRequestSchedule, ScheduleDay } from '../../models';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import S from './DaySelector.styled';

interface DaySelectorProps {
	schedule: CallbackRequestSchedule;
	selectedDay: number;
	selectDay: (idx: number) => void;
}

const DaySelector = ({ schedule, selectedDay, selectDay }: DaySelectorProps) => {
	const [{ settings }] = useStateContext();
	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const [showScrollLeft, setShowScrollLeft] = useState<boolean>(false);
	const [showScrollRight, setShowScrollRight] = useState<boolean>(true);

	const scrollLeft = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({ left: -scrollContainerRef.current.clientWidth, behavior: 'smooth' });
		}
	};

	const scrollRight = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({ left: scrollContainerRef.current.clientWidth, behavior: 'smooth' });
		}
	};

	useLayoutEffect(() => {
		const checkScrollPosition = () => {
			const element = scrollContainerRef.current;
			if (element) {
				const maxScrollLeft = element.scrollWidth - element.clientWidth;
				const tolerance = 1; // small tolerance to account for rounding errors
				setShowScrollLeft(element.scrollLeft > 0);
				setShowScrollRight(element.scrollLeft + tolerance < maxScrollLeft);
			}
		};

		const handleResize = () => {
			// Trigger the scroll check on window resize as well
			checkScrollPosition();
		};

		const element = scrollContainerRef.current;
		if (element) {
			window.addEventListener('resize', handleResize);
			element.addEventListener('scroll', checkScrollPosition);
			// Check initial position
			checkScrollPosition();
			return () => {
				window.removeEventListener('resize', handleResize);
				element.removeEventListener('scroll', checkScrollPosition);
			};
		}
	}, [scrollContainerRef]);

	return (
		<S.DaySelectorContainerWrapper>
			<S.LeftScrollButtonWrapper>
				{showScrollLeft && (
					<button onClick={scrollLeft}>
						<FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: '16px' }} />
					</button>
				)}
			</S.LeftScrollButtonWrapper>
			<S.RightScrollButtonWrapper>
				{showScrollRight && (
					<button onClick={scrollRight}>
						<FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '16px' }} />
					</button>
				)}
			</S.RightScrollButtonWrapper>
			<S.DaySelectorContainer ref={scrollContainerRef} className="DaySelectorContainer">
				{schedule &&
					schedule.timeSlots &&
					schedule.timeSlots.map((day: ScheduleDay, index: number) => (
						<S.DayButton
							isSelected={selectedDay === index}
							isDisabled={day.slots.length === 0}
							disabled={day.slots.length === 0}
							key={index}
							onClick={() => selectDay(index)}
							className={selectedDay === index ? 'selectedDayButton' : ''}
						>
							<div className="dayName">{dayName(day.date, settings.selectedLanguage.code)}</div>
							<div className="dayOfTheMonth">{dayOfTheMonth(day.date, settings.selectedLanguage.code)}</div>
							<div className="monthName">{monthName(day.date, settings.selectedLanguage.code)}</div>
						</S.DayButton>
					))}
			</S.DaySelectorContainer>
		</S.DaySelectorContainerWrapper>
	);
};

export default DaySelector;
