import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { SessionInput } from './BaseInput';

export type PostZipCodeInput = SessionInput & {
	zipCode: string;
	birthdate?: Date;
	userAgreedToUsage: boolean;
};

async function CallPostZipCode(apiKey: string, input: PostZipCodeInput): Promise<boolean> {
	try {
		const { status } = await minddApiAxios.post('PostZipCode?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return status === 200;
	} catch (error) {
		logAxiosError(error, 'CallPostZipCode');
	}

	return false;
}

export { CallPostZipCode };
