import { useCallback, useMemo } from 'react';

import { UseNextStepAbcdTriageResultType, useNextStepAbcdTriage } from './useNextStepAbcdTriage';
import { useNextStepCallbackRequest, UseNextStepCallbackRequestResultType } from './useNextStepCallbackRequest';
import { useNextStepConsultationPreparation } from './useNextStepConsultationPreparation';
import { UseNextStepSearchTriageResultType, useNextStepSearchTriage } from './useNextStepSearchTriage';
import { useNextStepSurvey } from './useNextStepSurvey';
import { UseNextStepTriageRedirectResultType, useNextStepTriageRedirect } from './useNextStepTriageRedirect';
import { StateContextType, useStateContext } from '../useStateContext';
import { initInitialConversation } from '../../constants/initInitialConversation';
import { CallGetNext, CallGetStartQuestion } from '../../services';
import { createDateForDob } from '../../support/createDateForDob';
import {
	ActivityAnswerAgeSelector,
	ActivityAnswerComplaintSelector,
	ActivityAnswerGenderSelector,
	ActivityStep,
	NextTriageGroup,
	Profile,
	SearchTriageGroup,
	Session
} from '../../../models';

export function useDetermineNextStep(): (currentStep?: Readonly<ActivityStep>) => Promise<ActivityStep | undefined> {
	const [{ profile, session, settings }]: StateContextType = useStateContext();
	const handleNextStepAbcdTriage = useNextStepAbcdTriage();
	const handleNextStepCallbackRequest = useNextStepCallbackRequest();
	const handleNextStepConsultationPreparation = useNextStepConsultationPreparation();
	const handleNextStepSearchTriage = useNextStepSearchTriage();
	const handleNextStepSurvey = useNextStepSurvey();
	const handleNextStepTriageRedirect = useNextStepTriageRedirect();

	const initialConversation = useMemo<readonly ActivityStep[]>(() => {
		return initInitialConversation(settings);
	}, [settings]);

	return useCallback(
		(currentStep?: Readonly<ActivityStep>): Promise<ActivityStep | undefined> => {
			if (!currentStep) {
				return Promise.resolve(initialConversation[0]);
			}

			// If we can find the current stepId in the initial conversation, see if we can get the next step
			// from the initial conversaion
			const initialConversationIndex = initialConversation.findIndex((as) => as.id === currentStep.id);
			if (initialConversationIndex !== -1) {
				const nextIndex = initialConversationIndex + 1;
				if (nextIndex < initialConversation.length) {
					const nextStep = initialConversation[initialConversationIndex + 1];
					if (nextStep) {
						return Promise.resolve(nextStep);
					}
				}
			}

			if (!currentStep.answer) {
				return Promise.resolve(undefined);
			}

			switch (currentStep.type) {
				case 'directTriage':
					return getTriageFollowUp(
						currentStep.answer.questionId,
						currentStep.answer.answer,
						profile,
						session,
						settings.selectedLanguage.code,
						settings.ApiKey,
						handleNextStepCallbackRequest,
						handleNextStepTriageRedirect
					);
				case 'genderSelector':
					return getGenderSelectorFollowUp(
						currentStep.id,
						currentStep.answer,
						currentStep.triageGroup,
						settings.startWithTriageSearch,
						profile,
						handleNextStepSearchTriage,
						handleNextStepTriageRedirect
					);
				case 'ageSelector':
					return getAgeSelectorFollowUp(
						currentStep.id,
						currentStep.answer,
						currentStep.triageGroup,
						settings.startWithTriageSearch,
						settings.startWithAbcdTriage,
						profile,
						handleNextStepAbcdTriage,
						handleNextStepSearchTriage,
						handleNextStepTriageRedirect
					);
				case 'complaintSelector':
					return getComplaintSelectorFollowUp(
						settings.askForGPPost,
						currentStep.answer,
						profile,
						session,
						settings.selectedLanguage.code,
						settings.ApiKey
					);
				case 'searchTriage':
				case 'searchComplaintSelector':
					return getSearchTriageFollowUp(
						currentStep.answer.triageGroup,
						settings.startWithTriageSearch,
						settings.startWithAbcdTriage,
						profile,
						handleNextStepSearchTriage
					);
				case 'bodyAreaSelector':
					return getBodyAreaSelectorFollowUp(
						currentStep.id,
						currentStep.answer.bodyAreaId,
						currentStep.triageGroup,
						settings.startWithTriageSearch,
						settings.startWithAbcdTriage,
						profile,
						handleNextStepSearchTriage,
						handleNextStepTriageRedirect
					);
				case 'askGPPost':
					return getAskForGPPostFollowUp(
						currentStep.triageId,
						settings.startWithTriageSearch,
						settings.startWithAbcdTriage,
						profile,
						session,
						settings.selectedLanguage.code,
						settings.ApiKey,
						handleNextStepSearchTriage
					);
				case 'triage':
					return getTriageFollowUp(
						currentStep.question.id,
						currentStep.answer,
						profile,
						session,
						settings.selectedLanguage.code,
						settings.ApiKey,
						handleNextStepCallbackRequest,
						handleNextStepTriageRedirect
					);
				case 'abcdTriage':
					return handleNextStepAbcdTriage(currentStep.question.id, currentStep.answer);
				case 'consultationPreparationQuestion':
					return handleNextStepConsultationPreparation(
						currentStep.question.id,
						currentStep.answer.answerId,
						currentStep.answer.answerChoices,
						currentStep.answer.answerText,
						currentStep.urgence,
						undefined,
						undefined,
						currentStep.showImageField
					);
				case 'surveyQuestion':
					return handleNextStepSurvey(currentStep.question.id, currentStep.answer.answerId, currentStep.answer.answerText);
			}

			return Promise.resolve(undefined);
		},
		[
			initialConversation,
			profile,
			session,
			settings,
			handleNextStepAbcdTriage,
			handleNextStepCallbackRequest,
			handleNextStepConsultationPreparation,
			handleNextStepSearchTriage,
			handleNextStepSurvey,
			handleNextStepTriageRedirect
		]
	);
}

function getGenderSelectorFollowUp(
	activityId: string,
	answer: ActivityAnswerGenderSelector,
	triageGroup: SearchTriageGroup | NextTriageGroup | undefined,
	startWithTriageSearch: boolean,
	profile: Profile,
	handleNextStepSearchTriage: UseNextStepSearchTriageResultType,
	handleNextStepTriageRedirect: UseNextStepTriageRedirectResultType
): Promise<ActivityStep> {
	let nextTriageGroup: NextTriageGroup | null = null;
	let searchTriageGroup: SearchTriageGroup | null = null;

	if (triageGroup) {
		if ('groupId' in triageGroup) {
			nextTriageGroup = triageGroup;
		} else {
			searchTriageGroup = triageGroup;
		}
	}

	if (activityId.startsWith('nextTriageGenderSelector') && nextTriageGroup) {
		return handleNextStepTriageRedirect(nextTriageGroup, profile.bodypart, answer.gender);
	} else if (startWithTriageSearch) {
		return handleNextStepSearchTriage(searchTriageGroup, profile.bodypart, answer.gender);
	}

	console.error('useDetermineNextStep.getGenderSelectorFollowUp: Unknown state');

	return Promise.resolve({
		step: 0,
		id: `genderSelector-notFound`,
		type: 'noNextStepFound'
	});
}

function getAgeSelectorFollowUp(
	activityId: string,
	answer: ActivityAnswerAgeSelector,
	triageGroup: SearchTriageGroup | NextTriageGroup | undefined,
	startWithTriageSearch: boolean,
	startWithAbcdTriage: boolean | undefined,
	profile: Profile,
	handleNextStepAbcdTriage: UseNextStepAbcdTriageResultType,
	handleNextStepSearchTriage: UseNextStepSearchTriageResultType,
	handleNextStepTriageRedirect: UseNextStepTriageRedirectResultType
): Promise<ActivityStep> {
	const birthdate = createDateForDob(answer.dob);

	let nextTriageGroup: NextTriageGroup | null = null;
	let searchTriageGroup: SearchTriageGroup | null = null;

	if (triageGroup) {
		if ('groupId' in triageGroup) {
			nextTriageGroup = triageGroup;
		} else {
			searchTriageGroup = triageGroup;
		}
	}

	if (activityId.startsWith('nextTriageAgeSelector') && nextTriageGroup) {
		return handleNextStepTriageRedirect(nextTriageGroup, profile.bodypart, profile.gender, answer.age.age, birthdate);
	} else if (startWithTriageSearch) {
		return handleNextStepSearchTriage(searchTriageGroup, profile.bodypart, profile.gender, answer.age.age, birthdate);
	} else if (startWithAbcdTriage) {
		return handleNextStepAbcdTriage();
	}

	console.error('useDetermineNextStep.getAgeSelectorFollowUp: Unknown state');

	return Promise.resolve({
		step: 0,
		id: `getAgeSelector-notFound`,
		type: 'noNextStepFound'
	});
}

function getComplaintSelectorFollowUp(
	askForGPPost: boolean,
	answer: ActivityAnswerComplaintSelector,
	profile: Profile,
	session: Session,
	languageCode: string,
	apiKey: string
): Promise<ActivityStep> {
	if (askForGPPost) {
		return Promise.resolve<ActivityStep>({
			step: 0,
			id: 'complaintAskGPPost',
			type: 'askGPPost',
			triageId: answer.triageId
		});
	}

	return getStartQuestion(answer.triageId, answer.bodyAreaId, profile, session, languageCode, apiKey);
}

function getSearchTriageFollowUp(
	triageGroup: SearchTriageGroup | null,
	startWithTriageSearch: boolean,
	startWithAbcdTriage: boolean | undefined,
	profile: Profile,
	handleNextStepSearchTriage: UseNextStepSearchTriageResultType
): Promise<ActivityStep> {
	if (startWithTriageSearch) {
		return handleNextStepSearchTriage(triageGroup);
	} else if (startWithAbcdTriage) {
		const birthdate = createDateForDob(profile.dob);
		return handleNextStepSearchTriage(triageGroup, undefined, profile.gender, profile.age, birthdate);
	}

	console.error('useDetermineNextStep.getSearchTriageFollowUp: Should only be used with search or ABCD-triage');

	return Promise.resolve({
		step: 0,
		id: `searchTriage-notFound`,
		type: 'noNextStepFound'
	});
}

function getBodyAreaSelectorFollowUp(
	activityId: string,
	bodyAreaId: number,
	triageGroup: SearchTriageGroup | NextTriageGroup,
	startWithTriageSearch: boolean,
	startWithAbcdTriage: boolean | undefined,
	profile: Profile,
	handleNextStepSearchTriage: UseNextStepSearchTriageResultType,
	handleNextStepTriageRedirect: UseNextStepTriageRedirectResultType
): Promise<ActivityStep> {
	let nextTriageGroup: NextTriageGroup | null = null;
	let searchTriageGroup: SearchTriageGroup | null = null;

	if ('groupId' in triageGroup) {
		nextTriageGroup = triageGroup;
	} else {
		searchTriageGroup = triageGroup;
	}

	if (activityId.startsWith('nextTriageBodyAreaSelector') && nextTriageGroup) {
		return handleNextStepTriageRedirect(nextTriageGroup, bodyAreaId);
	} else if (startWithTriageSearch) {
		return handleNextStepSearchTriage(searchTriageGroup, bodyAreaId);
	} else if (startWithAbcdTriage) {
		// Body area is only asked after ABCD-triage and the user searched for a triage, that why we
		// continue with the NextStepSearchTriage
		const birthdate = createDateForDob(profile.dob);
		return handleNextStepSearchTriage(searchTriageGroup, bodyAreaId, profile.gender, profile.age, birthdate);
	}

	console.error('useDetermineNextStep.getBodyAreaSelectorFollowUp: Unknown state');

	return Promise.resolve({
		step: 0,
		id: `bodyAreaSelector-notFound`,
		type: 'noNextStepFound'
	});
}

function getAskForGPPostFollowUp(
	triageId: number,
	startWithTriageSearch: boolean,
	startWithAbcdTriage: boolean | undefined,
	profile: Profile,
	session: Session,
	languageCode: string,
	apiKey: string,
	handleNextStepSearchTriage: UseNextStepSearchTriageResultType
): Promise<ActivityStep> {
	const birthdate = createDateForDob(profile.dob);

	if (startWithTriageSearch || startWithAbcdTriage) {
		if (!profile.selectedSearchTriageGroup) {
			console.error('useDetermineNextStep.getAskForGPPostFollowUp: profile.selectedSearchTriageGroup is empty');

			return Promise.resolve<ActivityStep>({
				step: 0,
				id: `selectedSearchTriageGroup-notFound`,
				type: 'noNextStepFound'
			});
		}

		return handleNextStepSearchTriage(
			profile.selectedSearchTriageGroup,
			profile.bodypart,
			profile.gender,
			profile.age ? profile.age : '0',
			birthdate,
			true
		);
	} else {
		return getStartQuestion(triageId, profile.bodypart, profile, session, languageCode, apiKey);
	}
}

async function getTriageFollowUp(
	questionId: number,
	answer: string | number,
	profile: Profile,
	session: Session,
	languageCode: string,
	apiKey: string,
	handleNextStepCallbackRequest: UseNextStepCallbackRequestResultType,
	handleNextStepTriageRedirect: UseNextStepTriageRedirectResultType
): Promise<ActivityStep> {
	const nextQuestionResult = await CallGetNext(apiKey, {
		sessionId: session.id,
		sessionToken: session.token,
		questionId: questionId,
		answer: answer,
		languageCode: languageCode
	});

	if (nextQuestionResult) {
		if (nextQuestionResult.nextQuestion) {
			return {
				step: 0,
				id: `question-${nextQuestionResult.nextQuestion.id}`,
				type: 'triage',
				question: nextQuestionResult.nextQuestion,
				empathy: nextQuestionResult.empathy
			};
		} else if (nextQuestionResult.advice) {
			if (nextQuestionResult.advice.skipAdvicePage && nextQuestionResult.advice.startContactRequestProcedure) {
				return await handleNextStepCallbackRequest(nextQuestionResult.advice);
			}

			return {
				step: 0,
				id: 'advice',
				type: 'advice',
				advice: nextQuestionResult.advice,
				callButtonPhoneNumbers: nextQuestionResult.callButtonPhoneNumbers
			};
		} else if (nextQuestionResult.nextTriageGroup) {
			const birthdate = createDateForDob(profile.dob);

			return await handleNextStepTriageRedirect(
				nextQuestionResult.nextTriageGroup,
				profile.bodypart,
				profile.gender,
				profile.age ? profile.age : '0',
				birthdate
			);
		}
	}

	return {
		step: 0,
		id: `getNext-${questionId}`,
		type: 'noNextStepFound'
	};
}

async function getStartQuestion(
	triageId: number,
	bodyAreaId: number | null | undefined,
	profile: Profile,
	session: Session,
	languageCode: string,
	apiKey: string
): Promise<ActivityStep> {
	const startQuestion = await CallGetStartQuestion(apiKey, {
		triageId: triageId,
		gender: profile.gender,
		age: profile.age ?? 0,
		birthdate: createDateForDob(profile.dob),
		bodyAreaId: bodyAreaId,
		requestType: 'StartTriage',
		languageCode: languageCode,
		sessionId: session.id,
		sessionToken: session.token
	});

	if (startQuestion) {
		return {
			step: 0,
			id: `question-${startQuestion.startQuestion.id}`,
			type: 'triage',
			question: startQuestion.startQuestion,
			empathy: startQuestion.empathy
		};
	}

	console.error(`useDetermineNextStep.getStartQuestion: Failed to get start question for triage ${triageId}`);

	return {
		step: 0,
		id: `startQuestion-${triageId}`,
		type: 'noNextStepFound'
	};
}
