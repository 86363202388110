import React from 'react';
import styled from 'styled-components';
import { default as SP } from '../Procedure/styled';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { getLabel } from '../../helpers/constants/getLabels';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import { AdvicePhoneNumber } from '../../models';
import useDeviceDetection from '../../helpers/hooks/useDeviceDetection';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { IconButton } from '../Button';

interface PhoneNumberListProps {
	activePhoneNumbers: AdvicePhoneNumber[];
	handleCall: (phoneNumber: string) => void;
}

const PhoneNumberRowDivider = styled.div`
	&&& {
		width: 100%;
		display: flex;
		height: 2px;
		background-color: ${(props) => props.theme.colors.advicebackground};
		margin: 1rem 0;
	}
`;

const PhoneNumberRow = styled.div`
	&&& {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
`;

const PhoneNumberRowWrapper = styled.div`
	&&& {
		display: flex;
		flex-direction: column;
	}
`;

const PhoneNumberLabel = styled(SP.Label)`
	&&& {
		font-weight: 600;
		line-height: 1.5rem;
	}
`;

const PhoneNumberList = ({ activePhoneNumbers, handleCall }: PhoneNumberListProps) => {
	const [{ settings }] = useStateContext();
	const isMobileDevice = useDeviceDetection();

	return (
		<>
			{activePhoneNumbers.map((phoneNumber, index) => (
				<PhoneNumberRowWrapper key={index}>
					<PhoneNumberRow>
						<div>
							<PhoneNumberLabel>{renderMarkdown(phoneNumber.label)}</PhoneNumberLabel>
							{renderMarkdown(phoneNumber.phoneNumber)}
						</div>
						{isMobileDevice && (
							<IconButton onClick={() => handleCall(phoneNumber.phoneNumber)} icon={faPhone} position={'before'}>
								{getLabel('NativeButtonCall', settings.applicationTexts, true)}
							</IconButton>
						)}
					</PhoneNumberRow>
					{index !== activePhoneNumbers.length - 1 && <PhoneNumberRowDivider />}
				</PhoneNumberRowWrapper>
			))}
		</>
	);
};

export default PhoneNumberList;
