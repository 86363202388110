import styled from 'styled-components';
import { default as SA } from '../Activity/styled';
const S = {};

S.ClearActivityBubble = styled(SA.ActivityBubble).attrs({ 'data-minddclass': 'clearactivitybubble' })`
	&&& {
		background-color: transparent;
		color: ${(props) => props.foregroundColor || props.theme.colors.text};
		box-shadow: none;

		padding: 0;
		padding-bottom: 1rem;
	}
`;

S.ClearActivityBubbleWhenOpen = styled(SA.ActivityBubble).attrs({ 'data-minddclass': 'clearactivitybubblewhenopen' })`
	&&& {
		background-color: transparent;
		box-shadow: none;
		padding: 0;
		padding-bottom: 1rem;
	}
`;

export default S;
