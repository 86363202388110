import styled from 'styled-components';
import { default as SA } from '../Activity/styled';
const S = {};

S.ActivityBubble = styled(SA.ActivityBubble)`
	&&& {
		max-height: none;
	}
`;

S.ActivityComplaintWrapper = styled.div.attrs({ 'data-minddclass': 'activitycomplaintwrapper' })`
	display: flex;
	flex-direction: row;
	margin-top: 1em;

	@media ${(props) => props.theme.screens.small} {
		flex-direction: column;
	}
`;

S.Body = styled.div.attrs({ 'data-minddclass': 'body' })`
	position: relative;
	max-width: 250px;

	width: 100%;
	max-height: 500px;
	height: 60vh;
	padding-inline-end: 1em;
	display: flex;
	align-items: center;

	@media ${(props) => props.theme.screens.small} {
		max-width: 100%;
		padding-inline-end: 0;
	}

	g[data-bodyareaid]:focus {
		outline: none;

		path {
			opacity: ${(props) => !props.disabled && '0.4'};
		}
	}

	path[data-bodyareaid],
	g[data-bodyareaid] path {
		transition: opacity 0.15s ease-in;
		opacity: 0;
		fill: ${(props) => props.theme.colors.accent};
		cursor: ${(props) => !props.disabled && 'pointer'};
		:focus,
		:hover {
			opacity: ${(props) => !props.disabled && '0.4'};
		}

		:focus {
			outline: none;
		}
	}
	path[data-disabled],
	g[data-disabled] path {
		fill: ${(props) => props.theme.colors.disabled};
		opacity: 0.2;
	}
	g[data-disabled] path[id='haar'],
	g[data-disabled] path[id='haarachter'] {
		fill: ${(props) => props.theme.colors.disabled};
		opacity: 1;
	}

	path[id='haar'],
	path[id='haarachter'],
	g[data-bodyareaid] path[id='haar'],
	g[data-bodyareaid] path[id='haarachter'] {
		opacity: 1;
	}
	g[data-bodyareaid] path[id='haar']:hover,
	g[data-bodyareaid] path[id='haarachter']:hover {
		opacity: ${(props) => !props.disabled && '0.5'};
	}

	svg {
		width: 100%;
		height: 100%;
		&.child {
			height: 85%;
			#haarachter {
				opacity: 0;
			}
		}
	}

	path[data-selected],
	g[data-selected] path,
	g[data-selected] path[id='haar'],
	g[data-selected] path[id='haarachter'] {
		opacity: 0.6;
	}
`;

S.Switch = styled.div.attrs({ 'data-minddclass': 'switch' })`
	position: absolute;
	inset-inline-start: 0;
	bottom: 0;
`;

S.SvgLoader = styled.div.attrs({ 'data-minddclass': 'svgloader' })`
	max-width: 250px;

	width: 100%;
	max-height: 500px;
	height: 60vh;
	padding-inline-end: 1em;
	display: flex;
	align-items: center;
	justify-content: center;
`;

S.ComplaintsTitle = styled.h3.attrs({ 'data-minddclass': 'complaintstitle' })`
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	width: 100%;
	color: ${(props) => props.theme.colors.text};
	text-transform: uppercase;
	display: flex;
	align-items: center;
	white-space: nowrap;
	margin-inline-start: 0.35rem;
	margin-bottom: 0;
	:after {
		content: ' ';
		display: inline-block;
		height: 1px;
		background-color: ${(props) => props.theme.colors.text};
		width: 100%;
		margin-inline-start: 7px;
	}
`;

S.Complaints = styled.div.attrs({ 'data-minddclass': 'complaints' })`
	flex: 2;
	@media ${(props) => props.theme.screens.small} {
		margin-top: 1rem;
	}
`;

S.ComplaintsEmpty = styled.div.attrs({ 'data-minddclass': 'complaintsempty' })`
	margin-inline-start: 0.35rem;
	font-style: italic;
`;

S.ComplaintsList = styled(SA.ComplaintsList)``;

S.Complaint = styled(SA.Complaint)``;

S.ComplaintButton = styled(SA.ComplaintButton)``;

S.ResetBodyPartSelection = styled.li.attrs({ 'data-minddclass': 'resetbodypartselection' })`
	display: none;

	@media ${(props) => props.theme.screens.small} {
		display: block;
	}
`;

export default S;
