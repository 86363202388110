import React, { useState, useEffect } from 'react';
import { compiler } from 'markdown-to-jsx';

import { default as SA } from '../Activity/styled';
import SQ from '../SurveyQuestion/styled';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { useNextStepSurvey } from '../../helpers/hooks/nextStep/useNextStepSurvey';
import { CallSurveyStartQuestion } from '../../helpers/services';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';

const SurveyIntro = (props) => {
	const [{ settings, session }, dispatch] = useStateContext();
	const handleNextStepSurvey = useNextStepSurvey();

	const [startQuestion, setStartQuestion] = useState(null);
	const [answerText, setAnswerText] = useState(null);

	const handleSetComment = (e) => {
		setAnswerText(e.target.value);
	};

	const handleActivityResponse = async (answerId) => {
		const nextStepSurvey = await handleNextStepSurvey(startQuestion.id, answerId, answerText);
		dispatch({
			type: 'conversation/addStep',
			step: nextStepSurvey
		});

		await props.handleActivityResponse({ answerId: answerId, answerText: answerText });
	};

	useEffect(() => {
		const fetchStartQuestion = async () => {
			const surveyStartQuestion = await CallSurveyStartQuestion(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				languageCode: settings.selectedLanguage.code
			});

			if (surveyStartQuestion) {
				setStartQuestion(surveyStartQuestion.question);
			}
		};
		fetchStartQuestion();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{renderMarkdown(settings.survey.labels.intro)}
			{startQuestion && (
				<>
					{compiler(startQuestion.title, {
						overrides: {
							p: {
								component: ({ children, ...props }) => <p {...props}>{children}</p>,
								props: {
									'data-texttype': 'markdown'
								}
							},
							img: {
								component: ({ children, ...props }) => <SA.MarkdownImage {...props}>{children}</SA.MarkdownImage>
							}
						}
					})}

					{startQuestion.type === 'Open' && ( // Open
						<SQ.Comment onChange={handleSetComment} disabled={props.disabled}></SQ.Comment>
					)}
					{(startQuestion.type === 'Normal' || startQuestion.type === 'Open') && ( // Normal or Open
						<SQ.SurveyAnswerButtons>
							{startQuestion.answers.map((answer) => (
								<SQ.SurveyAnswerButton
									key={answer.id}
									disabled={props.disabled}
									state={props.answer === answer.id ? true : false}
									onClick={() => handleActivityResponse(answer.id)}
								>
									{answer.title}
								</SQ.SurveyAnswerButton>
							))}
						</SQ.SurveyAnswerButtons>
					)}
				</>
			)}
		</>
	);
};

export default SurveyIntro;
