import styled from 'styled-components';

const S = {};

S.Stars = styled.div.attrs({ 'data-minddclass': 'stars' })`
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	margin: 1em 0 2em 0;
`;

S.Star = styled.div.attrs({ 'data-minddclass': 'star' })`
	border: 0;
	background: none;
	cursor: pointer;
	margin-inline-end: 20px;
	:focus,
	:hover {
		outline: none;
		box-shadow: unset;
	}

	path {
		transition: fill 0.15s;
		stroke: ${(props) => props.theme.colors.accent};
	}

	:hover,
	&:hover ~ & {
		path {
			fill: ${(props) => props.theme.colors.accent};
		}
	}

	@media ${(props) => props.theme.screens.small} {
		padding: 10px 10px;
	}
`;

S.Comment = styled.textarea.attrs({ 'data-minddclass': 'comment' })`
	display: block;
	border-radius: 4px;
	width: 100%;
	max-width: 400px;
	height: 200px;
	padding: 10px;
	margin-bottom: 1em;
	&:focus {
		outline: none;
	}
`;

export default S;
