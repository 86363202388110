import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FormContainer = styled.div`
	&&& {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		column-gap: 2rem;
	}
`;

const FormGroup = styled.div`
	&&& {
		display: flex;
		flex-direction: column;
		flex-basis: 100%;

		@media ${(props) => props.theme.screens.medium} {
			flex: 1;
		}
	}
`;

const ButtonContainer = styled.div`
	&&& {
		position: relative;
		display: flex;
		align-items: center;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
`;
const PhoneIcon = styled(FontAwesomeIcon)`
	color: ${(props) => props.theme.colors.accent};
	margin-inline-end: 1.5rem;

	width: 1.5rem;
	height: 1.5rem;
`;
const SuccessMessageIcon = styled(FontAwesomeIcon)`
	color: ${(props) => props.theme.colors.accent};
	margin-inline-end: 1.5rem;

	width: 1.5rem;
	height: 1.5rem;
`;
const SuccessMessageContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 1.5rem;
	margin: 1.5rem 0;
	background-color: ${(props) => props.theme.colors.accent}15;
`;

const SuccessLabel = styled.div``;

const S = {
	FormContainer,
	FormGroup,
	SuccessMessageContainer,
	SuccessMessageIcon,
	SuccessLabel,
	ButtonContainer,
	PhoneIcon
};

export default S;
