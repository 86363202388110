import React, { ChangeEvent, forwardRef, useEffect, useState } from 'react';

import S from './styled';

import Button from '../Button';

import { getLabel } from '../../helpers/constants/getLabels';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { calculateAgeFromDob } from '../../helpers/support/calculateAgeFromDob';
import { validateDob } from '../../helpers/support/validateDob';
import { CalculatedAge, DateOfBirth } from '../../models';

type ValidationSatus = '' | 'valid' | 'invalid' | 'maxAgeExceded';

type BirthdayInputProps = {
	confirmButtonClassName: string;
	disabled: boolean;
	step: number;
	title: string;
	answer?: { dob?: DateOfBirth; age: CalculatedAge };
	handleActivityResponse: (answer: { dob?: DateOfBirth; age: CalculatedAge }) => void;
};

const BirthdayInput = forwardRef<HTMLInputElement, BirthdayInputProps>((props: BirthdayInputProps, ref) => {
	const [{ profile, settings }, dispatch] = useStateContext();

	const defaultDoB: DateOfBirth = props.answer && props.answer.dob ? props.answer.dob : { day: undefined, month: undefined, year: undefined };
	const [dob, setDob] = useState<DateOfBirth>(settings.forceInitialStateEmpty ? defaultDoB : profile.dob);
	const [age, setAge] = useState<CalculatedAge>();

	const [validationStatus, setvalidationStatus] = useState<ValidationSatus>('');

	const handleOnChange = (field: ChangeEvent<HTMLInputElement>) => {
		const { name, value, max, maxLength } = field.target;

		// Rather guard possible properties here then extend DateOfBirth model
		if (name !== 'day' && name !== 'month' && name !== 'year') {
			return;
		}

		const previousValue = dob[name] || '0';
		const currentValue = value.slice(0, field.target.maxLength).replace(/\D/, '');

		const previousInt = parseInt(previousValue);
		const currentInt = parseInt(currentValue);

		setDob({
			...dob,
			[name]: currentValue
		});

		if (field.target.nextElementSibling && field.target.nextElementSibling instanceof HTMLLabelElement) {
			// First check if numeric input's arrows are used
			// There is no event for this so we assume they are used when the value increments or decrements by 1
			if (currentInt === previousInt + 1 || currentInt === previousInt - 1) {
				// Focus next if current value equals the max value or the user increments by 1 while typing '01' (maxLength)
				if (currentInt === parseInt(max) || (previousValue === '0' && currentValue === '01')) {
					field.target.nextElementSibling.focus();
				}
				// Input using keyboard reaches maxLength
			} else if (value.length === maxLength) {
				field.target.nextElementSibling.focus();
			}
		}
	};

	const handleActivityResponse = () => {
		// Age only has a value if it is valid
		if (!age?.age) {
			setvalidationStatus('invalid');
			return;
		}

		if (age?.ageInYears && age?.ageInYears > settings.maxAge) {
			setvalidationStatus('maxAgeExceded');
			return;
		}

		props.handleActivityResponse({
			dob: dob,
			age: age
		});

		setvalidationStatus('valid');
	};

	useEffect(() => {
		const validDob = validateDob(dob);
		if (validDob) {
			const calculatedAge = calculateAgeFromDob(dob, settings.applicationTexts);
			if (calculatedAge) {
				setAge(calculatedAge);
				dispatch({
					type: 'updateProfile/Age',
					dob: dob,
					age: calculatedAge.age
				});

				if (calculatedAge.ageInYears > settings.maxAge) {
					setvalidationStatus('maxAgeExceded');
				} else {
					setvalidationStatus('valid');
				}
			} else {
				setvalidationStatus('invalid');
			}
		} else {
			// reset values
			setAge(undefined);
			dispatch({
				type: 'updateProfile/Age',
				dob: { day: null, month: null, year: null },
				age: null
			});
		}
	}, [dob, settings.applicationTexts, settings.maxAge, dispatch]);

	const maxYearInput = new Date().getFullYear();
	const minYearInput = settings.maxAge ? maxYearInput - settings.maxAge : 1900;

	return (
		<S.Form
			onSubmit={(e) => {
				e.preventDefault();
			}}
		>
			<S.FieldSet>
				<S.Legend>{props.title}</S.Legend>
				<S.Label htmlFor={`MINDD-Widget-${props.step}-AgeDayInput`}>{getLabel('WidgetAgeDayLabel', settings.applicationTexts, true)}</S.Label>
				<S.Input
					ref={ref}
					id={`MINDD-Widget-${props.step}-AgeDayInput`}
					disabled={props.disabled}
					autoComplete="off"
					name="day"
					maxLength={2}
					type="number"
					inputMode="numeric"
					pattern="[0-9]*"
					min="1"
					max="31"
					placeholder={getLabel('WidgetAgeDayPlaceholder', settings.applicationTexts, true)}
					value={dob.day ? dob.day : ''}
					onChange={handleOnChange}
				/>
				<S.Label htmlFor={`MINDD-Widget-${props.step}-AgeMonthInput`}>{getLabel('WidgetAgeMonthLabel', settings.applicationTexts, true)}</S.Label>
				<S.Input
					id={`MINDD-Widget-${props.step}-AgeMonthInput`}
					disabled={props.disabled}
					autoComplete="off"
					name="month"
					maxLength={2}
					type="number"
					inputMode="numeric"
					pattern="[0-9]*"
					min="1"
					max="12"
					placeholder={getLabel('WidgetAgeMonthPlaceholder', settings.applicationTexts, true)}
					value={dob.month ? dob.month : ''}
					onChange={handleOnChange}
				/>
				<S.Label htmlFor={`MINDD-Widget-${props.step}-AgeYearInput`}>{getLabel('WidgetAgeYearLabel', settings.applicationTexts, true)}</S.Label>
				<S.InputLarge
					id={`MINDD-Widget-${props.step}-AgeYearInput`}
					disabled={props.disabled}
					autoComplete="off"
					name="year"
					maxLength={4}
					type="number"
					inputMode="numeric"
					pattern="[0-9]*"
					min={minYearInput}
					max={maxYearInput}
					placeholder={getLabel('WidgetAgeYearPlaceholder', settings.applicationTexts, true)}
					value={dob.year ? dob.year : ''}
					onChange={handleOnChange}
				/>
			</S.FieldSet>
			{validationStatus === 'invalid' && <S.Error>{getLabel('WidgetAgeRequestError', settings.applicationTexts)}</S.Error>}
			{validationStatus === 'maxAgeExceded' && <S.Error>{getLabel('WidgetMaxAgeRequestError', settings.applicationTexts)}</S.Error>}
			<S.Row>
				<Button onClick={handleActivityResponse} state={props.answer ? true : false} disabled={props.disabled} className={props.confirmButtonClassName}>
					{age?.displayAge
						? `${getLabel('WidgetConfirmButton', settings.applicationTexts, true)} (${age.displayAge})`
						: `${getLabel('WidgetConfirmButton', settings.applicationTexts, true)}`}
				</Button>
			</S.Row>
		</S.Form>
	);
});

BirthdayInput.displayName = 'BirthdayInput';
export default BirthdayInput;
