import React, { useCallback, useEffect } from 'react';

import { default as SA } from '../Activity/styled';

import AgeYearInput from './AgeYearInput';
import BirthdayInput from './BirthdayInput';
import ActivityWelcome from '../ActivityWelcome';
import Logo from '../Logo';
import TermsOfUse from '../TermsOfUse';

import ActivityBubbleTitle from '../ActivityBubbleTitle';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallPostTriageProgress } from '../../helpers/services';
import { createDateForDob } from '../../helpers/support/createDateForDob';
import { getLabel } from '../../helpers/constants/getLabels';
import useWidgetIsNarrow from '../../helpers/hooks/useWidgetIsNarrow';

const ActivityAge = (props) => {
	const [{ settings, session }] = useStateContext();

	const confirmButtonClassName = props.modalOpen ? null : 'mindd-widget-startbutton';

	const focusLastActivity = useCallback(
		(node) => {
			if (node && props.isLastActivity && props.modalOpen) {
				setTimeout(() => {
					node.focus();
					//node.select();
				}, 50);
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const handleActivityResponse = async (answer) => {
		await props.handleActivityResponse(answer);
	};

	const widgetIsNarrow = useWidgetIsNarrow();

	useEffect(() => {
		if (session && props.answer && props.answer.dob && props.answer.age) {
			const birthdate = createDateForDob(props.answer.dob);

			CallPostTriageProgress(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				updateAge: true,
				age: props.answer.age.age,
				birthdate: birthdate
			});
		}
	}, [props.answer, session, settings.ApiKey]);

	return (
		<SA.ActivityGroup>
			{props.modalOpen && props.isFirstActivity && <TermsOfUse modalOpen={props.modalOpen} />}
			{(settings.widget_showWelcomeText || props.modalOpen) && props.isFirstActivity && <ActivityWelcome modalOpen={props.modalOpen} />}

			<SA.ActivityBubble>
				<ActivityBubbleTitle
					title={settings.askForBirthday ? props.title : getLabel('QuestionWhatAgeInYears', settings.applicationTexts, true)}
					info={settings.askForBirthday ? props.info : getLabel('QuestionWhatAgeInYearsInfo', settings.applicationTexts, true, true)}
					isFirstActivity={props.isFirstActivity}
					isLastActivity={props.isLastActivity}
					modalOpen={props.modalOpen}
					disabled={props.disabled}
					limitHeight={!props.modalOpen}
					titleAriaHidden={true}
					widgetIsNarrow={widgetIsNarrow}
				/>
				{settings.askForBirthday ? (
					<BirthdayInput
						ref={focusLastActivity}
						confirmButtonClassName={confirmButtonClassName}
						disabled={props.disabled}
						step={props.step}
						title={props.title}
						answer={props.answer}
						handleActivityResponse={handleActivityResponse}
					/>
				) : (
					<AgeYearInput
						ref={focusLastActivity}
						confirmButtonClassName={confirmButtonClassName}
						disabled={props.disabled}
						step={props.step}
						title={props.title}
						answer={props.answer}
						handleActivityResponse={handleActivityResponse}
					/>
				)}
				{!props.modalOpen && !settings.hide_footer && props.isFirstActivity && <Logo align="end" size="145" />}
			</SA.ActivityBubble>
			{!props.modalOpen && props.isFirstActivity && <TermsOfUse modalOpen={props.modalOpen} />}
		</SA.ActivityGroup>
	);
};

export default ActivityAge;
