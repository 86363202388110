import { ActivityStep, ApplicationText } from '../../models';
import { getLabel } from './getLabels';

export const translateConversation = (conversation: ActivityStep[], labels: ApplicationText[]): ActivityStep[] => {
	const newConversation = conversation.map((step) => {
		const newStep = { ...step };

		if (newStep.type === 'searchTriage') {
			newStep.title = getLabel('QuestionWhatSearchTriage', labels);
		} else if (newStep.type === 'searchComplaintSelector') {
			newStep.title = getLabel('QuestionWhatTriage', labels);
		} else if (newStep.type === 'bodyAreaSelector') {
			newStep.title = getLabel('QuestionWhatBodyPart', labels);
			newStep.info = getLabel('QuestionWhatBodyPartInfo', labels, false, true);
		} else if (newStep.type === 'genderSelector') {
			newStep.title = getLabel('QuestionWhatGender', labels);
			newStep.info = getLabel('QuestionWhatGenderInfo', labels, false, true);
		} else if (newStep.type === 'ageSelector') {
			newStep.title = getLabel('QuestionWhatAge', labels);
			newStep.info = getLabel('QuestionWhatAgeInfo', labels, false, true);
		} else if (newStep.type === 'complaintSelector') {
			newStep.title = getLabel('QuestionWhatBodyPart', labels);
			newStep.info = getLabel('QuestionWhatBodyPartInfo', labels, false, true);
		}

		return newStep;
	});

	return newConversation;
};
