import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { LanguageInput, SessionInput } from './BaseInput';
import { SearchTriageHit } from '../../models';

export type SearchTriagesInput = SessionInput &
	LanguageInput & {
		searchTitle?: string;
	};

export type SearchTriagesOutput = {
	hits: SearchTriageHit[];
};

async function CallSearchTriages(apiKey: string, input: SearchTriagesInput): Promise<SearchTriageHit[]> {
	try {
		const { data } = await minddApiAxios.post<SearchTriagesOutput>('SearchTriages?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return data.hits;
	} catch (error) {
		logAxiosError(error, 'CallSearchTriages');
	}

	return [];
}

export { CallSearchTriages };
