import { RootContext } from '../../models';
import { InitialProfile } from '../constants/initialState';
import { RootReducerAction } from './rootReducerActions';

export type RootReducerType = (state: RootContext, action: RootReducerAction) => RootContext;

export const rootReducer: RootReducerType = (state: RootContext, action: RootReducerAction): RootContext => {
	switch (action.type) {
		case 'updateSettings':
			return {
				...state,
				settings: action.settings
			};
		case 'updateLanguages':
			return {
				...state,
				languages: action.languages
			};
		case 'updateProfile':
			return {
				...state,
				profile: action.profile
			};
		case 'updateProfile/Age':
			return {
				...state,
				profile: {
					...state.profile,
					dob: action.dob,
					age: action.age
				}
			};
		case 'updateProfile/Gender':
			return {
				...state,
				profile: {
					...state.profile,
					gender: action.gender
				}
			};
		case 'updateProfile/reset': {
			const newProfile = InitialProfile(action.forceInitialStateEmpty);
			const currentProfile = state.profile;

			if (!action.forceInitialStateEmpty) {
				newProfile.dob = currentProfile.dob;
				newProfile.age = currentProfile.age;
			}
			return {
				...state,
				profile: newProfile
			};
		}
		case 'session/update':
		case 'updateSession':
			return {
				...state,
				session: action.session ?? { id: '', token: '' }
			};

		case 'updateModal':
			return {
				...state,
				modal: action.modal,
				conversation: action.conversation ? action.conversation : state.conversation
			};
		case 'conversation/setStepAnswer': {
			// react object update vs statechange. a new array/object has to be created to trigger statechange.
			const conversationSetStepAnswer = [...state.conversation];
			if (action.stepId) {
				conversationSetStepAnswer.forEach((item) => {
					if (item.id === action.stepId) {
						item.answer = action.answer;
					}
				});
			}
			return {
				...state,
				conversation: conversationSetStepAnswer
			};
		}
		case 'conversation/addStep': {
			// react object update vs statechange. a new array/object has to be created to trigger statechange.
			const conversationWithStep = [
				...state.conversation,
				{
					...action.step,
					step: state.conversation.length + 1
				}
			];
			return {
				...state,
				conversation: conversationWithStep
			};
		}
		case 'updateConversationStep': {
			// react object update vs statechange. a new array/object has to be created to trigger statechange.
			const conversationUpdateStep = [...state.conversation];
			if (action.stepIndex >= 0 && action.stepIndex < conversationUpdateStep.length) {
				conversationUpdateStep[action.stepIndex] = action.step;
			}
			return {
				...state,
				conversation: conversationUpdateStep
			};
		}
		case 'updateConversationActivities': {
			// react object update vs statechange. a new array/object has to be created to trigger statechange.
			const newConversation = (conversation = []) => [...action.conversation];
			return {
				...state,
				conversation: newConversation()
			};
		}
		case 'updateRefs':
			return {
				...state,
				refs: action.refs
			};
		default:
			return state;
	}
};
