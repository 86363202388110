import styled from 'styled-components';

interface EndButtonsContainerProps {
	justifyContent: 'space-between' | 'flex-end';
}

const EndButtonsContainer = styled.div.attrs({ 'data-minddclass': 'endbuttonscontainer' })<EndButtonsContainerProps>`
	//margin: 1rem 0;
	display: flex;
	justify-content: ${(props) => props.justifyContent};
`;

const S = {
	EndButtonsContainer
};

export default S;
