import React, { ChangeEventHandler, useEffect, useRef } from 'react';

import { default as SP } from '../Procedure/styled';

interface Props {
	disabled?: boolean;
	value?: string;
	onChange: ChangeEventHandler<HTMLInputElement>;
}

const InputNumber = (props: Props) => {
	const inputNumberField = useRef<HTMLInputElement>(null);

	const setInputFilter = (textbox: HTMLInputElement, inputFilter: (value: string) => boolean) => {
		let oldValue: string;
		let oldSelectionStart: number | null;
		let oldSelectionEnd: number | null;

		['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function (event) {
			textbox.addEventListener(event, function () {
				if (inputFilter(textbox.value)) {
					oldValue = textbox.value;
					oldSelectionStart = textbox.selectionStart;
					oldSelectionEnd = textbox.selectionEnd;
				} else if (oldValue) {
					textbox.value = oldValue;
					textbox.setSelectionRange(oldSelectionStart, oldSelectionEnd);
				} else {
					textbox.value = '';
				}
			});
		});
	};

	useEffect(() => {
		if (inputNumberField && inputNumberField.current) {
			setInputFilter(inputNumberField.current, function (value) {
				return /^\d*(?:\.|,)?\d*$/.test(value);
			});
		}
	}, [inputNumberField]);

	return (
		<SP.Field
			ref={inputNumberField}
			type="text"
			inputMode="decimal"
			pattern="\d*(?:\.|,)?\d*"
			maxLength={7}
			autoComplete="off"
			disabled={props.disabled}
			onChange={props.onChange}
		/>
	);
};

export default InputNumber;
