import styled from 'styled-components';
import { default as SA } from '../Activity/styled';

const S = {};

// comes in place of the previous progress bar
S.CloseButtonBar = styled.div.attrs({ 'data-minddclass': 'closebuttonbar' })`
	display: flex;
	width: 100%;
	height: 38px;
	flex-wrap: nowrap;
	padding-top: 5px;
	padding-left: 12px;
	padding-right: 30px;
	position: absolute;
	left: 0px;
	z-index: 10000;
	top: -38px;
	background-color: ${(props) => (props.hasAdvice ? props.theme.colors.advicebackground : props.theme.colors.appbackground)};

	// If there is no header, this bar is on top. So the border-radius is applied here. Only for desktop.
	@media ${(props) => props.theme.screens.medium} {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
`;

// When the padding-top changes here, in Conversation/index.js, the verticalOffSet also must be changed
S.Widget = styled.div.attrs((props) => ({ 'data-minddclass': 'widget', dir: props.theme.isRtl ? 'rtl' : 'ltr' }))`
	&&& {
		color: #000;
		border: 0;
		padding: 10px;
		height: 100%;
		width: 100%;
		background-color: ${(props) => props.widgetBackground};

		// was 75px, removed progress bar with height 38px, 75-38 = 37px (same for mobile and desktop)
		padding-top: ${(props) => props.hasHeader && '37px'};

		${(props) => props.modalOpen && 'overflow-y: scroll;'};
		display: flex;
		flex-direction: row;
		justify-content: center;

		// Provides some horizontal padding for desktop to make it more visually appealing.
		@media ${(props) => props.theme.screens.medium} {
			padding-left: 30px;
			padding-right: 30px;
		}

		// note: when we use styled components version 6,
		// container-type: inline-size
		// could be added here
	}
`;

S.WidgetHeader = styled(SA.VisuallyHidden).attrs({ as: 'h1' })``;

S.Header = styled.div.attrs({ 'data-minddclass': 'widgetheader' })`
	position: absolute;
	z-index: 999999;
	background: #fff;
	width: 100%;
	height: 60px;
	border-bottom: 1px solid rgba(96, 96, 106, 0.25);
	box-sizing: border-box;
	justify-content: center;
	margin: auto;
	top: -60px; // was -98px, -98 + 38 (height removed progress bar) = -60
	display: flex;
	@media ${(props) => props.theme.screens.small} {
		width: 100%;
	}

	// Apply rounded borders to the header if it's present (otherwise it is applied to the progress bar).
	@media ${(props) => props.theme.screens.medium} {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
`;

S.HeaderInner = styled.div.attrs({ 'data-minddclass': 'headerinner' })`
	width: 100%;
	max-width: ${(props) => props.theme.sizes.maxWidth}px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 5px 10px;

	@media ${(props) => props.theme.screens.small} {
	}
`;

S.HeaderInnerLogo = styled.div.attrs({ 'data-minddclass': 'headerinnerlogo' })`
	display: block;
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	img {
		max-width: 100%;
		max-height: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
`;

S.HeaderInnerPhone = styled.div.attrs({ 'data-minddclass': 'headerinnerphone' })`
	margin-inline-start: 1em;
	display: flex;
	align-items: center;
	min-width: 140px;
	@media ${(props) => props.theme.screens.small} {
		margin-inline-start: 0;
		margin-top: 0.5em;
	}
	a {
		background: transparent !important;
		border: 0 !important;
		color: ${(props) => props.theme.colors.accent} !important;
		:hover {
			background: transparent !important;
			border: 0 !important;
			color: ${(props) => props.theme.colors.accent} !important;
		}
	}

	// for desktop, move phone number to the left so there is some spacing with the cross.
	@media ${(props) => props.theme.screens.medium} {
		margin-right: 2rem !important;
	}
`;

S.PhoneIcon = styled.div.attrs({ 'data-minddclass': 'phoneicon' })`
	display: inline-block;
	margin-inline-end: 0.5rem;
	svg path {
		fill: ${(props) => props.theme.colors.accent};
	}
`;
S.PhoneLabel = styled.div.attrs({ 'data-minddclass': 'phonelabel' })`
	display: inline-block;
`;

S.Body = styled.div.attrs({ 'data-minddclass': 'widgetbody' })`
	&&& {
		/* color: ${(props) => props.theme.colors.background}; */
		height: 100%;
		width: 100%;
		max-width: ${(props) => props.theme.sizes.maxWidth + 'px'};
	}
`;
/*
S.Footer = styled.div.attrs({ 'data-minddclass': 'widgetfooter' })`
	position: absolute;
	z-index: 999999;
	bottom: 0;

	background: #fff;
	width: calc(100% - 1em);

	border-top: 1px solid rgba(96, 96, 106, 0.25);
	box-sizing: border-box;

	margin: auto;

	display: flex;
	justify-content: center;
	align-items: center;

	@media ${(props) => props.theme.screens.small} {
		width: 100%;
		position: fixed;
	}
`;

S.FooterInner = styled.div.attrs({ 'data-minddclass': 'footerinner' })`
	width: 100%;
	max-width: ${(props) => props.theme.sizes.maxWidth}px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 10px;

	@media ${(props) => props.theme.screens.small} {
		flex-direction: column;
		padding: 1rem 1rem;
		align-items: flex-start;
		justify-content: center;
		width: 100%;
	}
`; */
export default S;
