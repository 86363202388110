import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';

import { ThemeContext } from 'styled-components';

import SVG from 'react-inlinesvg';
import ReactTooltip from 'react-tooltip';

import { default as SA } from '../Activity/styled';
import S from './styled';

import ActivityBubbleTitle from '../ActivityBubbleTitle';
import Button from '../Button';
import Loader from '../Loader';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallGetBodyAreas, CallPostTriageProgress } from '../../helpers/services';
import { getLabel } from '../../helpers/constants/getLabels';

const ActivityBodyArea = (props) => {
	const [{ profile, settings, session }, dispatch] = useStateContext();
	const themeContext = useContext(ThemeContext);
	const svgRef = useRef();

	const { handleActivityResponse } = props;

	const [bodySide, setBodySide] = useState(true);
	const [svg, setSvg] = useState({
		front: null,
		back: null
	});

	useEffect(() => {
		const fetchBodyareas = async () => {
			let bodyAreaIds = [];
			if (props.triageGroup !== null && props.triageGroup.triages !== null) {
				bodyAreaIds = props.triageGroup.triages.flatMap((t) => t.bodyAreaIds);
				bodyAreaIds = [...new Set(bodyAreaIds)]; // Only want unique values
			}

			const bodyAreas = await CallGetBodyAreas(settings.ApiKey, {
				gender: null,
				age: 0,
				bodyAreaIds: bodyAreaIds,
				languageCode: settings.selectedLanguage.code
			});

			setSvg({
				front: bodyAreas.bodyImageSVGs.filter((svg) => {
					return svg.perspective === 'Voor';
				})[0].svg,
				back: bodyAreas.bodyImageSVGs.filter((svg) => {
					return svg.perspective === 'Achter';
				})[0].svg
			});

			ReactTooltip.rebuild();
		};
		fetchBodyareas();
	}, [props.triageGroup, settings.ApiKey, settings.selectedLanguage.code]);

	const processBodyAreaSelect = useCallback(
		async (bodyAreaElement, svgElement) => {
			const bodyAreaIdString = bodyAreaElement.getAttribute('data-bodyareaid') || null;
			if (!bodyAreaIdString || bodyAreaElement.length === 0) {
				return;
			}

			const bodyAreaId = parseInt(bodyAreaIdString);
			if (bodyAreaId) {
				try {
					svgElement.querySelector('[data-selected]').removeAttribute('data-selected');
					const ariaPressedelements = svgElement.querySelectorAll('[aria-pressed="true"]');
					ariaPressedelements.forEach((el) => el.setAttribute('aria-pressed', 'false'));
				} catch (e) {
					// We ignore the error
				}

				bodyAreaElement.setAttribute('data-selected', 'true');
				bodyAreaElement.setAttribute('aria-pressed', 'true');

				await handleActivityResponse({ bodyAreaId: bodyAreaId });

				CallPostTriageProgress(settings.ApiKey, {
					sessionId: session.id,
					sessionToken: session.token,
					updateBodyArea: true,
					bodyAreaId: bodyAreaId
				});

				dispatch({
					type: 'updateProfile',
					profile: {
						...profile,
						bodypart: bodyAreaId
					}
				});
			}
		},
		[dispatch, handleActivityResponse, profile, session, settings.ApiKey]
	);

	// When control enabled make sure the tabindex on the SVG is 0, else -1 to disable tabbing
	// When control enabled attach a document event listener to keydown so we can select the body area using keyboard
	// Also use event listener on keydown to hide the tooltip on esc
	useEffect(() => {
		if (svgRef.current) {
			const tabIndexElements = svgRef.current.querySelectorAll('svg [tabindex]');
			if (tabIndexElements && tabIndexElements.length > 0) {
				const tabIndex = props.disabled ? '-1' : '0';
				tabIndexElements.forEach((el) => el.setAttribute('tabindex', tabIndex));
			}
		}

		if (!props.disabled) {
			const documentKeyDown = async (event) => {
				if (event.key === 'Escape' || event.key === 'Esc') {
					ReactTooltip.hide();
				} else if (event.key === 'Enter' || event.key === 'Spacebar' || event.key === ' ') {
					const bodyAreaElement = event.target.closest('[data-bodyareaid]');
					const svgElement = event.target.closest('svg');
					if (bodyAreaElement && svgElement) {
						await processBodyAreaSelect(bodyAreaElement, svgElement);

						event.preventDefault();
					}
				}
			};

			document.addEventListener('keydown', documentKeyDown);

			return () => {
				document.removeEventListener('keydown', documentKeyDown);
			};
		}
	}, [props.disabled, processBodyAreaSelect]);

	const handleBodyAreaSelect = async (event) => {
		event.preventDefault();

		const bodyAreaElement = event.target.closest('[data-bodyareaid]');
		const svgElement = event.target.closest('svg');
		if (bodyAreaElement && svgElement) {
			await processBodyAreaSelect(bodyAreaElement, event.currentTarget);
		}
	};

	const handleBodySideChange = () => {
		setBodySide(!bodySide);
	};

	const preProcesSvg = (code) => {
		// replace color codes with accent
		code = code.replace(/fill="#00b2ff"/g, `fill="${themeContext.colors.accent}"`);
		code = code.replace(/data-bodyareaid="(\d+)"/gm, '$& tabindex="0" role="button" aria-pressed="false"');
		code = code.replace(/data-tip="(.*?)"/gm, '$& aria-label="$1"');
		return code;
	};

	return (
		<SA.ActivityBubble>
			<ActivityBubbleTitle
				title={props.title}
				info={props.info}
				isFirstActivity={props.isFirstActivity}
				isLastActivity={props.isLastActivity}
				modalOpen={props.modalOpen}
				disabled={props.disabled}
			/>

			<S.ActivityBodyAreaWrapper>
				<S.Body disabled={props.disabled} numberOfComplaints={0}>
					{svg.front && svg.back ? (
						<SVG
							innerRef={svgRef}
							onError={(error) => console.log(error.message)}
							onLoad={ReactTooltip.rebuild}
							preProcessor={preProcesSvg}
							src={bodySide === true ? svg.front : svg.back}
							onClick={handleBodyAreaSelect}
							onTouchEnd={handleBodyAreaSelect}
						/>
					) : (
						<S.SvgLoader>
							<Loader />
						</S.SvgLoader>
					)}

					<S.Switch>
						<Button
							disabled={props.disabled}
							onClick={handleBodySideChange}
							title={getLabel('WidgetBodySwitchButtonTitle', settings.applicationTexts, true)}
						>
							<svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
								<path
									fill="#666"
									d="M12,5C16.97,5 21,7.69 21,11C21,12.68 19.96,14.2 18.29,15.29C19.36,14.42 20,13.32 20,12.13C20,9.29 16.42,7 12,7V10L8,6L12,2V5M12,19C7.03,19 3,16.31 3,13C3,11.32 4.04,9.8 5.71,8.71C4.64,9.58 4,10.68 4,11.88C4,14.71 7.58,17 12,17V14L16,18L12,22V19Z"
								/>
							</svg>
						</Button>
					</S.Switch>
				</S.Body>

				{!props.disabled && <ReactTooltip globalEventOff="click" disableInternalStyle={true} />}
			</S.ActivityBodyAreaWrapper>
		</SA.ActivityBubble>
	);
};

export default ActivityBodyArea;
