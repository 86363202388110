import { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useStateContext } from './useStateContext';

// Used for better widget display at https://huisartsenpraktijkdebrink.uwartsonline.nl/
// At this website, for desktop, the widget container is very narrow.
// Note: this hook is a temporary (?) solution.
// When we use styled components version 6, CSS container queries can be used instead of ResizeObserver.
// Note: widgetIsNarrow is always true for mobile, but it is only used for desktop.
const useWidgetIsNarrow = (): boolean => {
	const [{ refs }] = useStateContext();
	const [isNarrow, setIsNarrow] = useState<boolean>(false);
	const themeContext = useContext(ThemeContext);
	const narrowBreakpoint = themeContext.breakpoints.narrow;

	useEffect(() => {
		if (refs.widget && refs.widget.current) {
			const widgetElement = refs.widget.current;

			const resizeObserver = new ResizeObserver((entries) => {
				for (const entry of entries) {
					const { width } = entry.contentRect;
					setIsNarrow(width <= narrowBreakpoint);
				}
			});

			resizeObserver.observe(widgetElement);

			return () => {
				resizeObserver.unobserve(widgetElement);
			};
		}
	}, [refs.widget, narrowBreakpoint]);

	return isNarrow;
};

export default useWidgetIsNarrow;
