import styled from 'styled-components';
import { default as SA } from '../Activity/styled';

const S = {};

S.Complaints = styled.div.attrs({ 'data-minddclass': 'complaints' })``;

S.ComplaintsList = styled(SA.ComplaintsList)``;

S.Complaint = styled(SA.Complaint)``;

S.ComplaintButton = styled(SA.ComplaintButton)``;

export default S;
