import React, { useState, useCallback, useEffect, useRef } from 'react';

import { default as SA } from '../Activity/styled';
import { default as SP } from '../Procedure/styled';

import Button from '../Button';
import ActivityEndActions from '../ActivityEndActions';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import { CallSurveyPostInquiryForm } from '../../helpers/services';

const SurveyInquiry = (props) => {
	const [{ profile, settings, session }] = useStateContext();

	const [sendStatus, setSendStatus] = useState(false);
	const [isDisabled, setIsDisabled] = useState(props.disabled);
	const [isSending, setIsSending] = useState(false);
	const [validationStatus, setvalidationStatus] = useState(null);
	const [fields, setFields] = useState({
		SurveyEmail: null,
		SurveyTel: null,
		SurveyConsent: null
	});

	const { question } = props;

	const focusLastActivity = useCallback(
		(node) => {
			if (node && props.isLastActivity && props.modalOpen) {
				setTimeout(() => {
					node.focus();
					node.select();
				}, 50);
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const handleChangeFormField = (field) => {
		if (field.target.type === 'checkbox') {
			setFields({
				...fields,
				[field.target.name]: field.target.checked ? true : null
			});
		} else {
			setFields({
				...fields,
				[field.target.name]: field.target.value === '' || field.target.value.trim() === '' ? null : field.target.value
			});
		}
	};

	const handleSendData = async () => {
		setIsDisabled(true);
		setIsSending(true);
		let valid = true;

		valid = session === null ? false : valid;
		valid = fields.SurveyEmail === null && question.askForEmailAddress ? false : valid;
		valid = (fields.SurveyTel === null || !(isNumberOfChars(fields.SurveyTel) === 10)) && question.askForPhoneNumber ? false : valid;
		valid = fields.SurveyConsent === null ? false : valid;

		if (valid) {
			setvalidationStatus(true);

			const result = await CallSurveyPostInquiryForm(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				email: fields.SurveyEmail,
				phoneNumber: fields.SurveyTel,
				userAgreedToPrivacyStatement: fields.SurveyConsent
			});

			if (result) {
				setSendStatus(true);
				setIsSending(false);
				setIsDisabled(false);

				props.handleActivityResponse({ ...props.answer, inquirySend: true });
				props.setBackButtonStatus(true);
			}
		} else {
			setvalidationStatus(false);
			setIsSending(false);
			setIsDisabled(false);
		}
	};

	const handleActivityEndActions = async (answer) => {
		switch (answer.message) {
			case 'feedback':
				await props.handleActivityResponse({ ...props.answer, isFeedbackStarted: true });
				break;
			// no default
		}
	};

	const isNumberOfChars = (input) => {
		if (!input) return 0;

		return input.replace(/( )/g, '').replace(/(\.)/g, '').length;
	};

	useEffect(() => {
		setFields({
			...fields,
			SurveyTel: profile.phoneNumber
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<SA.ActivityGroup>
			<SA.ActivityBubble>
				{!sendStatus && (
					<>
						{renderMarkdown(settings.survey.labels.formIntro)}
						<SP.Form>
							{question.askForEmailAddress && (
								<SP.Col>
									<InputText
										ref={focusLastActivity}
										onChange={handleChangeFormField}
										name="SurveyEmail"
										label={getLabel('WidgetFormLabelEmail', settings.applicationTexts, true)}
										defaultValue={fields.SurveyEmail}
									></InputText>
								</SP.Col>
							)}
							{question.askForPhoneNumber && (
								<SP.Col>
									<InputNumber
										onChange={handleChangeFormField}
										name="SurveyTel"
										label={getLabel('WidgetFormLabelTel', settings.applicationTexts, true)}
										defaultValue={fields.SurveyTel}
									></InputNumber>
								</SP.Col>
							)}

							<SP.Row>
								<InputCheckBox
									onChange={handleChangeFormField}
									name="SurveyConsent"
									label={renderMarkdown(settings.survey.labels.formConsent)}
									type="checkbox"
								></InputCheckBox>
							</SP.Row>

							<SP.RowClean>
								{validationStatus === false && <SP.Error>{renderMarkdown(settings.survey.labels.formError)}</SP.Error>}
								<SA.ActivityActions>
									<Button onClick={handleSendData} disabled={isDisabled}>
										{isSending
											? getLabel('WidgetSendingButton', settings.applicationTexts, true)
											: getLabel('WidgetSendButton', settings.applicationTexts, true)}
									</Button>
								</SA.ActivityActions>
							</SP.RowClean>
						</SP.Form>
					</>
				)}
				{sendStatus && <SP.Succes>{renderMarkdown(settings.survey.labels.formSuccess)}</SP.Succes>}
			</SA.ActivityBubble>
			{sendStatus && (
				/* #2 follow up feedback/opnieuw */
				<SA.ActivityBubble>
					<ActivityEndActions
						showFeedbackAction={false}
						hideDownloadButton={settings.downloadConversationDisabled}
						handleActivityResponse={handleActivityEndActions}
					/>
				</SA.ActivityBubble>
			)}
		</SA.ActivityGroup>
	);
};

export default SurveyInquiry;

const InputCheckBox = (props) => {
	return (
		<React.Fragment>
			<SP.RowConsent>
				<SP.Field id={props.name} {...props} />
				<SP.LabelInline htmlFor={props.name}>{props.label}</SP.LabelInline>
			</SP.RowConsent>
		</React.Fragment>
	);
};

const InputText = React.forwardRef((props, ref) => (
	<React.Fragment>
		<SP.Label htmlFor={props.name}>{props.label}</SP.Label>
		<SP.Field ref={ref} type="text" {...props} autoComplete="off" />
	</React.Fragment>
));
InputText.displayName = 'InputText';

const InputNumber = (props) => {
	const inputNumberField = useRef();
	const setInputFilter = (textbox, inputFilter) => {
		['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function (event) {
			textbox.current.addEventListener(event, function () {
				if (inputFilter(this.value)) {
					this.oldValue = this.value;
					this.oldSelectionStart = this.selectionStart;
					this.oldSelectionEnd = this.selectionEnd;
				} else if (Object.prototype.hasOwnProperty.call(this, 'oldValue')) {
					this.value = this.oldValue;
					this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
				} else {
					this.value = '';
				}
			});
		});
	};

	useEffect(() => {
		setInputFilter(inputNumberField, function (value) {
			return /^[0-9]*$/.test(value);
		});
	}, []);

	return (
		<React.Fragment>
			<SP.Label htmlFor={props.name}>{props.label}</SP.Label>
			<SP.Field ref={inputNumberField} type="text" pattern="[0-9]*" {...props} autoComplete="off" />
		</React.Fragment>
	);
};
