import styled from 'styled-components';
import Button, { IconButton } from '../Button';

import { default as SA } from '../Activity/styled';

const S = {};

S.ActivityGroup = styled(SA.ActivityGroup).attrs({ 'data-minddclass': 'adviceactivitygroup' })`
	${SA.Activity}:last-child & {
		padding-bottom: 160px;
	}
`;

S.ActivityBubble = styled(SA.ActivityBubble)`
	&&& {
		max-height: none;
	}
`;

S.ActivityBubbleHeader = styled.div.attrs({ 'data-minddclass': 'activitybubbleheader' })`
	&&& {
		display: flex;
		flex-direction: column;
		flex: 0 1 auto;
		p {
			margin: 0px;
		}
	}
`;

S.ActivityActionsContactButtons = styled.div.attrs({ 'data-minddclass': 'activityactionscontactbuttons' })`
	&&& {
		margin: 1rem 0;
		display: flex;
		justify-content: start;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 0.5rem;				

		@media ${(props) => props.theme.screens.small} {
			gap: 0.25rem;						
			margin 0.75rem 0;
		}

		@media ${(props) => props.theme.screens.extraSmall} {
			flex-direction: column;
		}
	}
`;

S.ActivityActionsCallButtons = styled(SA.ActivityActions).attrs({ 'data-minddclass': 'activityactionscallbutton1' })`
	justify-content: start;
	flex-direction: row;
	margin-top: 0.75rem;
	margin-bottom: 0;
	flex-wrap: wrap;
	flex-direction: column;
	width: 33%;

	> * {
		margin: 0 0.5rem;
		margin-bottom: 1rem;
		i,
		em,
		b,
		strong {
			font-style: normal;
			font-weight: normal;
			white-space: nowrap; /* trick to prevent ie. phonenumber to not wrap*/
		}
	}
	@media ${(props) => props.theme.screens.small} {
		margin-top: 0.25rem;
		margin-bottom: 0rem;
		width: 100%;

		flex-direction: column;
		padding-inline-start: 0px;
		> * {
			margin: 0.5rem 0rem;
		}
	}
`;

S.ShowPhoneNumbersButton = styled(IconButton)`
	&&& {
		svg {
			transform: ${(props) => (props.rotateIcon ? 'rotate(-180deg)' : 'rotate(0deg)')};
			transition: all 0.3s ease-out;
		}
	}
`;

S.ActivityActionsCallButton = styled(Button)`
	&&& {
		margin: 0.25rem 0.5rem 0.25rem 0;

		@media ${(props) => props.theme.screens.small} {
			margin: 0.25rem 0 0.25rem 0;
		}
	}
`;

S.PhoneNumberPanelLabel = styled(SA.BubbleSubTitle)`
	&&& {
		margin-bottom: 1.5rem;
	}
`;

S.PhoneNumberPanel = styled.div`
	&&& {
		padding-left: 30px;
		padding-right: 30px;

		@media ${(props) => props.theme.screens.small} {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
`;

S.FullWidthContainer = styled.div`
	&&& {
		margin-left: -30px;
		margin-right: -30px;

		@media ${(props) => props.theme.screens.small} {
			margin-left: -15px;
			margin-right: -15px;
		}
	}
`;

S.PhoneNumberPanelContainer = styled(S.FullWidthContainer)`
	&&& {
		background-color: #f8f8f8;
	}
`;

S.IconBackground = styled.div.attrs({ 'data-minddclass': 'iconbackground' })`
	&&& {
		position: absolute;
		top: 0;
		inset-inline-start: 0;
		border-radius: 50%;
		width: 50px;
		height: 50px;
		background-color: ${(props) => props.theme.colors.accent};
		opacity: 0.15;
	}
`;

S.ProfileImageWrapper = styled.div.attrs({ 'data-minddclass': 'placeholderDokter' })`
	&&& {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

S.ProfileImage = styled.div.attrs({ 'data-minddclass': 'placeholderDokter' })`
	&&& {
		width: 120px;
		height: 120px;
		overflow: hidden;
		border-radius: 50%;
		background-image: url('${(props) => props.profileImage}');
		background-size: contain;
		background-repeat: no-repeat;
		background-origin: content-box;
		display: block;
		margin: 15px;
	}
`;
S.Icon = styled.div.attrs({ 'data-minddclass': 'icon' })`
	&&& {
		position: relative;
		display: flex;
		align-items: center;
		margin-bottom: 15px;

		@media ${(props) => props.theme.screens.small} {
			margin-bottom: 0px;
		}

		h2 {
			font-weight: bold !important;
			padding-left: 10px;
		}
	}
`;

S.IconLabel = styled.div.attrs({ 'data-minddclass': 'iconlabel' })`
	&&& {
		display: flex;
		flex-shrink: 0;
		position: relative;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}
`;

S.ExpandableTextBox = styled.div.attrs({})`
	&&& {
		border-radius: 8px;
		border: 1px solid #ddd;
		background: ${(props) => (props.expanded ? '#F8F8F8' : 'transparent')};
		height: auto;
		padding: 24px 24px; // verticaal horizontaal
		position: relative;
		overflow: hidden;
		.truncateText {
			display: ${(props) => (props.expanded ? null : '-webkit-box')};
			-webkit-line-clamp: ${(props) => (props.expanded ? null : '2')};
			-webkit-box-orient: ${(props) => (props.expanded ? null : 'vertical')};
			overflow: ${(props) => (props.expanded ? null : 'hidden')};
			margin-bottom: 10px;
		}
		button:not(.expandableTextButton) {
			display: ${(props) => (props.expanded ? null : 'none !important')};
		}
		button.expandableTextButton {
			outline: none !important;
		}
	}
`;

S.ExpandableTextBoxButton = styled.button.attrs({})`
	&&& {
		position: absolute;
		inset-inline-end: 0;
		background: none;
		border: none;
		position: absolute;
		top: 2px;
		right: 18px;
		color: black;
		padding: 15px 0px 15px 0px;
		svg {
			width: 30px;
			height: 30px;
		}

		&:focus {
			outline-color: transparent;
		}
		&:focus-visible {
			outline-color: black;
		}
	}
`;

S.ParacetamolAdviceBlock = styled.div.attrs({ 'data-minddclass': 'paracetamoladviceblock' })``;

S.DownloadLink = styled.a.attrs({ 'data-minddclass': 'downloadlink' })`
	color: ${(props) => props.theme.colors.accent} !important;
`;

export default S;
