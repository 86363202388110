import { useCallback } from 'react';

import { useResetWidget } from './useResetWidget';
import { useStateContext } from './useStateContext';
import { ActivityStepAdvice } from '../../models';

export const useToggleModal = () => {
	const [{ modal, conversation, session, settings }, dispatch] = useStateContext();
	const resetWidget = useResetWidget();

	return useCallback(async () => {
		const previousOpenValue = modal.open;
		const newOpenValue = !modal.open;

		let finished = false;
		if (conversation && conversation.length > 0) {
			finished = conversation.some((a) => a.type === 'advice');
		}

		const adviceUrgence = (conversation.find((step) => step.type === 'advice') as ActivityStepAdvice | undefined)?.advice.urgence ?? null;

		dispatch({
			type: 'updateModal',
			modal: {
				...modal,
				open: newOpenValue
			},
			conversation: newOpenValue === false ? [] : conversation
		});

		// We reset the profile when closing the model, so previous body area etc. is not remembered
		if (previousOpenValue) {
			// Is open now, so is closing
			await resetWidget(false);
		}

		if (newOpenValue) {
			if (settings.target instanceof Element) {
				settings.target.dispatchEvent(new CustomEvent('minddWidgetOpened', { bubbles: true }));
			}
			if (typeof settings.onWidgetOpened === 'function') {
				settings.onWidgetOpened.call(null);
			}
		} else {
			if (settings.target instanceof Element) {
				settings.target.dispatchEvent(
					new CustomEvent('minddWidgetClosed', { bubbles: true, detail: { finished: finished, sessionId: session.id, urgence: adviceUrgence } })
				);
			}
			if (typeof settings.onWidgetClosed === 'function') {
				settings.onWidgetClosed.call(null, finished, session.id, adviceUrgence);
			}
		}
	}, [conversation, dispatch, modal, session.id, resetWidget, settings.onWidgetOpened, settings.onWidgetClosed, settings.target]);
};
