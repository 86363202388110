import styled from 'styled-components';
import { default as SA } from '../Activity/styled';
import LanguageSelector from '../LanguageSelector';

const InternalLanguageSelector = styled(LanguageSelector)`
	inset-inline-end: 10px;
`;

const InfoPanelOverlay = styled.div.attrs({ 'data-minddclass': 'infopaneloverlay' })`
	&&& {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: transparent;
		z-index: 9997;
		[data-texttype='markdown'] {
			margin-bottom: 0;
			font-weight: 400;
			strong {
				font-size: 1.05em;
				font-weight: 600;
			}
		}
	}
`;
const infoPanelBackground = styled.div.attrs({ 'data-minddclass': 'infopanelbackground' })`
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 9998;
`;

const InfoPanel = styled.div.attrs({ 'data-minddclass': 'infopanel' })`
	&&& {
		z-index: 9999;
		position: absolute;
		left: 50%;
		top: 10px;
		transform: translate(-50%, 0%);
		padding: 30px 20px;
		width: 400px;
		max-width: 85%;
		background-color: #dbe3f4;
		border-radius: 10px;
		text-align: center;
		box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
		overflow-y: auto;
		max-height: 80vh;

		span {
			max-width: unset;
			text-align: unset;
			background-color: #dbe3f4;
			color: ${(props) => props.theme.colors.text};
		}

		[data-texttype='markdown'] {
			width: unset;
			max-width: unset;
			text-align: unset;
			background-color: #dbe3f4;
			color: ${(props) => props.theme.colors.text};
			margin-bottom: 0;
			font-weight: 400;

			strong {
				font-size: 1.05em;
				font-weight: 600;
			}
		}
	}
`;

const InfoCloseButton = styled.button.attrs({ 'data-minddclass': 'infoclosebutton' })`
	&&& {
		top: 5px;
		inset-inline-end: 10px;
		z-index: 9999;
		box-shadow: unset;
		position: absolute;
		background: transparent;
		padding: 5px 0px;
		border: 0;
		margin: 0;
		cursor: pointer;

		:focus,
		:hover {
			outline: none;
			box-shadow: unset;
			background: transparent !important;
		}

		:focus-visible {
			outline: 2px solid ${(props) => props.theme.colors.focus};
		}

		svg path {
			fill: ${(props) => props.theme.colors.text};
		}

		@media ${(props) => props.theme.screens.small} {
			padding: 5px 0px;
			filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.4));
			svg path {
				fill: ${(props) => props.theme.colors.text};
			}
		}
	}
`;

interface BubbleTitleWrapperProps {
	widgetIsNarrow?: boolean;
}

const BubbleTitleWrapper = styled.div<BubbleTitleWrapperProps>`
	display: flex;
	border-bottom: 2px solid ${(props) => props.theme.colors.appbackground};
	padding-bottom: 10px;

	@media ${(props) => props.theme.screens.medium} {
		${(props) =>
			props.widgetIsNarrow &&
			`
		flex-direction: column-reverse !important;
		gap: 32px !important;
		#language-selector-button {
			align-self: self-end !important;
		}
	  `}
	}
`;

const BubbleTitle = styled(SA.BubbleTitle)`
	flex-grow: 1;
	display: inline-flex;
	align-items: center;

	&& {
		span {
			background: #fff;
			color: ${(props) => props.theme.colors.text};
		}

		[data-texttype='markdown'] {
			background: #fff;
			color: ${(props) => props.theme.colors.text};
		}
	}

	${InternalLanguageSelector} + & {
		width: calc(100% - 80px);
		min-height: 64px;

		@media ${(props) => props.theme.screens.extraSmall} {
			min-height: 69px;
		}
	}
`;

const BubbleTitleLower = styled(SA.BubbleTitle)`
	flex-grow: 1;
	display: inline-flex;
	align-items: center;

	&& {
		span {
			background: #fff;
			color: ${(props) => props.theme.colors.text};
		}

		[data-texttype='markdown'] {
			background: #fff;
			color: ${(props) => props.theme.colors.text};
		}
	}

	${InternalLanguageSelector} + & {
		width: calc(100% - 80px);
		min-height: 41px;

		@media ${(props) => props.theme.screens.extraSmall} {
			min-height: 69px;
		}
	}
`;

const S = {
	infoPanelBackground,
	InfoPanelOverlay,
	InfoCloseButton,
	LanguageSelector: InternalLanguageSelector,
	InfoPanel,
	BubbleTitle,
	BubbleTitleLower,
	BubbleTitleWrapper
};

export default S;
