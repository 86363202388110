import React, { createContext, useContext, useReducer, PropsWithChildren, Dispatch } from 'react';
import { RootContext } from '../../models';
import { rootReducer } from '../reducer/rootReducer';
import { RootReducerAction } from '../reducer/rootReducerActions';
import { InitialState } from '../constants/initialState';

// We need default context value and dispatch function to call createContext with
// Even though the values shouldn't be used since we use StateProvider with useReducer

const defaultContextValue = InitialState({
	ApiKey: '',
	open: false,
	widget_showWelcomeText: true
});

// eslint-disable-next-line @typescript-eslint/no-empty-function
const defaultContextDispatch: Dispatch<RootReducerAction> = (value: RootReducerAction) => {};

export type StateContextType = [RootContext, Dispatch<RootReducerAction>];

const StateContext = createContext<StateContextType>([defaultContextValue, defaultContextDispatch]);

export const StateProvider = ({ initialState, children }: PropsWithChildren<{ initialState: RootContext }>) => {
	const [state, dispatch] = useReducer(rootReducer, initialState);

	return <StateContext.Provider value={[state, dispatch]}>{children}</StateContext.Provider>;
};

export const useStateContext: () => StateContextType = () => useContext<StateContextType>(StateContext);
