import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { LanguageInput, SessionInput } from './BaseInput';
import { ConsultationPreparationQuestion } from '../../models';

export type ConsultationPreparationGetStartQuestionInput = SessionInput & LanguageInput;

export type ConsultationPreparationGetStartQuestionOutput = {
	startQuestion?: ConsultationPreparationQuestion;
	empathy?: string;
};

async function CallConsultationPreparationGetStartQuestion(
	apiKey: string,
	input: ConsultationPreparationGetStartQuestionInput
): Promise<ConsultationPreparationGetStartQuestionOutput | undefined> {
	try {
		const { data } = await minddApiAxios.post<ConsultationPreparationGetStartQuestionOutput>(
			'ConsultationPreparationGetStartQuestion?api-version=2.1',
			input,
			{
				headers: {
					'MINDD-OC-ApiKey': apiKey
				}
			}
		);

		return data;
	} catch (error) {
		logAxiosError(error, 'ConsultationPreparationGetStartQuestion');
	}

	return undefined;
}

export { CallConsultationPreparationGetStartQuestion };
