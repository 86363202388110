import React from 'react';

import S from './styled';

import ReactTooltip from 'react-tooltip';
import SVG from 'react-inlinesvg';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';

import { getLabel } from '../../helpers/constants/getLabels';
import { translateConversation } from '../../helpers/constants/translateConversation';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallGetApplicationTexts, CallGetChannelSettings } from '../../helpers/services';

const LanguageSelector = (props) => {
	const [{ settings, languages, conversation }, dispatch] = useStateContext();

	const { selectedLanguage } = settings;
	const { className, disabled } = props;

	// react-accessible-dropdown-menu-hook does not like 0 available options
	const availableLanguages = languages.length > 1 ? languages.filter((l) => l.code !== selectedLanguage.code) : languages;
	const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(availableLanguages.length);

	const handleLanguageSelection = async (language) => {
		setIsOpen(false);
		ReactTooltip.hide();

		const previousSelectedLanguage = { ...settings.selectedLanguage };
		const newSelectedLanguage = { ...language };

		const channelSettings = await CallGetChannelSettings(settings.ApiKey, {
			languageCode: language.code
		});
		const applicationTexts = await CallGetApplicationTexts(settings.ApiKey, {
			languageCode: language.code
		});
		const newConversation = translateConversation(conversation, applicationTexts.applicationTexts);

		// use welcomeText from channnel settings, otherwise from widget config
		const welcome_text = settings.welcome_text ?? {};
		if (channelSettings.welcomeText) {
			welcome_text[newSelectedLanguage.code] = channelSettings.welcomeText;
		}

		// use questionWhatGender from channnel settings, otherwise from widget config
		const questionWhatGender = settings.labels.QuestionWhatGender ?? {};
		if (channelSettings.questionWhatGender) {
			questionWhatGender[newSelectedLanguage.code] = channelSettings.questionWhatGender;
		}

		const allConfigSettings = {
			...settings,
			...channelSettings,
			...applicationTexts,
			selectedLanguage: newSelectedLanguage,
			welcome_text: welcome_text,
			labels: {
				...settings.labels,
				QuestionWhatGender: questionWhatGender
			},
			branding: {
				...settings.branding,
				name: channelSettings.brandingName || settings.branding.name
			}
		};

		delete allConfigSettings.brandingName;
		delete allConfigSettings.questionWhatGender;
		delete allConfigSettings.welcomeText;

		// update
		dispatch({ type: 'updateSettings', settings: allConfigSettings });
		dispatch({ type: 'updateConversationActivities', conversation: newConversation });
		settings.target.dispatchEvent(
			new CustomEvent('minddWidgetLanguageChanged', {
				bubbles: true,
				detail: { previousLanguage: previousSelectedLanguage, newLanguage: newSelectedLanguage }
			})
		);
		if (typeof settings.onLanguageChanged === 'function') {
			settings.onLanguageChanged.call(null, previousSelectedLanguage, language);
		}
	};

	const menuClassName = isOpen ? 'mindd-widget-languageSelector-showDropDownMenu' : 'mindd-widget-languageSelector-hideDropDownMenu';
	const wrapperClassName = className ? `${className} ${menuClassName}` : menuClassName;

	return (
		<>
			<S.LanguageSelectorWrapper className={wrapperClassName} id={'language-selector-button'} data-tip data-for="language-selector-tooltip">
				<S.LanguageSelectorButton
					{...buttonProps}
					tabIndex={disabled ? '-1' : '0'}
					className={'mindd-widget-languageSelector-button'}
					aria-disabled={disabled}
				>
					<S.LanguageOptionCode>{selectedLanguage.code}</S.LanguageOptionCode>
					<S.FlagWrapper>
						<SVG src={selectedLanguage.flagSvg} title={selectedLanguage.code} />
					</S.FlagWrapper>
				</S.LanguageSelectorButton>

				<S.LanguageSelectorMenu>
					<S.LanguageSelectorMenuArrow></S.LanguageSelectorMenuArrow>
					<S.LanguageSelectorOptionsWrapper>
						{availableLanguages.map((item, index) => {
							return (
								<S.LanguageSelectorOption key={item.code} {...itemProps[index]} onClick={() => handleLanguageSelection(item)}>
									<S.FlagWrapper>
										<SVG src={item.flagSvg} title={item.code} />
									</S.FlagWrapper>
									<S.LanguageOptionCode>{item.code}</S.LanguageOptionCode>
								</S.LanguageSelectorOption>
							);
						})}
					</S.LanguageSelectorOptionsWrapper>
				</S.LanguageSelectorMenu>
			</S.LanguageSelectorWrapper>
			<ReactTooltip effect="solid" id="language-selector-tooltip" disableInternalStyle={true}>
				{getLabel('WidgetLanguageSelectorLabel', settings.applicationTexts, true)}
			</ReactTooltip>
		</>
	);
};

export default LanguageSelector;
